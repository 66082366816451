/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */

export default {
    // every plugin for Vue.js needs install method
    // this method will run after Vue.use(<your-plugin-here>) is executed
    install(Vue, options) {
        // We must create new Eventbus
        // which is just another Vue instance that will be listening for and emiting events from our main instance
        // this EventBus will be available as Modal.EventBus
        this.EventBus = new Vue()

        // making our modal component global

        // exposing global $modal object with method show()
        // method show() takes object params as argument
        // inside this object we can have modal title, text, styles... and also our callback confirm function
        // eslint-disable-next-line no-underscore-dangle
        const vm = this
        Vue.prototype.$modal = {
            confirm(title, message, callback) {
                  vm.EventBus.$emit('confirm', title, message, callback)
            },
            show(params) {
                vm.EventBus.$emit('show', params)
            },
            error(text) {
                vm.EventBus.$emit('error', text)
            },
            warning(text) {
                vm.EventBus.$emit('warning', text)
            },
            info(text) {
                vm.EventBus.$emit('info', text)
            },
            success(text) {
                vm.EventBus.$emit('success', text)
            },
            loadError() {
                vm.EventBus.$emit('loadError')
            },
            accept(title, text, callback) {
                vm.EventBus.$emit('accept', title, text, callback)
            },
        }
    },
}
