export default {
    IN_1: 'Inferring specific information in a literary / non-literary text.',
    IN_10: 'Identify the solution of problems presented in a text.',
    IN_11: 'Identify the purpose for which an action is performed in a text.',
    IN_12:
        'Identify the central theme of a literary text / recognize lyrical theme / topic not recognize the literary text.',
    IN_13: 'Summarize a text read by focusing on its main contents.',
    IN_14: 'Infer motivations of characters in a text or individuals.',
    IN_15: 'Infer feelings and / or emotions of characters in a text.',
    IN_16:
        "Connect details or parts of a text to the text's main idea",
    IN_17: 'Determine the sense in which a homonym is used in a text.',
    IN_18: 'Use contextual clues to interpret figurative language of familiar expressions in a text.',
    IN_19: 'Use contextual clues to interpret figurative language in a text.',
    IN_2: 'Infer important characteristics of central characters.',
    IN_20: 'Use contextual clues to recognize a synonym and / or antonym of a term.',
    IN_21: 'Use contextual clues to identify the meaning of a term.',
    IN_22: 'Use contextual clues to interpret the meaning of symbols in a text.',
    IN_23: 'Identify the main conflict of a text.',
    IN_24: 'Describe the physical and / or psychological qualities of characters in a text.',
    IN_25: 'Describe the physical and / or psychological space of a text.',
    IN_26: 'Establish networks of relationships between characters in a text.',
    IN_27:
        'Connect the psychological characteristics of the characters with their actions throughout the text',
    IN_28: "Identify a character's journey or evolution throughout a text.",
    IN_29:
        'Understand the overall meaning of a text or text fragment',
    IN_3:
        'Infer physical and / or psychological characteristics of characters in a text.',
    IN_30: 'Identify relevant ideas of characters in a text.',
    IN_31: 'Recognize the purpose of endings in short texts.',
    IN_32: 'Identify the main idea of a nonfiction text, distinguishing it from secondary and anecdotal ideas.',
    IN_33: 'Identify core emotions and/or  feelings in a text.',
    IN_34: 'Identify the central theme of a non-literary text.',
    IN_35:
        'Use explicit and implicit information to describe the context in which a given action takes place.',
    IN_36: 'Distinguish fact from opinion',
    IN_37: 'Interpret verses in poems or songs.',
    IN_38: 'Interpret overall meaning of a poem.',
    IN_39: 'Connect rhetorical devices to the overall meaning of a text.',
    IN_4: 'Infer contextual characteristics in a text.',
    IN_40: 'Organize the facts of a text in chronological order.',
    IN_41: 'Distinguish between the physical and psychological characteristics of characters ',
    IN_42: 'Identify the lyrical use of language.',
    IN_43: 'Identify the central conflict in a lyrical text.',
    IN_44: 'Interpret a poem, connecting specific verses to general or particular aspects of the poem.',
    IN_5: 'Compare information about characters in a text.',
    IN_6: 'Compare information from contextual elements in a text.',
    IN_7: 'Infer the causes of actions in a text.',
    IN_8: 'Identify cause-and-effect relationships in a specific section of a text.',
    IN_9: 'Identify effects or consequences of actions in a text.',
    LOC_1: 'Retrieve explicit information in a text.',
    LOC_10: 'Organize chronologically a sequence of relevant actions in a dramatic text.',
    LOC_11: 'Identify the explicit cause of actions in a text.',
    LOC_12: 'Identify the explicit purpose for which an action is performed in a literary / non-literary text.',
    LOC_13: 'Identify explicit motivations of characters in a text.',
    LOC_14: 'Identify explicit consequences of actions in a text.',
    LOC_15: 'Identify a specific fact or piece of information that expresses an important idea.',
    LOC_3: 'Identify explicit traits of characters in a text.',
    LOC_4: 'Identify explicit characteristics of contextual elements in a literary / non-literary text.',
    LOC_5: 'Identify feelings and / or emotions explicitly present in a literary / non-literary text.',
    LOC_6: 'Identify the chronological order of events in a section of a text.',
    LOC_7: 'Locate an action in a textual sequence.',
    LOC_8:
        'Identify words or expressions that help establish qualities or characteristics (adjectival phrases, adjectives).',
    LOC_9: 'Identify the space in which a specific action takes place in the text.',
    RV_1: 'Identify text type.',
    RV_10: 'Identify the argument that a character in the text uses to support an opinion.',
    RV_11: 'Connect the information presented in a text to everyday life.',
    RV_12: "Identify agreements or disagreements between characters' opinions in a text.",
    RV_13: 'Identify the world view and / or beliefs of characters in a text.',
    RV_14: 'Connect facts in the text to the context of production of the work.',
    RV_15: 'Contrast views presented in the text with contemporary perspectives of the world.',
    RV_16: 'Identify archetypes in the characters of a text.',
    RV_17: 'Identify the type of narrator in a text.',
    RV_18:
        'Describe the treatment of time or temporal structure of a narrative',
    RV_19: 'Explain the purpose or function of a specific part of a text.',
    RV_2: 'Recognize the communicative purpose of a text.',
    RV_20:
        'Explain how the devices used in a text (use of punctuation, rhyme, rhythm, loudness, distribution of lines or information) contribute to the expression of ideas and feelings.',
    RV_21:
        'Critically analyze characters based on how their individual situations influence their thoughts and reactions.',
    RV_24: 'Identify literary devices present in a text.',
    RV_25: 'Identify the explicit moral or lesson in a fable.',
    RV_26: 'Predict effects and / or consequences of actions in a text.',
    RV_27: 'Identify the main argument a character uses to support an opinion',
    RV_28:
        'Identify or infer structural aspects of form and content in a fictional text.',
    RV_29:
        'Identify elements of lyrical analysis, such as: rhyme, leitmotif, lyrical object, lyricism, among others.',
    RV_3: 'Identify aspects of form and/or content in different types of texts.',
    RV_30: 'Identify values as seen through specific attitudes of characters.',
    RV_5: 'Identify the context, environment or culture to which a text is associated.',
    RV_6: 'Identify the main teaching and/or message of a text.',
    RV_7: 'Distinguish between facts and opinions in a text.',
    RV_8: 'Identify the values ​​of characters in a text.',
    RV_9: "Identify a character's opinion about a specific subject in the text.",
}
