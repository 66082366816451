export class DateUtils {
    public static isAfter (date: Date, afterDate: Date = new Date(), equal: boolean = false): boolean {
        afterDate.setHours(0, 0, 0, 0)
        date.setHours(0, 0, 0, 0)
        return equal ? date >= afterDate : date > afterDate
    }

    public static isNowAfter (date: Date, afterDate: Date = new Date()): boolean {
        return this.isAfter(date, afterDate, true)
    }

    public static formatToUS (value: string) {
        const [day, month, year] = value.split('/')
        return `${year}-${month}-${day}`
    }

    public format (date: Date, options = { date: true, hour: true }): string {
        let result = ''

        if (options.date) {
            const day = this.padZeros(date.getUTCDate())
            const month = this.padZeros(date.getUTCMonth() + 1)
            const year = date.getUTCFullYear()
            result += `${day}/${month}/${year}`
        }

        if (options.hour) {
            const hours = this.padZeros(date.getUTCHours())
            const minutes = this.padZeros(date.getUTCMinutes())
            const seconds = this.padZeros(date.getUTCSeconds())
            if (result.length > 0) {
                result += ' '
            }
            result += `${hours}:${minutes}:${seconds}`
        }

        return result
    }

    public formatLastLogin (date: Date, options = { date: true, location: 'en' }): string {
        let result = ''
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        const meses = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
        ]
        const nth = (d: number) => {
            if (d > 3 && d < 21) return 'th'
            switch (d % 10) {
                case 1:  return 'st'
                case 2:  return 'nd'
                case 3:  return 'rd'
                default: return 'th'
            }
        }

        if (options.date) {
            const day = date.getDate()
            const month = date.getMonth()
            const year = date.getFullYear()
            if (options.location === 'en') {
                result += `${months[month]} ${day}${nth(day)}`
            } else {
                result += `${day} de ${meses[month]}`
            }
        }

        return result
    }

    public formatStartDate (date: Date, options = { date: true, location: 'en' }): string {
        let result = ''
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        const meses = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
        ]

        if (options.date) {
            const day = date.getDate()
            const month = date.getMonth()
            const year = date.getFullYear()
            if (options.location === 'en') {
                result += `${day} ${months[month]} ${year}`
            } else {
                result += `${day} ${meses[month]} ${year}`
            }
        }

        return result
    }

    private padZeros (n: number, length: number = 2): string {
        const str = n.toString()
        const leadingZerosCount = length > str.length ? length - str.length : 0
        return '0'.repeat(leadingZerosCount) + str
    }
}
