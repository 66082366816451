export default {
  dashboard: {
    title: 'Reports',
    sectionTitles: {
      generalInformation: 'Overview',
      books: 'Books',
      challenges: 'Challenges',
    },
    cards: {
      readingComprehension: 'Reading Comprehension',
      questionsAmountquestionsAmount: 'Number Of Activities And Questions',
      questionsAmount: 'Number Of Questions',
      studentsPerformance: 'Students Performance',
      booksByAssignment: 'Books By Assignment',
      assignedBooksResults: 'Books Assigned Results',
      selfLectures: 'Students who have read books on their own',
      selfLecturesBooks: 'Self lectures book',
      challengeResults: 'Challenge Results',

    },
    tooltips: {
      generalInformation: {
        generalInformationTab: 'This tab shows student results on assigned books, self-taste books, and completed challenges.',
        readingComprehension: 'This chart shows the trend in student reading comprehension for the selected date range.',
        questionsAmount: 'This table shows the number of books assigned, self-liked books, and challenges completed, as well as the number of correct, incorrect, and total answers.',
        studentsPerformance: "This table shows the results of each student's reading skills, level of intervention, type of reader, and Beereaders stage.",
        individualInformation: {
          readingComprehension: 'Este gráfico muestra la tendencia en la comprensión lectora de los estudiantes en el rango de fechas seleccionado.',
          questionsAmount: 'Esta tabla muestra la cantidad de libros asignados, libros de gusto propio y desafíos realizados, así como el número de respuestas correctas, incorrectas y totales.',
          studentsPerformance: 'Esta tabla muestra los resultados de las habilidades lectoras, nivel de intervención, tipo de lector y etapa Beereaders de cada estudiante.',
        }
      },
      booksInformation: {
        bookInformationTab: 'Esta pestaña muestra los resultados de los estudiantes en los libros asignados y libros de gusto propio.',
        booksByAssignment: 'Esta tabla muestra los resultados de los libros asignados. Puedes hacer clic en el libro para más detalles.',
        assignedBooksResults: 'Este gráfico de barras muestra los promedios de las habilidades lectoras en los libros asignados.',
        questionsAmount: 'Esta tabla muestra la cantidad de preguntas correctas, incorrectas y totales de todos los libros asignados. Ayuda a interpretar el gráfico de la izquierda.',
        selfLectures: 'Esta tabla muestra los nombres de los estudiantes que han realizado lecturas por gusto propio.',
        individualInformation: {
          booksByAssignment: 'Esta tabla muestra los resultados de los libros asignados. Puedes hacer clic en el libro para más detalles.',
          assignedBooksResults: 'Este gráfico de barras muestra los promedios de las habilidades lectoras en los libros asignados.',
          questionsAmount: 'Esta tabla muestra la cantidad de preguntas correctas, incorrectas y totales de todos los libros asignados. Ayuda a interpretar el gráfico de la izquierda.',
          selfLectures: 'Esta tabla muestra los resultados de los libros leídos por gusto propio del estudiante. Puedes hacer clic en el libro para más información de este.',
        }
      },
      challengesInformation: {
        challengeInformationTab: 'Esta pestaña muestra los resultados de los estudiantes en los desafíos realizados.',
        challengesResults: 'Este gráfico de barras muestra los promedios de las habilidades lectoras en los desafíos realizados.',
        questionsAmount: 'Esta tabla muestra la cantidad de preguntas correctas, incorrectas y totales de todos los desafíos realizados. Ayuda a interpretar el gráfico de la izquierda.',
        studentsPerformance: 'Esta tabla muestra los resultados de las habilidades lectoras y cantidad de desafíos por estudiante.',
      }
    }
  },
  skills: {
    fullName: {
      locate: 'Locate Information',
      integrate: 'Integrate and Interpret',
      reflect: 'Reflect and Evaluate',
      readingTime: 'Reading time'
    },
    abbrevationName: {
      locate: 'LOC',
      integrate: 'INT',
      reflect: 'RV',
      readingTime: 'RT',
      readingComprehension: 'RC',
    }
  },
  tableHeaders: {
    student: 'Student',
    bookTitle: 'Book Title',
    readerType: 'Reading Stage',
    tiers: 'Tiers',
    beereadersStage: 'Beereaders Stage',
    lectures: 'Readings',
    activities: 'Activities',
    booksAmount: 'Number Of Books',
    amount: 'Number',
    corrects: 'Correct Questions',
    incorrects: 'Incorrects Questions',
    total: 'Total Questions',
    challengesAmount: 'Nº of challenges',
    lexileMeasure: 'Lexile Measure',
    progress: 'Progress',
    skill: 'Skill',
  },
  tableValues: {
    student: {
      type: {
        preInitial: 'Early Emergent',
        initial: 'Emergent',
        transitional: 'Transitional',
        independent: 'Independent',
        fluent: 'Fluent',
        reflexive: 'Reflexive',
      }
    },
    lectures: {
      assignedBooks: 'Books',
      readChallenges: 'Challenges',
    },
    emptyValues: {
      emptyStudents: 'There is no students to show!',
      emptyData: 'There is no data to show!',
    }
  },
  generalFilter: {
    filters: {
      courses: 'Classes',
      levels: 'Tiers',
      students: 'Students',
      dateRange: 'Date Range',
    },
    defaultValues: {
      all: 'All',
      customDates: 'Custom Dates',
      last7Days: 'Last 7 days',
      last30Days: 'Last 30 days',
      last90Days: 'Last 90 days',
    },
    placeholders: {
      dateRange: 'Select Date Range'
    },
    labels: {
      filterByCourses: 'Filters by courses',
      filterByLevels: 'Filter by levels',
      filterByStudents: 'Filter by students',
    }
  },
  utils: {
    of: 'of',
    level: 'Tier',
    cancel: 'Cancel',
  },
  errors: {
    filterError: {
      initialDateGreater: 'Oops! The initial date must be before than the end date, try again!'
    }
  }
}