export default {
    titles: {
        readerCharacteristics: 'Reader Characteristics',
        textCharacteristics: 'Text Characteristics',
        beereadersReadingStages: 'BeeReaders Reading Progress Stage',
    },
    tooltips: {
        lexileFountasPinell: 'BeeReaders incorporates elements from Lexile® and Fountas & Pinnell to grade text difficulty levels. All content was analyzed and Lexile® and Fountas & Pinnell ranks were established for each BeeReaders reading stage.'
    },
    cards: {
        stages: {
            title: 'BeeReaders Stages of Reading Progress in Spanish',
            content: 'The BeeReaders Spanish reading progression system is a leveling system for texts and readers that integrates skills, goals, and criteria from the Spanish-speaking world, while synthesizing and grouping the transversal and complementary elements found in the most relevant international reading assessments. Our system is divided into 72 stages for students in grades 1-12 and 12 stages for explorer students. Each stage focuses on skills related to the specific characteristics of the type of reader.'
        },
        information: {
            title: 'RTI Tiers in the BeeReaders Framework',
            content: 'BeeReaders incorporates the Response to Intervention (RTI) model. These interventions are hierarchical and are divided into tiers that group students according to their performance, suggesting appropriate interventions or improvements for each group.'
        },
        characteristics: {
            title: 'Text Levels in the BeeReaders Reading Progress Framework',
            content: 'The BeeReaders system is divided into 72 stages of reading progression for readers who have mastered comprehension and 12 stages for explorer readers. Each stage is associated with specific skills and characteristics of the reader type.'
        }
    },
    readers: {
        explorer: {
            title: 'Explorer Reader',
            readerCharacteristics: [
                'He has an incipient understanding of the connection between phonemes and graphemes.',
                'She is familiar with some letters of the alphabet.',
                'She recognizes some words (for example, names), upper and lowercase letters, and sounds and shapes of letters.',
                'Recognizes some words (e.g., names), uppercase and lowercase letters, sounds, and letter shapes.',
                'He can identify the sound and/or first and last syllable of high frequency words.',
                'She is able to divide words by syllables in texts on familiar topics.',
            ],
            textCharacteristics: [
                'They are comprised of short paragraphs.',
                'They present words in linear fashion (left to right).',
                'They contain simple sentences and high frequency words.',
                'They repeats words over and over throughout the text.',
                'They feature phonetically consistent words.',
                'They feature simple, familiar themes and concrete elements.',
                'They feature a few lines of text per page.',
                'They use predictable texts with images and listening support.'
            ],
            beereadersStageInformation: 'Questions focus on phonics, decoding and the most basic level of comprehension (locate, infer and reflect on the general information presented in a given text).'
        },
        initial: {
            title: 'Emergent Reader',
            readerCharacteristics: [
                'She knows the alphabet and recognizes word concepts.',
                'She connects letters and sound.',
                'He uses illustrations to support comprehension.',
                'He is capable of reading simple text, sometimes with help.',
                'He has incipient decoding skills.',
                'She can read sight words and tends to read aloud.',
                'She is able to read and understand texts on familiar topics.',
            ],
            textCharacteristics: [
                'They are comprised of simple sentences that contain common compound and multisyllabic words.',
                'They present familiar, simple storylines.',
                'They are composed of short paragraphs and simple structures.',
                'They are repetitive but with changes in pattern.',
                'They feature progressively longer sentences and include changes in verb tenses.',
                'They feature simple dialogue.',
            ],
            beereadersStageInformation: 'At this level, comprehension questions are very basic, evaluating general understanding. The highest percentage of questions for this type of reader focuses on the ability to locate or obtain information.'
        },
        bold: {
            title: 'Transitional Reader',
            readerCharacteristics: [
                'She is starting to make the transition from "learning to read" to "reading to learn".',
                'He continues building reading ability by using strategies to identify the meaning of unfamiliar words.',
                'She demonstrates increased reading fluency.',
                'She has incipient automaticity with sight words and high frequency words (HFW).',
                'He is primarily decoding but with incipient comprehension.',
                'He is capable of self-monitoring.',
                'She reads more and reads aloud more fluently.',
            ],
            textCharacteristics: [
                'They feature complex sentences and varied punctuation.',
                'They use fewer illustrations.',
                'They use literary language.',
                'They feature greater complexity in story plots.',
                'They are composed of polysyllabic words and complex sentences.',
                'They include fewer patterns.',
            ],
            beereadersStageInformation: 'At this level, comprehension questions are of intermediate difficulty, covering questions of various levels of understanding. The highest percentage of questions from this type of reader focuses on the ability to integrate and interpret information.'
        },
        independent: {
            title: 'Independent Reader',
            readerCharacteristics: [
                'He reads autonomously and understands a wide range of texts.',
                'She reads to gain knowledge.',
                'She deploys reading strategies.',
                'She develops points of view in relation to the text.',
                'He has fluid comprehension.',
                'He can read hundreds of high frequency words and sight words fluently.',
                'She uses context to help with vocabulary.',
                'She has started to make inferences and integrates information.',
                'He tends to read silently.',
            ],
            textCharacteristics: [
                'They present a diversity of familiar and unfamiliar themes and genres.',
                'They feature a figurative use of language and use of linguistic and literary devices.',
                'They include fewer, if any, illustrations.',
                'They include charts, infographs (nonfiction texts).',
                'They use multisyllabic words and complex sentences',
                'They are divided in chapters of longer extension and/or chapters.',
                'They feature more complex characters and plots.',
                'They use, mostly, smaller type of fonts.',
            ],
            beereadersStageInformation: 'At this level, comprehension questions are of intermediate-advanced difficulty, with questions of various degrees of understanding. The highest percentage of questions for this type of reader focuses on the ability to integrate and interpret information'
        },
        youth: {
            title: 'Fluent Reader',
            readerCharacteristics: [
                'He is able to synthesize, reflect on, and apply multiple perspectives to reading material.',
                'Reading is autonomous and fluent.',
                'She possess a large sight vocabulary.',
                'He reads silently almost exclusively.',
                'She is capable of identifying word patterns and experimenting with roots and affixes when encountering new words.',
                'He can analyze a variety of texts and make predictions.',
            ],
            textCharacteristics: [
                'They include themes and storylines of greater critical depth.',
                'They use a range of literary and linguistic resources.',
                'They use multi-syllabic words and multi-clause sentences.',
                'They present complex storylines and themes.',
                'They include dialogues and a variety of sequences.',
                'They use a variety of bibliographic references.',
            ],
            beereadersStageInformation: 'At this level, comprehension questions are of advanced difficulty, with questions that test various degrees of comprehension. The highest percentage of questions for this type of reader focuses on the ability to reflect on and evaluate the information presented in the text.'
        },
        reflective: {
            title: 'Reflexive Reader',
            readerCharacteristics: [
                'She is able to integrate more sophisticated disciplinary perspectives.',
                'She is motivated to read for personal reasons (educational, work-related, family, social).',
                'He deploys a set of cognitive and metacognitive strategies during reading.',
                'He is capable of critical and comparative readings.',
                'She has mastered of a wide range of genres and text categories.',
                'She is capable of interpreting multiple points of view.',
                'Her comprehension has entirely replaced decoding.',
                'He is able to sustain long-term reading over days and weeks.',
                'She applies knowledge of Greek and Latin roots to recognize the meaning of the words.',
                'She is able to build new knowledge by integrating different sources of information.',
            ],
            textCharacteristics: [
                'They have a range of perspectives and topics.',
                'They include a range of genres and textual modalities.',
                'Include multisyllabic words and multi-clause sentences.',
                'They feature complex plots and themes.',
                'They use a variety of literary and linguistic resources.',
                'They use a wide variate of vocabulary, including technical and scientific terms.',
            ],
            beereadersStageInformation: 'At this level, comprehension questions are of advanced difficulty, with questions that assess various degrees of comprehension. The highest percentage of questions for this type of reader focuses on the ability to reflect on and evaluate information in the text.'
        },
    },
    utils: {
        none: 'None',
    }
}