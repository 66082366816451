export default {
    INF: 'Infantile',
    AVEN: 'Adventure',
    DRAM: 'Drama',
    OCI: 'Leisure',
    DEP: 'Sports',
    M_S: 'Mystery and Suspense',
    ROM: 'Romance',
    C_F: 'Science Fiction',
    FANT: 'Fantastic',
    TER: 'Terror',
    EDU: 'Education',
    ART: 'Art',
    ESPT: 'Spirituality',
    C_T: 'Science and Technology',
    V_S: 'Life and Health',
    H_F: 'History and Philosophy',
    GAST: 'Gastronomy',
    AUTAY: 'Self-help',
    M_L: 'Myths and Legends',
    CULT: 'Culture',
    C_P: 'Crime and Police',
    MAR: 'Wonderful',
    POES: 'Poetry',
    TEA: 'Theater',
    C_N_G: 'Comic and Graphic Novel',
    F_L: 'Reading Promotion',
    MEAM: 'Environment',
    MUSIC: 'Music',
    AMIS: 'Friendship',
    ANIM: 'Animals',
    FILS: 'Philosophy',
    PasatiempoDeportes: 'Sports and Recreation',
    MisterioTerror: 'Mystery and Horror',
    AventurasViajes: 'Adventure and Travel',
    FantasiaMundos: 'Fantasy and Alternate Worlds',
    ArteCultura: 'Art and Culture',
    CienciaTecnologia: 'Science and Technology',
    CienciasUniverso: 'Science and Universe',
    TecnologiaInventos: 'Inventions and Technology',
    PensamientosEmociones: 'Emotions and Thoughts',
    HistoriaVidaReal: 'History and Realistic Fiction',
    HumorCuriosidades: 'Humor and Fun Facts',
    CocinaVidaCotidiana: 'Cooking and Everyday Life',
    NaturalezaAnimales: 'Animals and Nature',
}
