import { ReportsGlobalState, ReportViews } from './types/index'

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const defaultState = (): ReportsGlobalState => ({
  courses: [],
  courseSelected: null,
  students: [],
  filteredStudents: [],
  studentsPerCourse: {},
  studentSelected: null,
  tiers: [],
  tierSelected: null,
  dates: {
    initialDate: undefined,
    endDate: undefined,
  },
  dateOptionSelected: null,
  generalInformation: {},
  bookInformation: {},
  challengesInformation: {},
  loading: {
    generalDataLoading: false,
    booksDataLoading: false,
    challengesDataLoading: false,
  },
  errors: {
    generalError: null,
    filterError: null,
  },
  currentTab: ReportViews.GENERAL_INFORMATION,
})

const reportsData = {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions
}

export default reportsData
