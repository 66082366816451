import ArrowButton from './core/ArrowButton.vue'
import ArrowButtonOutline from './core/ArrowButtonOutline.vue'

const components = [
    { key: 'ArrowButton', component: ArrowButton },
    { key: 'ArrowButtonOutline', component: ArrowButtonOutline },
]

export default (vue: any) => {
    const prefix = 'X'
    components.forEach((component) => {
        vue.component(`${prefix}${component.key}`, component.component)
    })
}
