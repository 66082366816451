export default {
    diagnoses: 'Diagnósticos',
    diagnosis: {
        types: {
            initial: 'Evaluación diagnóstica',
            middle: 'Evaluación progresiva',
            final: 'Evaluación de cierre',
        },
        assignmentType: {
            AUTOMATIC: 'AUTOMÁTICO',
            ASSIGNED: 'ASIGNADO',
        },
    },
    titles: {
        evaluations: 'Evaluaciones',
        assignedEvaluations: 'Evaluaciones asignadas',
        results: 'Resultados',
        generalInformation: 'Información general',
        diagnosesInformation: 'Información de diagnósticos',
        classResultsPerSkill: 'Resultados de la clase',
        studentsList: 'Listado de estudiantes',
        assignEvaluation: 'Asignar evaluación',
        assignDiagnosis: 'Asignar diagnóstico',
        updateDiagnosis: 'Actualizar diagnóstico',

    },
    coursesList: {
        '1-3': '1° a 3° grado',
        '4-6': '4° a 6° grado',
        '7-8': '7° a 8° grado',
        '9-10': '9° a 10° grado',
        '11-12': '11° a 12° grado',
        '11-13': '11° a 13° grado',
        '12-13': '12° a 13° grado',
    },
    utils: {
        list: 'Listado',
        instructions: 'Instrucciones para asignar las evaluaciones',
        skills: {
            locate: 'Localizar información',
            integrate: 'Integrar e interpretar',
            reflect: 'Reflexionar y valorar',
            readingComprenhension: 'Comprensión lectora',
        },
        totalOfQuestions: 'Total de preguntas',
        diagnosisType: 'Tipo de diagnóstico',
        text: 'Texto',
        question: 'Pregunta',
        to: 'a',
        grade: 'grado',
    },
    texts: {
        evaluations: {
            information: `<div class="evaluation-info">
            <div class="evaluation-wrapper mb-2">
              <h4>Evaluación diagnóstica</h4>
              <div class="evaluation-desc">
                  <p><b>Fecha:</b> Inicio del año escolar.</p>
                  <p><b>Objetivo:</b> Entregar información sobre el estado de las habilidades lectoras para planificar las clases y promover una formación integral en las y los estudiantes.</p>
              </div>
            </div>
            <div class="evaluation-wrapper mb-2">
              <h4>Evaluación progresiva</h4>
              <div class="evaluation-desc">
                  <p><b>Fecha:</b> Mitad del año escolar.</p>
                  <p><b>Objetivo:</b> Entregar información sobre los logros de las habilidades de lectura de las y los estudiantes para que los docentes y directivos puedan ajustar la planificación del segundo semestre y adecuar las acciones pedagógicas.</p>
              </div>
            </div>
            <div class="evaluation-wrapper mb-2">
              <h4>Evaluación de cierre</h4>
              <div class="evaluation-desc">
                <p><b>Fecha:</b> Final del año escolar.</p>
                <p><b>Objetivo:</b> Entregar información sobre los logros y avances de las habilidades de lectura de las y los estudiantes para que los docentes y directivos evalúen las acciones realizadas durante el año que finaliza y usen esta información para planificar el próximo año escolar.</p>
              </div>
            </div>
            <div class="evaluation-important">
                <h4>
                    <span class="icon-wrap"><i class="material-icons mdi mdi-information md-18"></i></span>
                    <b>Importante</b>
                </h4>
              <div class="important-desc">
                  <ul>
                    <li>La evaluación diagnóstica siempre debe anteceder a las demás evaluaciones. Esta se ocupa para posicionar al estudiante en la Etapa BeeReaders que determina el nivel de dificultad de los Desafíos y que se observa tanto en los resultados de la evaluación diagnóstica como en la tabla Desempeño de estudiantes de la sección Reportes. De igual forma, agrupa a los estudiantes en los tres Niveles de intervención.</li>
                    <li>Las evaluaciones progresiva y de cierre calculan la Etapa BeeReaders del estudiante, pero solo es informativa y NO modifica la Etapa que se observa en la tabla Desempeño de estudiantes de la sección Reportes. Estas evaluaciones reagrupan a los estudiantes en los tres Niveles de intervención.</li>
                  </ul>
              </div>
            </div>
        </div>`
        },
        tooltips: {
            diagnosesState: {
                completed: 'Evaluación terminada',
                inProgress: 'Evaluación pendiente',
            }
        }
    },
    tables: {
        evaluationsList: {
            labels: {
                section: 'Tramo',
                initial: 'Evaluación diagnóstica',
                middle: 'Evaluación progresiva',
                final: 'Evaluación de cierre',
            }
        },
        assignedEvaluations: {
            labels: {
                evaluation: 'Evaluación',
                state: 'Progreso',
                assignedTo: 'Asignado a',
                course: 'Curso',
                startDate: 'Fecha de inicio',
                endDate: 'Fecha de término',
                actions: 'Acciones',
                assignmentType: 'Tipo',
            }
        },
        studentsList: {
            labels: {
                student: 'Estudiante',
                state: 'Estado',
                tier: 'Nivel',
                actions: 'Acciones',
                beeStage: 'Etapa BeeReaders',
            }
        }
    },
    inputs: {
        labels: {
            evaluationType: 'Tipo de evaluación',
            state: 'Estado',
            course: 'Curso',
            evaluation: 'Evaluación',
        },
        placeholders: {
            chooseEvaluationType: 'Seleccione tipo de evaluación',
            chooseEvaluationState: 'Seleccione estado de evaluación',
            chooseCourse: 'Seleccione curso',
        },
        filters: {
            diagnosticState: {
                active: 'Activa',
                inactive: 'Inactiva',
            },
            evaluationType: {
                all: 'Todas',
                initial: 'Evaluación diagnóstica',
                middle: 'Evaluación progresiva',
                final: 'Evaluación de cierre',
            },
            utils: {
                all: 'Todos',
            }
        },
    },
    actions: {
        evaluation: {
            assign: 'Asignar evaluación',
            edit: 'Editar evaluación',
            delete: 'Eliminar evaluación',
            update: 'Actualizar evaluación',
            activate: 'Activar evaluación',
        },
        diagnosis: {
            assign: 'Asignar diagnóstico',
            edit: 'Edit diagnóstico',
            delete: 'Delete diagnóstico',
            update: 'Update diagnóstico',
            activate: 'Activate diagnóstico',
            nextText: 'Siguiente texto',
            prevText: 'Texto anterior',
        },
        export: 'Exportar',
        assign: 'Asignar',
        view: 'Ver',
        confirmation: {
            delete: '¿Confirma que desea eliminar esta evaluación? No podrá recuperarla después.',
            assign: '¿Está seguro que desea asignar esta evaluación? Se podra elimiar la asignación después',
        }
    },
    errors: {
        codes: {
            700: 'Máximo número de evaluaciones alcanzado',
            701: 'Nivel de diagnóstico ya asignado',
            702: 'Evaluación ya tiene respuestas',
            703: 'Evaluación no existe',
            default: 'Ha ocurrido un error, intente de nuevo luego',
        },
    },
}
