import { BookInformationDTO, ChallengeInformationDTO, GeneralInformationDTO, StudentsCourseBookMetrics } from '@/data/reports/responses'
import Services from '../base-services'

const service = new Services('teachers/reportFilters')

export default {
    async getPerformanceByCourse(courseID: number, startDate: string, endDate: string) {
        const data = await service.get(`generalInformationFiltered`, {
            params: {
                courseId: courseID,
                startDate: startDate,
                endDate: endDate,
            },
        })
        return data.content as GeneralInformationDTO
    },
    async getBookAssignedMetricsByCourse(courseID: number, startDate: string, endDate: string) {
        const startDateModified = startDate.split('T')[0];
        const endDateModified = endDate.split('T')[0];
        const data = await service.get(
            `bookAssignedMetricsFiltered`,
            {
                params: {
                    courseId: courseID,
                    startDate: startDateModified,
                    endDate: endDateModified,
                },
            }
        )
        return data.content as BookInformationDTO
    },
    async geteCourseBookMetrics(courseID: number, startDate: string, endDate: string, courseBookID: string) {
        const data = await service.get(`courseBookMetricsPerStudent`, {
            params: {
                courseId: courseID,
                startDate,
                endDate,
                assigmentId: courseBookID,
            },
        })
        return data.content as StudentsCourseBookMetrics
    },
    async getChallengesMetricsByCourse(courseID: number, startDate: string, endDate: string) {
        const data = await service.get(`challengesMetricsFiltered`, {
            params: {
                courseId: courseID,
                startDate,
                endDate,
            },
        })
        return data.content as ChallengeInformationDTO
    },
    async getStudentsSelfLectures(studentID: number, courseID: number, startDate: string, endDate: string) {
        const data = await service.get(`bookAssignedMetricsFiltered`, {
             params: {
                courseId: courseID,
                studentId: studentID,
                startDate,
                endDate
             }
        })
        return data.content as BookInformationDTO
    },
    async getExcelFileFromTab(generalFilterState: { startDate: string, endDate: string, courseID: number, selectedTab: 'Challenges' | 'GeneralInfo' | 'Books', tierID?: number, studentID?: number }) {
        const selectedTier = generalFilterState.tierID ? (generalFilterState.tierID - 1) : null
        const data = await service.get('generateFileBySelectedTab', {
            params: {
                startDate: generalFilterState.startDate,
                endDate: generalFilterState.endDate,
                courseId: generalFilterState.courseID,
                selectedTab: generalFilterState.selectedTab,
                tierId: selectedTier,
                studentId: generalFilterState.studentID || null,
            },
            responseType: 'blob',
        })
        return data
    },
    async getPerformanceExcelFile(generalFilterState: { startDate: string, endDate: string, courseId: number, assigmentId: number}) {
        const data = await service.get('generateCourseBookMetricsReport', {
            params: {
                startDate: generalFilterState.startDate,
                endDate: generalFilterState.endDate,
                courseId: generalFilterState.courseId,
                assigmentId: generalFilterState.assigmentId,
            },
            responseType: 'blob',
        })
        return data
    }
}
