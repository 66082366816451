export default {
    beereaders_level: 'Beereaders Level',
    complexity: 'Complexity Level',
    description: 'Description',
    spanish_lexile: 'Spanish Lexile',
    fountas_pinnel: 'Fountas & Pinnell SEL',
    leveling_system_params: [
        {
            grade: '1st grade',
            level: 1,
            description:
                'Can retrieve explicit information from short, continuous texts, with low semantic load, appropriate for the grade level',
            lexile: '420-820',
            fountas_pinnell: 'E',
            complexity_level: 'Low',
        },
        {
            grade: '1st grade',
            level: 2,
            description:
                'Short extracts information explicit and continuous texts with low semantic load, appropriate to their grade.',
            lexile: '420-820',
            fountas_pinnell: 'E',
            complexity_level: 'Medium low',
        },
        {
            grade: '1st grade',
            level: 3,
            description:
                'Short extracts information explicit and continuous texts with low semantic load, appropriate to their grade.',
            lexile: '420-820',
            fountas_pinnell: 'F',
            complexity_level: 'Medium',
        },
        {
            grade: '1st grade',
            level: 4,
            description:
                'Locate and interprets specific information brief and continuous texts with low semantic load, appropriate to their grade.',
            lexile: '420-820',
            fountas_pinnell: 'F',
            complexity_level: 'Middle high',
        },
        {
            grade: '1st grade',
            level: 5,
            description:
                'Locate and interprets specific information of short texts, continuous, low semantic load, appropriate to their grade.',
            lexile: '420-820',
            fountas_pinnell: 'G',
            complexity_level: 'High',
        },
        {
            grade: '1st grade',
            level: 6,
            description:
                'Locate and interprets specific information of short texts, continuous, low semantic load, appropriate to their grade. It also recognizes some basic text types.',
            lexile: '420-820',
            fountas_pinnell: 'G',
            complexity_level: 'Higher',
        },
        {
            grade: '2nd grade',
            level: 7,
            description:
                'Located and infer specific information in short, continuous texts with low semantic appropriate to their grade.',
            lexile: '420-820',
            fountas_pinnell: 'H',
            complexity_level: 'Low',
        },
        {
            grade: '2nd grade',
            level: 8,
            description:
                'Located and infer specific information in short, continuous texts with low semantic appropriate to their grade.',
            lexile: '420-820',
            fountas_pinnell: 'H',
            complexity_level: 'Medium low',
        },
        {
            grade: '2nd grade',
            level: 9,
            description:
                'Located and infer specific information in short, continuous texts with low semantic appropriate to their grade.',
            lexile: '420-820',
            fountas_pinnell: 'I',
            complexity_level: 'Medium',
        },
        {
            grade: '2nd grade',
            level: 10,
            description:
                'Located and infer specific information in short, continuous texts with low semantic appropriate to their grade.',
            lexile: '420-820',
            fountas_pinnell: 'I',
            complexity_level: 'Middle high',
        },
        {
            grade: '2nd grade',
            level: 11,
            description:
                'Located and infer specific information in short, continuous texts with low semantic appropriate to their grade. Recognize some textual typologies.',
            lexile: '420-820',
            fountas_pinnell: 'J',
            complexity_level: 'High',
        },
        {
            grade: '2nd grade',
            level: 12,
            description:
                'Located and infer specific information in short, continuous texts with low semantic appropriate to their grade. Recognize teaching message or text.',
            lexile: '420-820',
            fountas_pinnell: 'J',
            complexity_level: 'Higher',
        },
        {
            grade: '3rd grade',
            level: 13,
            description:
                'Located and infer specific information of short texts, continuous or discontinuous, with low semantic load, appropriate to their grade.',
            lexile: '420-820',
            fountas_pinnell: 'K',
            complexity_level: 'Low',
        },
        {
            grade: '3rd grade',
            level: 14,
            description:
                'Located and infer specific information of short texts, continuous or discontinuous, with low semantic load, appropriate to their grade.',
            lexile: '420-820',
            fountas_pinnell: 'K',
            complexity_level: 'Medium low',
        },
        {
            grade: '3rd grade',
            level: 15,
            description:
                'Located and infer specific information of short texts, continuous or discontinuous, with low semantic load, appropriate to their grade.',
            lexile: '420-820',
            fountas_pinnell: 'L',
            complexity_level: 'Medium',
        },
        {
            grade: '3rd grade',
            level: 16,
            description:
                'Located and infer specific information of short texts, continuous or discontinuous, with low semantic load, appropriate to their grade. Recognize some textual typologies.',
            lexile: '420-820',
            fountas_pinnell: 'L',
            complexity_level: 'Middle high',
        },
        {
            grade: '3rd grade',
            level: 17,
            description:
                'Located and infer specific information of short texts, continuous or discontinuous, with low semantic load, appropriate to their grade. Recognize teaching message or text.',
            lexile: '420-820',
            fountas_pinnell: 'M',
            complexity_level: 'High',
        },
        {
            grade: '3rd grade',
            level: 18,
            description:
                'Located and infer specific information of short texts, continuous or discontinuous, with low semantic load, appropriate to their grade. Text information related to everyday life.',
            lexile: '420-820',
            fountas_pinnell: 'M',
            complexity_level: 'Higher',
        },
        {
            grade: '4th grade',
            level: 19,
            description:
                'Located and infer specific information of short texts and / or intermediates, continuous and / or discontinuous, with intermediate and semantic paragraphs moderate extension according to their degree.',
            lexile: '420-820',
            fountas_pinnell: 'N',
            complexity_level: 'Low',
        },
        {
            grade: '4th grade',
            level: 20,
            description:
                'Located and infer specific information of short texts and / or intermediates, continuous and / or discontinuous, with intermediate and semantic paragraphs moderate extension according to their degree.',
            lexile: '420-820',
            fountas_pinnell: 'N',
            complexity_level: 'Medium low',
        },
        {
            grade: '4th grade',
            level: 21,
            description:
                'Located and infer specific information of short texts and / or intermediates, continuous and / or discontinuous, with intermediate and semantic paragraphs moderate extension according to their degree. Recognize some textual typologies.',
            lexile: '420-820',
            fountas_pinnell: 'O',
            complexity_level: 'Medium',
        },
        {
            grade: '4th grade',
            level: 22,
            description:
                'Located and infer specific information of short texts and / or intermediates, continuous and / or discontinuous, with intermediate and semantic paragraphs moderate extension according to their degree. Recognize the communicative purpose.',
            lexile: '420-820',
            fountas_pinnell: 'O',
            complexity_level: 'Middle high',
        },
        {
            grade: '4th grade',
            level: 23,
            description:
                'Located and infer specific information of short texts and / or intermediates, continuous and / or discontinuous, with intermediate and semantic paragraphs moderate extension according to their degree. Recognize features and / or content.',
            lexile: '420-820',
            fountas_pinnell: 'P',
            complexity_level: 'High',
        },
        {
            grade: '4th grade',
            level: 24,
            description:
                'Located and infer specific information of short texts and / or intermediates, continuous and / or discontinuous, with intermediate and semantic paragraphs moderate extension according to their degree. Recognize teaching message or text.',
            lexile: '420-820',
            fountas_pinnell: 'P',
            complexity_level: 'Higher',
        },
        {
            grade: '5th grade',
            level: 25,
            description:
                'Located and infer specific information word low extension or intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree.',
            lexile: '420-820',
            fountas_pinnell: 'Q',
            complexity_level: 'Low',
        },
        {
            grade: '5th grade',
            level: 26,
            description:
                'Located and infer specific information word low extension or intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree.',
            lexile: '420-820',
            fountas_pinnell: 'Q',
            complexity_level: 'Medium low',
        },
        {
            grade: '5th grade',
            level: 27,
            description:
                'Located and infer specific information word low extension or intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree. Recognize various text types.',
            lexile: '420-820',
            fountas_pinnell: 'R',
            complexity_level: 'Medium',
        },
        {
            grade: '5th grade',
            level: 28,
            description:
                'Located and infer specific information word low extension or intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree. Recognize communicative purpose of the text.',
            lexile: '420-820',
            fountas_pinnell: 'R',
            complexity_level: 'Middle high',
        },
        {
            grade: '5th grade',
            level: 29,
            description:
                'Located and infer specific information word low extension or intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree. Recognize teaching message or text.',
            lexile: '420-820',
            fountas_pinnell: 'S',
            complexity_level: 'High',
        },
        {
            grade: '5th grade',
            level: 30,
            description:
                'Located and infer specific information word low extension or intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree. Information related text with everyday life.',
            lexile: '420-820',
            fountas_pinnell: 'S',
            complexity_level: 'Higher',
        },
        {
            grade: '6th grade',
            level: 31,
            description:
                'Located and infer specific information of short texts and intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree.',
            lexile: '420-820',
            fountas_pinnell: 'T',
            complexity_level: 'Low',
        },
        {
            grade: '6th grade',
            level: 32,
            description:
                'Located and infer specific information of short texts and intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree.',
            lexile: '420-820',
            fountas_pinnell: 'T',
            complexity_level: 'Medium low',
        },
        {
            grade: '6th grade',
            level: 33,
            description:
                'Located and infer specific information of short texts and intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree. Recognize communicative purpose in the text.',
            lexile: '420-820',
            fountas_pinnell: 'U',
            complexity_level: 'Medium',
        },
        {
            grade: '6th grade',
            level: 34,
            description:
                'Located and infer specific information of short texts and intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree. Recognize the scope, or world culture which text is associated.',
            lexile: '420-820',
            fountas_pinnell: 'U',
            complexity_level: 'Middle high',
        },
        {
            grade: '6th grade',
            level: 35,
            description:
                'Located and infer specific information of short texts and intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree. Recognize the message or teaching text.',
            lexile: '420-820',
            fountas_pinnell: 'V',
            complexity_level: 'High',
        },
        {
            grade: '6th grade',
            level: 36,
            description:
                'Located and infer specific information of short texts and intermediate, continuous and / or discontinuous, with average semantic and paragraphs moderate extension according to their degree. Information related text with everyday life.',
            lexile: '420-820',
            fountas_pinnell: 'V',
            complexity_level: 'Higher',
        },
        {
            grade: '7th grade',
            level: 37,
            description:
                'Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic.',
            lexile: '740-1010',
            fountas_pinnell: 'W',
            complexity_level: 'Low',
        },
        {
            grade: '7th grade',
            level: 38,
            description:
                'Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic. Recognize the communicative purpose of the text.',
            lexile: '740-1010',
            fountas_pinnell: 'W',
            complexity_level: 'Medium low',
        },
        {
            grade: '7th grade',
            level: 39,
            description:
                'Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic. Recognize the world or culture field which is associated text.',
            lexile: '740-1010',
            fountas_pinnell: 'W',
            complexity_level: 'Medium',
        },
        {
            grade: '7th grade',
            level: 40,
            description:
                'Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic. People recognize values ​​or characters.',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'Middle high',
        },
        {
            grade: '7th grade',
            level: 41,
            description:
                'Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic. Evaluate the opinion of a person or character.',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'High',
        },
        {
            grade: '7th grade',
            level: 42,
            description:
                'Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic. Information related text with everyday life.',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'Higher',
        },
        {
            grade: '8th grade',
            level: 43,
            description:
                'Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic.',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'Low',
        },
        {
            grade: '8th grade',
            level: 44,
            description:
                ' Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic. Recognize various text types.',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'Medium low',
        },
        {
            grade: '8th grade',
            level: 45,
            description:
                'Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic. Recognize various text types.',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'Medium',
        },
        {
            grade: '8th grade',
            level: 46,
            description:
                'Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic. Recognize communicative purpose.',
            lexile: '740-1010',
            fountas_pinnell: 'Y',
            complexity_level: 'Middle high',
        },
        {
            grade: '8th grade',
            level: 47,
            description:
                'Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic. They recognize sphere, world or culture to which a text is associated.',
            lexile: '740-1010',
            fountas_pinnell: 'Y',
            complexity_level: 'High',
        },
        {
            grade: '8th grade',
            level: 48,
            description:
                'Locate specific information and infer short, intermediate or long texts. Work continuous texts, discontinuous and / or mixed, complexity and high average semantic. Predict effects or consequences of actions.',
            lexile: '740-1010',
            fountas_pinnell: 'Y',
            complexity_level: 'Higher',
        },
        {
            grade: '9th grade',
            level: 49,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. Relate and appreciate multiple text types.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Low',
        },
        {
            grade: '9th grade',
            level: 50,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. Recognize communicative purpose of the text.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Medium low',
        },
        {
            grade: '9th grade',
            level: 51,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. Recognize features and / or content.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Medium',
        },
        {
            grade: '9th grade',
            level: 52,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. Recognize scope, world and / or culture to which the text and the main message is associated or teaching delivery.',
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'Middle high',
        },
        {
            grade: '9th grade',
            level: 53,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. Recognize the main message or teaching and evaluate facts in a text.',
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'High',
        },
        {
            grade: '9th grade',
            level: 54,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. Recognize values ​​in people and characters and predict effects and / or consequences of actions in a text.',
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'Higher',
        },
        {
            grade: '10th grade',
            level: 55,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. People recognize values ​​and characters of a text.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Low',
        },
        {
            grade: '10th grade',
            level: 56,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. Evaluate the opinion of a person or character in a text.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Medium low',
        },
        {
            grade: '10th grade',
            level: 57,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. Relate facts of the text to the context of production.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Medium',
        },
        {
            grade: '10th grade',
            level: 58,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. Evaluate the argument of a person or character in a text.',
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'Middle high',
        },
        {
            grade: '10th grade',
            level: 59,
            description:
                "Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. Relate the text to everyday life and contrasting views presented in the text with visions of today's world.",
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'High',
        },
        {
            grade: '10th grade',
            level: 60,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts, discontinuous and / or mixed, complexity and high semantic. critically analyze a character and relate the facts of the text in the context of production.',
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'Higher',
        },
        {
            grade: '11th grade',
            level: 61,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. In addition, they are recognizing the communicative purpose of the text.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Low',
        },
        {
            grade: '11th grade',
            level: 62,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. Evaluates the argument that uses a character or person in the text.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Medium low',
        },
        {
            grade: '11th grade',
            level: 63,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. Related information submitted to daily life.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Medium',
        },
        {
            grade: '11th grade',
            level: 64,
            description:
                "Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. Contrasting views presented in the text with visions of today's world.",
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'Middle high',
        },
        {
            grade: '11th grade',
            level: 65,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. Explain how the resources present in the text contribute to the expression of ideas.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'High',
        },
        {
            grade: '11th grade',
            level: 66,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. Critically analyze a character and relate facts to the context of production.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'Higher',
        },
        {
            grade: '12th grade',
            level: 67,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. They recognize sphere, world or culture with which the text is associated.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Low',
        },
        {
            grade: '12th grade',
            level: 68,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. Related information submitted to daily life.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Medium low',
        },
        {
            grade: '12th grade',
            level: 69,
            description:
                "Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. Contrasting views presented in the text with visions of today's world.",
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Medium',
        },
        {
            grade: '12th grade',
            level: 70,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. Explain how the resources present in the text contribute to the expression of ideas.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'Middle high',
        },
        {
            grade: '12th grade',
            level: 71,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. Critically analyze a character.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'High',
        },
        {
            grade: '12th grade',
            level: 72,
            description:
                'Locate specific information and infer extensive short texts, intermediate and. Work continuous texts discontinuous and mixed, complexity and high semantic. Predict the effects and / or consequences of actions and events related to the context of production.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'Higher',
        },
    ],
}
