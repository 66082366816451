export default {
    accuracy: '% de Respuestas Correctas',
    activate_session: 'Activar sesión',
    add_new_session: 'Agregar Nueva Sesión',
    all_tiers: 'Todos los niveles',
    all_tiers_global: 'Global',
    avg_reading_time: 'Promedio tiempo de lectura',
    avg_number_texts_read: 'Promedio número de textos leídos',
    back_to_sessions: 'Volver a sesiones y datos',
    cancel: 'Cancelar',
    compared_performance: 'Rendimiento comparado',
    confirm: 'Confirmar',
    continue: 'Seguir',
    correct: 'Correctas',
    incorrect: 'Incorrectas',
    currently_reading: 'Actualmente leyendo',
    ending_time: 'Hora de finalización',
    find_locate: 'Localizar Información',
    focus_skill: 'Habilidad de enfoque',
    group: 'Grupo',
    groups_by_tier: 'Grupos por nivel',
    instructional_goal: 'Objetivo educativo',
    integrate_interpret: 'Integrar e Interpretar',
    level: 'Nivel',
    live_session: 'Monitoreo y sesiones en vivo',
    live_session_tab: 'Monitoreo y<br/> Sesiones en vivo',
    my_students: 'Mis Estudiantes',
    next_step: 'Próximo paso',
    no_data: 'No hay datos',
    no_students_data: 'No hay estudiantes activos en esta clase',
    number_groups: 'Número de grupos',
    number_short_texts: 'Número de textos breves',
    number_subgroups: 'Número de sub-grupos',
    performance_genre: {
        female: 'su',
        male: 'su',
    },
    performance_measure: {
        low: 'inferior',
        high: 'superior',
    },
    performance_measure_student: {
        low: 'inferior',
        high: 'superior',
    },
    performance_text: `En promedio, el desempeño en habilidades de sus estudiantes fue del {percent}% {reference} en esta sesión.`,
    performance_text_student:
        '{name} está en el {percent}% {reference} de {genre} salón de clases.',
    period: 'Periodo',
    periods: [
        '7 Días',
        '30 Días',
        '90 Días',
    ],
    reading_time: 'Tiempo de lectura',
    reflect_valuate: 'Reflexionar y evaluar',
    repeat: 'Repetir',
    repeat_options: ['Una vez a la semana durante 1 mes', 'Una vez a la semana durante 2 mes'],
    select_class: 'Seleccione la clase',
    select_group_tier: 'Seleccione el grupo por nivel',
    select_session: 'Seleccione la sesión',
    select_start_date: 'Seleccione la fecha de inicio',
    session_activated: '¡Sesión activada!',
    session_live: 'Sesión en vivo',
    sessions: 'Sesiones',
    sessions_data: 'Sesiones y Datos',
    sessions_data_tab: 'Sesiones y<br/> Datos',
    sessions_dates: 'Sesiones y Datos',
    sessions_number: [
        '1 Grupo',
        '2 Grupos',
        '3 Grupos',
        '4 Grupos',
        '5 Grupos',
        '6 Grupos',
        '7 Grupos',
        '8 Grupos',
    ],
    sessions_subnumber: ['Aula'],
    set_group: 'Establecer grupo',
    set_new_session: 'Establecer nueva sesión',
    set_up: 'Preparar',
    set_up_groups: 'Configurar grupos',
    set_up_successfully: 'Se ha configurado correctamente.',
    short_texts: 'Textos cortos',
    skills_options: [
        'Recomendado por Beereaders',
        'Localizar información',
        'Integrar e interpretar',
        'Evaluar',
    ],
    starting_time: 'Hora de inicio',
    student_attendance_at_session: 'Asistencia de los estudiantes a esta sesión',
    students: 'Estudiantes',
    student_students: ['Estudiante', 'Estudiantes'],
    texts_read: 'Textos leídos',
    tier_text: ['Nivel 1: 61%-100%', 'Nivel 2: 41%-60%', 'Nivel 3: 0%-40%'],
    well_done: '¡Bien hecho!',
}
