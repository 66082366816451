export default {
    titles: {
        readerCharacteristics: 'Características del lector',
        textCharacteristics: 'Características del texto',
        beereadersReadingStages: 'Etapa de progreso de lectura BeeReaders',
    },
    tooltips: {
        lexileFountasPinell: 'BeeReaders incorpora elementos de Lexile® y Fountas & Pinnell para calificar los niveles de dificultad de textos. Se analizó todo el contenido y establecieron rangos de Lexile® y Fountas & Pinnell para cada etapa de lectura de BeeReaders.'
    },
    cards: {
        stages: {
            title: 'Etapas de progresión lectora en español de BeeReaders',
            content: 'El sistema de progresión lectora en español de BeeReaders es un sistema de nivelación de texto y lector en español que integra habilidades, objetivos y criterios del mundo de habla hispana, al tiempo que sintetiza y agrupa los elementos transversales y complementarios que se encuentran en las evaluaciones internacionales de lectura más relevantes. Nuestro sistema se divide en 72 etapas para estudiantes lectores y 12 etapas para estudiantes en etapa preinicial. Cada etapa ejercita habilidades relacionadas a las características propias del tipo de lector.'
        },
        information: {
            title: 'Niveles de respuesta a la intervención en el modelo BeeReaders',
            content: 'BeeReaders incorpora los principios y pilares de la respuesta a la intervención (RTI, Response to Intervention). Estos niveles (tiers en inglés) brindan al equipo docente una visión general de su grupo de estudiantes y además permiten agrupar a los estudiantes de acuerdo con su desempeño, sugiriendo el tipo de intervención o enriquecimiento más apropiado.'
        },
        characteristics: {
            title: 'Características específicas de los textos para cada etapa de lectura en el sistema BeeReaders',
            content: 'El sistema BeeReaders se divide en setenta y dos etapas de progresión lectora para lectores que dominan la comprensión y 12 etapas para lectores preiniciales. Cada etapa de ejercitación está asociada a habilidades y características propias del tipo de lector.'
        }
    },
    readers: {
        explorer: {
            title: 'Lector Explorador',
            readerCharacteristics: [
                'Es un lector emergente que tiene una comprensión incipiente de la conexión entre fonemas y grafemas.',
                'Está familiarizado con algunas letras del alfabeto.',
                'Tiene una incipiente conciencia fonológica y fonémica.',
                'Reconoce algunas palabras (por ejemplo, nombres), letras mayúsculas y minúsculas, sonidos y formas de letras.',
                'Puede identificar el sonido y/o la primera y última sílaba de las palabras de alta frecuencia.',
                'Es capaz de dividir palabras por sílabas en textos sobre temas familiares.',
            ],
            textCharacteristics: [
                'Se componen de párrafos cortos.',
                'Presentan palabras de manera lineal (de izquierda a derecha).',
                'Usan oraciones simples que contienen palabras de alta frecuencia.',
                'Repiten palabras y frases a lo largo del texto.',
                'Presentan palabras fonéticamente consistentes.',
                'Presentan temas simples y familiares y elementos concretos.',
                'Presentan pocas líneas de texto por página.',
                'Usan texto predecible con imágenes y apoyos auditivos.'
            ],
            beereadersStageInformation: 'Las preguntas se centran en fonética, decodificación, vocabulario y en niveles básicos de comprensión (localizar, inferir y reflexionar sobre la información general presentada en un texto determinado).'
        },
        initial: {
            title: 'Lector Inicial',
            readerCharacteristics: [
                'Es un lector que conoce conceptos del alfabeto y reconoce palabras.',
                'Conecta letras y sonidos.',
                'En ocasiones, utiliza ilustraciones para apoyar la comprensión.',
                'Es capaz de leer textos simples, a veces con ayuda.',
                'Tiene habilidades incipientes de decodificación.',
                'Puede leer palabras a la vista y tiende a leer en voz alta.',
                'Es capaz de leer y comprender textos sencillos sobre temas familiares.',
            ],
            textCharacteristics: [
                'Se componen de oraciones simples que incluyen palabras compuestas y polisílabas comunes.',
                'Presentan historias familiares con elementos de la vida cotidiana.',
                'Se componen de párrafos cortos y estructuras simples.',
                'Contienen elementos repetitivos, pero con cambios en el patrón.',
                'Presentan oraciones progresivamente más largas e incluyen cambios en los tiempos verbales.',
                'Incluyen diálogos simples.',
            ],
            beereadersStageInformation: 'Las preguntas de comprensión son muy básicas, evaluando la comprensión general y detalles mínimos. La gran mayoría de preguntas para este tipo de lectores se enfocan en que el alumno localice u obtenga información.'
        },
        bold: {
            title: 'Lector Intrépido',
            readerCharacteristics: [
                "Es un lector que está empezando a hacer la transición de 'aprender a leer' a 'leer para aprender'.",
                'Continúa desarrollando la capacidad de lectura mediante el uso de estrategias para identificar el significado de palabras desconocidas.',
                'Demuestra una mayor fluidez de lectura.',
                'Tiene una incipiente automaticidad con palabras a la vista y palabras de alta frecuencia.',
                'Es principalmente decodificador, pero con comprensión incipiente.',
                'Es capaz de monitorear su lectura.',
                'Lee cada vez más y más fluidamente en voz alta.',
            ],
            textCharacteristics: [
                'Se componen de oraciones complejas y puntuación variada.',
                'Utilizan menos ilustraciones.',
                'Hacen uso del lenguaje literario.',
                'Presentan una mayor complejidad en las tramas.',
                'Incluyen menos patrones repetitivos.',
            ],
            beereadersStageInformation: 'En este nivel, las preguntas de comprensión son de dificultad intermedia, cubriendo preguntas de varios niveles de comprensión. El foco de la mayoría de preguntas de este tipo de lector busca examinar la capacidad de integrar e interpretar la información.'
        },
        independent: {
            title: 'Lector Independiente',
            readerCharacteristics: [
                "Este lector lee de forma autónoma y entiende una amplia gama de textos.",
                'Lee para adquirir conocimiento.',
                'Implementa estrategias de lectura.',
                'Desarrolla puntos de vista en relación con el texto.',
                'Tiene una comprensión fluida.',
                'Puede leer cientos de palabras de alta frecuencia con fluidez.',
                'Utiliza el contexto para ayudarse con el vocabulario.',
                'Comienza a hacer inferencias e integrar información.',
                'Tiende a leer en silencio.',
            ],
            textCharacteristics: [
                'Utilizan un uso figurativo del lenguaje y el uso de recursos lingüísticos y literarios.',
                'Incluyen menos ilustraciones de apoyo a la lectura.',
                'Presentan diversos formatos que incluyen variedad de textos de no ficción, como infografías.',
                'Se componen de palabras polisílabas y oraciones complejas.',
                'Se dividen en párrafos de mayor extensión y/o capítulos.',
                'Presentan personajes y tramas más complejas.',
                'Usan, en su gran mayoría, tamaños más pequeños de tipografía.',
                'Incluyen menos ilustraciones de apoyo a la lectura.',
            ],
            beereadersStageInformation: 'En este nivel las preguntas de comprensión son de dificultad intermedia - avanzada, con preguntas de diversos grados de comprensión. La gran mayoría de preguntas para este tipo de lector ejercita la habilidad de integrar e interpretar la información.'
        },
        youth: {
            title: 'Lector Juvenil',
            readerCharacteristics: [
                "Este lector es capaz de sintetizar, reflexionar y aplicar múltiples perspectivas al material de lectura.",
                'La lectura es autónoma y fluida.',
                'Posee un vocabulario amplio.',
                'Lee casi siempre en silencio.',
                'Es capaz de identificar patrones de palabras y experimentar con raíces y afijos cuando se encuentra con nuevas palabras.',
                'Puede analizar diversidad de textos y hacer predicciones.',
            ],
            textCharacteristics: [
                'Incluyen temas e historias de mayor profundidad crítica.',
                'Utilizan una serie de recursos lingüísticos y literarios.',
                'Emplean palabras polisílabas y oraciones multicláusula.',
                'Presentan historias y temas complejos.',
                'Incluyen diálogos y diversidad de secuencias.',
                'Utilizan variadas referencias bibliográficas.',
            ],
            beereadersStageInformation: 'En este nivel, las preguntas de comprensión son de dificultad avanzada, con preguntas que evalúan varios grados de comprensión. La gran mayoría de preguntas para este tipo de lectores examina la capacidad de reflexionar y evaluar la información presentada en el texto.'
        },
        reflective: {
            title: 'Lector Reflexivo',
            readerCharacteristics: [
                "Este tipo de lector es capaz de integrar perspectivas disciplinarias más sofisticadas.",
                'Está motivado a leer por razones personales (educativas, laborales, familiares, sociales).',
                'Despliega un conjunto de estrategias cognitivas y metacognitivas durante la lectura.',
                'Es capaz de realizar lecturas críticas y comparativas.',
                'Ha dominado una amplia gama de géneros y categorías de textos.',
                'Es capaz de interpretar múltiples puntos de vista.',
                'La comprensión ha reemplazado por completo la decodificación.',
                'Es capaz de realizar lecturas a largo plazo durante días y semanas.',
                'Aplica el conocimiento de las raíces griegas y latinas para reconocer el significado de palabras.',
                'Es capaz de construir nuevos conocimientos mediante la integración de diferentes fuentes de información.',
            ],
            textCharacteristics: [
                'Presentan una variedad de perspectivas.',
                'Incluyen una variedad de géneros y modalidades textuales.',
                'Incluyen palabras polisílabas y oraciones multicláusula.',
                'Presentan tramas y temas complejos.',
                'Utilizan una variedad de recursos literarios y lingüísticos.',
                'Usan un amplio vocabulario, incluyendo términos técnicos y científicos.',
            ],
            beereadersStageInformation: 'En este nivel, las preguntas de comprensión son de dificultad avanzada, con preguntas que evalúan varios grados de comprensión. El mayor porcentaje de preguntas para este tipo de lector ejercitan la capacidad de reflexionar y evaluar la información del texto.'
        },
    },
    utils: {
        none: 'Ninguno',
    }
}