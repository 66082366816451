const defaultState = () => ({
    user: null,
    courses: [],
    students: [],
    countStudent: 0,
    userLoaded: false,
})

const userData = {
    namespaced: true,
    state: defaultState(),
    mutations: {
        setUserInfo: (state: any, data: any) => {
            if (data.user) state.user = data.user
            if (data.userProfile) state.userProfile = data.userProfile
            if (data.countStudent) state.countStudent = data.countStudent
            if (data.avatarSelected) state.avatarSelected = data.avatarSelected
        },
        setCoursesByTeacher: (state: any, value: any) => {
            state.courses = value
        },
        setStudentsByTeacher: (state: any, value: any) => {
            state.students = value
        },
        reset: (state: any) => {
            Object.assign(state, defaultState())
        },
    },
    actions: {
        setUserInfo({ commit }: any, value: any) {
            commit('setUserInfo', value)
        },
        setCoursesByTeacher({ commit }: any, value: any) {
            commit('setCoursesByTeacher', value)
        },
        setStudentsByTeacher({ commit }: any, value: any) {
            commit('setStudentsByTeacher', value)
        },
        setUserLoaded({ state }: any, value: any) {
            state.userLoaded = value
        },
        resetUserState({ commit }: any) {
            commit('reset')
        },
    },
}

export default userData
