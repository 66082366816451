export default {
    first: '1st grade',
    second: '2nd grade',
    third: '3rd grade',
    fourth: '4th grade',
    fiveth: '5th grade',
    sixth: '6th grade',
    seventh: '7th grade',
    eighth: '8th grade',
    nineth: '9th grade',
    tenth: '10th grade',
    eleventh: '11th grade',
    twelveth: '12th grade',
}
