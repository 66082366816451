import Services from "../base-services";
import mapParameters from "@/utils/map-parameters";

const service = new Services('evaluacionDiagnostico')

export const evaluacionDiagnostico = {
  async generateEvaluationDataFile(assigmentId: string) {
    const data = await service.get(`generateEvaluationDataFile`, {
      params: {
        assigmentId: assigmentId,
      },
      responseType: 'blob',
    })

    return data
  },
}