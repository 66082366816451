export enum SurveyQuestionType {
    RATE = 'RATE',
    TEXT = 'TEXT'
} 

export type SurveyQuestion = {
    id: number;
    question: string;
    questionType: SurveyQuestionType;
    required?: boolean
    placeholder?: string
    labels?: {
        start: string,
        end: string 
    }
}

export interface Survey {
    id: number
    title: string
    createdDate: string;
    answeredDate?: string | null;
    surveyType: string;
    questions: SurveyQuestion[]
}

export type Answer = {
    questionId: number;
    answer: any;
}

export interface SurveyGlobalState {
    surveys: Survey[];
    currentSurvey: Survey | null;
    answers: Answer[]
    currentAnswer: Answer | null;
    currentQuestion: SurveyQuestion | null;
    currentQuestionIndex: number;
    error?: string;
}