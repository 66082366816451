import { DateUtils } from '../libraries'

const dateUtils = new DateUtils()

export default (value: any, format: { date: boolean, location: string}) => {
    if (!value) {
        return ''
    }
    if (!format) {
        format = { date: true, location: 'en' }
    }

    const formattedDate = dateUtils.formatLastLogin(new Date(value), format)

    return formattedDate.trim()
}
