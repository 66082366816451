export default {
    diagnoses: 'Diagnoses',
    diagnosis: {
        types: {
            initial: 'Diagnostic evaluation',
            middle: 'Progressive evaluation',
            final: 'Closing evaluation',
        },
        assignmentType: {
            AUTOMATIC: 'AUTOMATIC',
            ASSIGNED: 'ASSIGNED',
        },
    },
    titles: {
        assessmentOfReadingProficiency: 'Assessment of Reading Proficiency',
        evaluations: 'Diagnostics',
        assignedEvaluations: 'Diagnostics Assigned',
        results: 'Results',
        generalInformation: 'Overall Information',
        diagnosesInformation: 'Diagnoses Information',
        classResultsPerSkill: 'Class Results',
        studentsList: 'Student Results',
        assignEvaluation: 'Assign evaluation',
        assignDiagnosis: 'Assign diagnosis',
        updateDiagnosis: 'Update diagnosis',
        assignmentsAndLectures: 'Assignments and Lectures',
    },
    coursesList: {
        '1-3': '1° to 3° grade',
        '4-6': '4° to 6° grade',
        '7-8': '7° to 8° grade',
        '9-10': '9° to 10° grade',
        '11-12': '11° to 12° grade',
        '11-13': '11° to 13° grade',
        '12-13': '12° to 13° grade',
    },
    utils: {
        list: 'List of Diagnostics',
        instructions: 'Instructions to assign the diagnostics',
        skills: {
            locate: 'Locate Information',
            integrate: 'Integrate and Interpret',
            reflect: 'Reflect and Evaluate',
            readingComprenhension: 'Reading Comprenhension',
        },
        totalOfQuestions: 'Total of questions',
        diagnosisType: 'Diagnosis type',
        text: 'Text',
        question: 'Question',
        to: 'to',
        grade: 'grade',
    },
    texts: {
        evaluations: {
            information: `<div class="evaluation-info">
            <div class="evaluation-wrapper mb-2">
              <h4>Diagnostic Evaluation</h4>
              <div class="evaluation-desc">
                  <p><b>Date:</b> Beginning of the school year.</p>
                  <p><b>Objective:</b> Provide information about the state of reading skills to plan classes and promote comprehensive education in students.</p>
              </div>
            </div>
            <div class="evaluation-wrapper mb-2">
              <h4>Progressive Evaluation</h4>
              <div class="evaluation-desc">
                  <p><b>Date:</b> Middle of the school year.</p>
                  <p><b>Objective:</b> Provide information about the achievements in students' reading skills so that teachers and administrators can adjust the planning for the second semester and adapt pedagogical actions.</p>
              </div>
            </div>
            <div class="evaluation-wrapper mb-2">
              <h4>Closure Evaluation</h4>
              <div class="evaluation-desc">
                <p><b>Date:</b> End of the school year.</p>
                <p><b>Objective:</b> Provide information about the achievements and progress in students' reading skills so that teachers and administrators evaluate the actions taken during the ending year and use this information to plan for the next school year.</p>
              </div>
            </div>
            <div class="evaluation-important">
                <h4>
                    <span class="icon-wrap"><i class="material-icons mdi mdi-information md-18"></i></span>
                    <b>Important</b>
                </h4>
              <div class="important-desc">
                  <ul>
                    <li>The diagnostic evaluation should always precede other evaluations. This is used to position the student in the BeeReaders Stage, which determines the level of difficulty of the Challenges and is observed both in the results of the diagnostic evaluation and in the Student Performance table in the Reports section. Likewise, it groups students into the three levels of intervention.</li>
                    <li>The progressive and closing assessments calculate the student's BeeReaders Stage, but are only informative and DO NOT modify the Stage seen in the Student Performance table in the Reports section. These evaluations regroup students in the three levels of intervention.</li>
                  </ul>
              </div>
            </div>
        </div>`
        },
        tooltips: {
            diagnosesState: {
                completed: 'Completed evaluation',
                inProgress: 'Pending evaluation',
            }
        }
    },
    tables: {
        evaluationsList: {
            labels: {
                section: 'Segment',
                initial: 'Diagnostic evaluation',
                middle: 'Progressive evaluation',
                final: 'Closing evaluation',
            }
        },
        assignedEvaluations: {
            labels: {
                evaluation: 'Diagnostic',
                state: 'Progress',
                assignedTo: 'Assigned to',
                course: 'Class',
                startDate: 'Start Date',
                endDate: 'End date',
                actions: 'Actions',
                assignmentType: 'Type',
            }
        },
        studentsList: {
            labels: {
                student: 'Student',
                state: 'Status',
                tier: 'Tier',
                actions: 'Actions',
                beeStage: 'BeeReaders Stage',
            }
        }
    },
    inputs: {
        labels: {
            evaluationType: 'Evaluation type',
            state: 'State',
            course: 'Class',
            evaluation: 'Diagnostic',
        },
        placeholders: {
            chooseEvaluationType: 'Choose an evaluation type',
            chooseEvaluationState: 'Choose an evaluation state',
            chooseCourse: 'Choose a course',
        },
        filters: {
            diagnosticState: {
                active: 'Active',
                inactive: 'Inactive',
            },
            evaluationType: {
                all: 'All',
                initial: 'Diagnostic evaluation',
                middle: 'Progressive evaluation',
                final: 'Closing evaluation',
            },
            utils: {
                all: 'All',
            }
        },
    },
    actions: {
        evaluation: {
            assign: 'Assign evaluation',
            edit: 'Edit evaluation',
            delete: 'Delete evaluation',
            update: 'Update evaluation',
            activate: 'Activate evaluation',
        },
        diagnosis: {
            assign: 'Assign a Diagnostic',
            edit: 'Edit diagnosis',
            delete: 'Delete diagnosis',
            update: 'Update diagnosis',
            activate: 'Activate diagnosis',
            nextText: 'Next text',
            prevText: 'Previous text',
        },
        export: 'Export',
        assign: 'Assign',
        view: 'See',
        confirmation: {
            delete: "Do you confirm that you want to delete this evaluation? You won't be able to get it back later.",
            assign: 'Are you sure do you want to assign this evaluation? You can delete after'
        }
    },
    errors: {
        codes: {
            700: 'Maximum number of evaluations reached',
            701: 'Level is already assigned',
            702: 'Evaluation has answers',
            703: 'Evaluation does not exists',
            default: 'An error has occurred. Please try again later',
        },
    },
}
