export default {
    reports: 'Reports & Analytics',
    activities: 'Lesson plans and activity sheets',
    teacher_training: 'Tutorials and webinars',
    message_center: 'Message center',
    resources_room: 'Resource room',
    categories: 'Categories',
    all: 'All',
    student_tutorial: 'Student tutorial',
    teacher_tutorial: 'Teacher tutorial',
    recorded_webinar: 'Recorded webinars',
    new_message: 'New message',
    inbox: 'Inbox',
    sent_messages: 'Sent messages',
    archived: 'Archived',
    trash: 'Trash',
    paginator: `<b>{0} - {1}</b> of {2}`,
    file: 'File',
    forward: 'Forward',
    delete: 'Delete',
    reply: 'Reply',
    to_students: 'To students:',
    to_groups: 'To groups:',
    send_message: 'Send message',
    to: 'To:',
    from: 'From:',
    subject_required: 'Subject is required.',
    body_required: 'Message is required.',
    student_required: 'You must select at least one student',
    group_required: 'You must select a grade and a class',
    not_videos: 'There is no content to display.',
    report_type: 'Type of report:',
    activity_type: 'Type of activity',
    preview: 'Preview',
    unable_to_get_student_report: "Unable to get students' report for this class",
    unable_to_get_course_report: 'Unable to get class report',
    from_six_years: 'From 6 years',
    from_nine_years: 'From 9 years',
    from_twelve_years: 'From 12 years',
    from_fourteen_years: 'From 14 years',
    from_sixteen_years: 'From 16 years',
    monthly_report: 'Monthly report',
    must_select_course: 'Select a class',
    select_report_month: 'Select report month',
    report_month: 'Report month',
    course_to_download: 'Class to download',
    must_select_month: 'Select a month',
    school: 'School',
    teacher_in_charge: 'Teacher name',
    segment: 'Segment',
    segment_values: ['61%-100%', '41%-60%', '0%-40%'],
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    to_the: 'to',
    of: 'from:',
    reported_period: 'Reported period',
    course_section: 'Class / Section',
    current_level: 'Current level',
    detail_by_student: 'Individual student data',
    average_and_totals: 'AVERAGE AND TOTALS',
    name: 'Name',
    books: 'Books',
    challenges: 'Challenges',
    skills: 'Skills',
    total_average: 'Total average',
    monthly_report_no_data: 'There is no data for this class for the period selected.',
    accept: 'Accept',
    cl_report: 'C.L. = Current level according to Beereaders Leveling System',
    percent_pages: '% Pag. = Percentage of pages read',
    percent_reading: '% R.C = Percentage of reading comprehension',
    challenges_number: 'N° chall. = Number of challenges',
    f_report: 'L = Locate information',
    i_report: 'I = Integrate and interpret',
    r_report: 'R = Reflect and evaluate',
    report_header_abbreviation: ['I.D.', '% Pag.', '% R.C.', 'N° chall.', 'F', 'I', 'R', 'C.L.'],
    di_report: 'I.D. = Initial Diagnostic',
    warning_percent_cl: '*The percentage of reading comprehension is calculated considering the total number of questions answered by each student.',
    warning_quantity_answer_challenge: '*Each challenge is made up of a set of 4 questions per text.',
    reading_progression_system: 'Reading Progress Framework',
    select_reader_type: 'Choose the Reader Type',
}
