import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './en'

import es from './es'

Vue.use(VueI18n)

const messages = {
    es,
    en,
}
// @ts-ignore
const userLang = navigator.language || navigator.userLanguage || navigator.browserLanguage
const lang = /^es\b/.test(userLang) ? 'es' : 'en'

const i18n = new VueI18n({ locale: lang, fallbackLocale: lang, messages })

export default i18n

// @ts-ignore
