import Services from './base-services'

const service = new Services('bookReading')

export default {
    getBookFullDataById(bookId: any) {
        return service.post(`getBookFullDataById?bookId=${bookId}`)
    },
    updateBookReadConfig(data: any) {
        return service.put('updateBookReadConfig', data)
    },
    uploadUserReadingSpeed(data: any) {
        return service.post(`uploadUserReadingSpeed`, data)
    },
}

