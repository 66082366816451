<template>
    <vue-ellipse-progress
        :progress="value"
        :size="size"
        :legend="false"
        :emptyColor="`${emptyColor}`"
        :color="gradient"
        thickness="10"
        emptyThickness="10"
        :dot="`15 ${color}`"
        class="mx-1"
    >
        <div slot="legend-caption" class="black--text font-size-28">
            {{ `${value}%` }}
        </div>
    </vue-ellipse-progress>
</template>

<script>
export default {
    name: 'EllipseProgress',
    props: {
        value: { type: [String, Number], default: 0 },
        size: { type: [String, Number], default: 100 },
        color: { type: String, default: '#514773' },
        title: { type: String, default: 'no title' },
    },
    computed: {
        gradient() {
            return {
                radial: false,
                colors: [
                    {
                        color: this.color,
                        offset: '0',
                        opacity: '1',
                    },
                    {
                        color: this.color,
                        offset: '100',
                        opacity: '1',
                    },
                ],
            }
        },
        emptyColor() {
            // Convert the first 2 characters to hexadecimal
            const r = parseInt(this.color.substring(1, 3), 16)

            // Convert the middle 2 characters to hexadecimal
            const g = parseInt(this.color.substring(3, 5), 16)

            // Convert the last 2 characters to hexadecimal
            const b = parseInt(this.color.substring(5, 7), 16)

            // append them all
            return `rgba(${r}, ${g} ,${b},.3)`
        },
    },
}
</script>

<style scoped></style>
