import { Course, CourseBasicData } from "@/data/dto/course"
import { Student } from "@/data/dto/student"
import { TableSkillData } from "@/data/reports/tables"

export enum DIAGNOSIS_STATE {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
}

export enum DIAGNOSIS_ERROR_TYPE {
    WORD_OMISSION = 'OMISSION',
    WORD_SUBSTITUTION = 'SUBSTITUTION',
    WORD_INSERTION = 'INSERTION',
    WORD_REPETITION = 'REPETITION',
    WORD_REVERSAL = 'REVERSAL',
    DICTION_ERROR = 'DICTION_ERROR',
}

export enum DIAGNOSIS_READING_QUALITY {
    NO_READER = 'NO_READER',
    SYLLABIC_READING = 'SYLLABIC_READING',
    WORD_BY_WORD_READING = 'WORD_BY_WORD_READING',
    SHORT_UNITS_READING = 'SHORT_UNITS_READING',
    FLUENT_READING = 'FLUENT_READING',
}

export enum DIAGNOSIS_EVALUATION_TYPE {
    INITIAL = 'INITIAL_EVALUATION',
    MIDDLE = 'MIDDLE_EVALUATION',
    FINAL = 'FINAL_EVALUATION',
}

export enum DIAGNOSIS_EVALUATION_STATE {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum DIAGNOSIS_RESULT_TYPE {
    MANUAL = 'MANUAL',
    AI = 'AI',
}

export type EvaluationText = { 
    id: number,
    grade: number,
    text: string,
    title: string,
    word_count: string,
    created_at: string,
    updated_at: string,
    splitted_words: string[],
}

export interface DiagnosticsGlobalState {
    currentCourseResultsID: number | null,
    currentCourseTextResultsID: number | null,
    currentAssignedEvaluationDetail: EvaluationFullData | null
    assignedEvaluations: EvaluationSimpleData[]
    diagnosisResultsPreview: DiagnosisResultsPreviewData | null
    diagnosisResultsDetail: DiagnosisResultsFullData | null
    evaluationsTexts: EvaluationText[]
    newEvaluationData?: {
        startDate?: string,
        endDate?: string,
        courseID?: number,
        type?: DIAGNOSIS_EVALUATION_TYPE,
    }
    filters: {
        currentCourseID?: number,
        currentEvaluationType?: DIAGNOSIS_EVALUATION_TYPE,
        evaluationState?: DIAGNOSIS_EVALUATION_STATE
    },
    errorsType: {
        id: number,
        errorType: DIAGNOSIS_ERROR_TYPE
    }[],
    readingQualityOptions: {
        id: number,
        name: DIAGNOSIS_READING_QUALITY,
    }[]
}

export type StudentWithSkills = Student & {
    skills: {
        locate: TableSkillData | null,
        integrate: TableSkillData | null,
        reflect: TableSkillData | null,
        readingComprehension: number,
    }
}

export type StudentWithDiagnosis = Student & {
    diagnosticState: DIAGNOSIS_STATE
}

export type EvaluationSimpleData = {
    id: number,
    evaluation_type: DIAGNOSIS_EVALUATION_TYPE
    text_id: number
    text_title: string
    course_id: number
    course_name: string
    course_students_count: number
    start_date: string
    finish_date: string
    status: string
    progress: number
    created_at: string
}

export type EvaluationFullData = EvaluationSimpleData & {
    students: StudentWithDiagnosis[]
}

export type DiagnosisResultsPreviewData = {
    id: number,
    student_id: number,
    evaluation_id: number,
    text_id: number,
    word_per_minute: number,
    fluency_level: string,
    recording_url: string,
    errors_count: number,
    errors: DiagnosisError[]
    text_words: WordTranscriptionDiagnosisData[]
    created_at: string
    updated_at: string
    words_in_transcription: WordTranscription[]
    words_to_read: number
    lecture_time?: number
}

export type WordTranscriptionDiagnosisData = {
    correct: boolean
    category: DIAGNOSIS_ERROR_TYPE | "OK"
    textWord: string
    correctWord: string
    transcriptionWord: string
    position: number
}

export type WordTranscription = {
    word: string
    punctuated_word: string
    start: number
    end: number
    confidence: number
}

export type DiagnosisError = {
    category: DIAGNOSIS_ERROR_TYPE,
    correctWord: string
    position: number,
    recordedWord: {
        word: string,
        start: number
        end: number
        punctuated_word: number
        confidence: number
    }[],
}

export type DiagnosisResultsFullData = DiagnosisResultsPreviewData & {
    errors: DiagnosisError[],
    recordData: {
        url: string,
        duration: number,
        autoGeneratedText: string
    }
}