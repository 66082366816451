import Vue from 'vue'
import VueRouter from 'vue-router'
import RequireAuthentication from './guards/require-authentication'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Original Home',
        redirect: '/callback',
    },
    {
        path: '/callback',
        name: 'Callback',
        component: () => import('@/views/default/Callback.vue'),
    },
    {
        path: '/teacher',
        component: () => import('@/views/Index.vue'),
        beforeEnter: RequireAuthentication,
        children: [
            {
                path: 'preview/:bookId',
                name: 'BookPreview',
                component: () => import('../components/library/BookReading.vue'),
                props: true,
            },
            {
                path: 'home',
                name: 'Home',
                component: () => import('../views/teacher/Home.vue'),
            },
            {
                path: '',
                name: 'index',
                redirect: 'quick-stats',
            },
            {
                path: 'quick-stats',
                name: 'Quick Stats',
                component: () => import('../views/teacher/QuickStats.vue'),
            },
            {
                path: 'report-skill-level',
                name: 'Skills & Reading level',
                redirect: '/teacher/report-skill-level/main-stats',
                component: () => import('../views/teacher/SkillReadingLevel.vue'),
                children: [
                    {
                        path: 'main-stats',
                        name: 'Main Stats',
                        component: () => import('../components/reporting/CardSkill.vue'),
                    },
                    {
                        path: 'beereaders-level-system',
                        name: 'Beereaders level system',
                        component: () => import('../components/reporting/CardReadingLevel.vue'),
                    },
                    {
                        path: 'top-students/:courseId',
                        name: 'Top student',
                        component: () => import('../components/reporting/view/TopStudents.vue'),
                        props: true,
                    },
                    {
                        path: 'student-performance/:userId/:accuracy',
                        name: 'Student performance data',
                        component: () => import('../components/reporting/view/StudentPerformanceData.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: 'library',
                name: 'Library',
                redirect: '/teacher/library/books',
                component: () => import('../views/teacher/Library.vue'),
                children: [
                    {
                        path: 'books',
                        name: 'Books',
                        component: () => import('../components/library/Books.vue'),
                    },
                    {
                        path: 'assignments',
                        name: 'Assignments',
                        component: () => import('../components/library/Assignments.vue'),
                    },
                    {
                        path: 'book/:bookId',
                        name: 'Book',
                        component: () => import('../components/library/ToAssignBook.vue'),
                        props: true,
                    },
                    {
                        path: 'book/:bookId/:courseId/:assignmentId/:start/:end',
                        name: 'Modify Book assignment',
                        component: () => import('../components/library/ToAssignBook.vue'),
                        props: true,
                    },
                    {
                        path: 'stats/:assigmentId',
                        name: 'Assignments stats',
                        component: () => import('../components/library/AssigmentStats.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: 'sessions',
                name: 'Sessions & Data',
                redirect: '/teacher/sessions/list',
                component: () => import('../views/teacher/SessionData.vue'),
                children: [
                    {
                        path: 'list',
                        name: 'Sessions',
                        component: () => import('../components/sessions/Sessions.vue'),
                    },
                    {
                        path: 'new',
                        name: 'Sessions New',
                        component: () => import('../components/sessions/SessionsNew.vue'),
                    },
                    {
                        path: 'students',
                        name: 'Students',
                        component: () => import('../components/sessions/Students.vue'),
                    },
                ],
            },
            {
                path: 'resources',
                component: () => import('../views/teacher/ResourceRoom.vue'),
                children: [
                    {
                        path: 'activities',
                        name: 'Activities',
                        component: () => import('../components/resources/Activities.vue'),
                    },
                    {
                        path: 'reports',
                        name: 'Reports',
                        component: () => import('../components/resources/Reports.vue'),
                    },
                    {
                        path: 'teacher_training',
                        name: 'Teacher_training',
                        component: () => import('../components/resources/Training.vue'),
                    },
                    {
                        path: 'message_center',
                        name: 'Message_center',
                        component: () => import('../components/resources/Messages.vue'),
                    },
                    {
                        path: 'monthly_report',
                        name: 'Monthly_report',
                        component: () => import('../components/resources/MonthlyReport.vue'),
                    },
                    {
                        path: 'reading_progression_system',
                        name: 'reading_progression_system',
                        component: () => import('../components/resources/ReadingProgressionSystem.vue'),
                    },
                    {
                        path: '',
                        redirect: '/teacher/resources/activities',
                    }
                ],
            },
            {
                path: 'reports/dashboard',
                component: () => import('../views/teacher/reports/Dashboard.vue'),
                children: [],
            },
            {
                path: 'diagnoses',
                component: () => import('../views/teacher/Diagnoses.vue'),
                children: [
                    {
                        path: 'evaluations',
                        name: 'Evaluations',
                        component: () => import('../components/diagnoses/evaluations/index.vue'),
                    },
                    {
                        path: 'assigned',
                        name: 'assigned_evaluations',
                        component: () => import('../components/diagnoses/assignedEvaluations/index.vue'),
                    },
                    {
                        path: 'results',
                        name: 'results',
                        component: () => import('../components/diagnoses/evaluationsResults/index.vue'),
                    },
                    {
                        path: '',
                        redirect: '/teacher/diagnoses/evaluations',
                    }
                ],
            },
            {
                path: 'evaluations',
                component: () => import('../views/teacher/Evaluate.vue'),
                children: [
                    {
                        path: 'assignments',
                        name: 'assignments_and_lectures',
                        component: () => import('../components/evaluate/assignmentsAndLectures/index.vue'),
                    },
                    {
                        path: 'assign',
                        name: 'assign_evaluation',
                        component: () => import('../components/evaluate/assignEvaluation/index.vue'),
                    },
                    {
                        path: 'evaluate/:assignmentId',
                        name: 'evaluate',
                        component: () => import('../components/evaluate/evaluate/index.vue'),
                    },
                    {
                        path: 'evaluate/:assignmentId/student/:studentId',
                        name: 'evaluate_student',
                        component: () => import('../components/evaluate/studentTextEvaluation/index.vue'),
                    },
                    {
                        path: 'results',
                        name: 'results',
                        component: () => import('../components/evaluate/assignmentsResults/index.vue'),
                    },
                    {
                        path: '',
                        redirect: '/teacher/evaluations/assignments',
                    }
                ],
            },
        ],
    },
    {
        path: '*',
        component: () => import('../views/default/404Page.vue'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
