import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import { es, en } from 'vuetify/src/locale'

Vue.use(Vuetify, {
    iconfont: '[mdi, fa]',
})

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#6857B2',
                secondary: '#ff6f3a',
                accent: '#24dca9',
                error: '#FF5252',
                info: '#347edb',
                success: '#34dbaa',
                warning: '#fddc61',
            },
            dark: {
                primary: '#6857B2',
                secondary: '#ff6f3a',
                accent: '#24dca9',
                error: '#FF5252',
                info: '#347edb',
                success: '#34dbaa',
                warning: '#fddc61',
            },
        },
    },
    lang: {
        locales: { es, en },
        current: 'es',
    },
    icons: {
        iconfont: 'mdi',
    },
})
