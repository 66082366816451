export default {
    actions: {
        assign: 'Asignar Este Libro',
        modify: 'Editar la asignación',
        activate: 'Activar asignación',
        cancel: 'Cancelar',
        saveChanges: 'Guardar cambios',
        goBackToLibrary: 'Volver a la biblioteca',
        seeAssignedBooks: 'Ver libros asignados',
        loadMoreAssignments: 'Cargar más asignaciones',
    },
    inputs: {
        dates: {
            startDate: 'Fecha de inicio',
            endDate: 'Fecha término',
        },
        course: {
            pick: 'Escoger un curso'
        },
        students: {
            assignTo: 'Asignar a',
            all: 'Todos los estudiantes',
        },
        assignation: {
            name: 'Nombre de la asignación (Opcional)',
        },
        assignationType: {
            actives: 'Activas',
            futureAssignations: 'Futuras asignaciones',
            inactives: 'Inactivas',
        }
    },
    placeholders: {
        dates: 'Selecciona una fecha',
        course: 'Escoge un curso',
        searchStudent: 'Busca un estudiante',
        students: 'Escoge a los estudiantes',
        assignation: 'Escribe un nombre',
        studentsSelection: 'Selección de estudiantes',
        totalQuestions: 'Total de preguntas',
        pagesAmount: 'Cantidad de páginas',
        progress: 'Progreso',
        readingComprenhension: 'Comprensión lectora',
    },
    titles: {
        assignTheBook: 'Asigna tu libro',
        modifyTheAssignation: 'Modifica la asignación',
        assignmentGeneralInformation: 'Información general de la asignación',
        generalPerformance: 'Desempeño general',
        assignmentTableTitles: {
            bookTitle: 'Título del libro',
            assignTo: 'Asignado a',
            startDate: 'Fecha de inicio',
            endDate: 'Fecha término',
            actions: 'Acciones',
            groupType: 'Tipo de grupo',
        }
    },
    texts: {
        focusSkill: 'El siguiente gráfico muestra como se distribuyen las tres macrohabilidades de comprensión lectora en este libro específico.'
    },
    utils: {
        diffDays: {
            createdAgo: 'Creado hace',
            createdInLessThan: 'Creado hace menos de',
            days: 'días',
            day: 'día',
            daysAgo: 'días',
            dayAgo: 'día',
            hours: 'horas',
            hour: 'hora',
            inLess: 'En menos de',
            lessThan: 'Hace menos de',
            startsIn: 'Empieza en',
            startsInLessThan: 'Empieza en menos de',
            remain: 'Quedan',
            remainLess: 'Quedan menos de',
            finished: 'Terminó hace',
            finishedInLess: 'Terminó hace menos de',
        },
        books: {
            noAuthor: 'No hay autor',
        },
        students: {
            single: "Estudiante",
            multiple: "Estudiantes",
        },
        tiers: {
            tier: 'Nivel',
            noTier: 'Sin Nivel',
            TIER_GROUP_1: 'Nivel 1',
            TIER_GROUP_2: 'Nivel 2',
            TIER_GROUP_3: 'Nivel 3',
        },
        groupType: {
            TIER: 'Nivel',
            CUSTOM: 'Personalizado',
            ALL_COURSE: 'Todo el curso',
            NO_TYPE: 'Sin tipo',
        }
    }
}