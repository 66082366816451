import ICommit from '@/store/contracts/commit'

const getDefaultState = () => {
    return {
        linguisticVariables: [],
    }
}

const CommonModule = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        linguisticVariables: (state: any, value: any) => {
            state.linguisticVariables = value
        },
    },
    actions: {
        setLinguisticVariables({ commit }: ICommit, value: any) {
            commit('linguisticVariables', value)
        },
    },
}

export default CommonModule
