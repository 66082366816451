import axios from 'axios'
import sendformData from '../utils/form-data'
import mapResponse from '../utils/map-response'

export default class Services {
    private readonly baseUrl = `${process.env.VUE_APP_BEEREADERS_API_URL}/`
    private readonly fluencyUrl = `${process.env.VUE_APP_BEEREADERS_FLUENCY_API_URL}/`
    private readonly cacheBaseUrl = `${process.env.VUE_APP_BEEREADERS_REDIS_URL}/`
    private readonly contentBaseUrl = `${process.env.VUE_APP_BEEREADERS_CONTENT_URL}`
    private module: string | undefined = ''
    private useFluency: boolean = false

    constructor(module?: string, baseUrlOptions?: { fluency: boolean }) {
        this.module = module

        if (baseUrlOptions) {
            baseUrlOptions.fluency && (this.useFluency = true)
        }
    }

    public get(
        url: string,
        params?: object,
        useCache = false,
        replaceCache = false,
        urlToReplaceCache = url
    ) {
        let requestedUrl = this.getUrl(urlToReplaceCache, false, useCache)
        if (replaceCache == true) {
            requestedUrl = requestedUrl.replace('redis', 'delete-redis')
        }
        const response = mapResponse(axios.get(requestedUrl, params))
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.get(this.getUrl(url, false, useCache), params))
                }
                if (err.code === 'redo-without-cache') {
                    return mapResponse(axios.get(this.getUrl(url, false, false), params))
                }
                return null
            })
        return response
    }

    public post(url: string, data?: object) {
        const response = mapResponse(axios.post(this.getUrl(url), data))
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.post(this.getUrl(url), data))
                }
                return null
            })
        return response
    }

    public postWithFile(url: string, data?: any) {
        const fd = new FormData()
        for (const key in data) {
            fd.append(key, data[key])
        }
        const response = mapResponse(
            axios.post(this.getUrl(url), fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.post(this.getUrl(url), data))
                }
                return null
            })
        return response
    }

    public put(url: string, data?: object) {
        const response = mapResponse(axios.put(this.getUrl(url), data))
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.put(this.getUrl(url), data))
                }
            })
        return response
    }

    public delete(url: string, data?: object) {
        const response = mapResponse(axios.delete(this.getUrl(url), data))
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.put(this.getUrl(url), data))
                }
            })
        return response
    }

    public form(url: string, data?: object, transf?: object) {
        const response = sendformData(this.getUrl(url), data, transf)
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return sendformData(this.getUrl(url), data, transf)
                }
            })
        return response
    }

    public bGet(url: string, params?: object) {
        const response = mapResponse(axios.get(this.getUrl(url, true), params))
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.get(this.getUrl(url, true), params))
                }
            })
        return response
    }

    public bPost(url: string, data?: object) {
        const response = mapResponse(axios.post(this.getUrl(url, true), data))
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.post(this.getUrl(url, true), data))
                }
            })
        return response
    }

    public bPut(url: string, data?: object) {
        const response = mapResponse(axios.put(this.getUrl(url, true), data))
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.put(this.getUrl(url, true), data))
                }
            })
        return response
    }

    public bForm(url: string, data?: object, transf?: object) {
        const response = sendformData(this.getUrl(url, true), data, transf)
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return sendformData(this.getUrl(url, true), data, transf)
                }
            })
        return response
    }

    private getUrl(url: string, onlyBase = false, cacheServer?: boolean) {
        if (cacheServer) {
            return `${!this.useFluency ? this.cacheBaseUrl : this.fluencyUrl}${
                onlyBase ? url : this.checkModule(url)
            }`
        }
        return `${!this.useFluency ? this.baseUrl : this.fluencyUrl}${
            onlyBase ? url : this.checkModule(url)
        }`
    }

    public contentPost(url: string, data?: object) {
        const localStore = JSON.parse(localStorage.getItem('teacher-beereaders-app') || '{}')

        const response = mapResponse(
            axios.post(`${this.contentBaseUrl}/analyticEvents/user_events`, data, {
                headers: {
                    authorization: `Bearer ${localStore.token}`,
                    'x-client-id': `${process.env.VUE_APP_CONTENT_MANAGER_CLIENT_ID}`,
                },
            })
        )
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.post(this.getUrl(url), data))
                }
                return null
            })
        return response
    }

    public cmsPost<T>(url: string, data?: object): Promise<T> {
        const localStore = JSON.parse(localStorage.getItem('teacher-beereaders-app') || '{}')

        const response = mapResponse(
            axios.post<T>(`${this.contentBaseUrl}${url}`, data, {
                headers: {
                    authorization: `Bearer ${localStore.token}`,
                    'x-client-id': `${process.env.VUE_APP_CONTENT_MANAGER_CLIENT_ID}`,
                },
            })
        )
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.post(this.getUrl(url), data))
                }
                return null
            })
        return response
    }
    public cmsGet(url: string, params?: object) {
        const localStore = JSON.parse(localStorage.getItem('teacher-beereaders-app') || '{}')

        const response = mapResponse(
            axios.get(`${this.contentBaseUrl}${url}`, {
                headers: {
                    authorization: `Bearer ${localStore.token}`,
                    'x-client-id': `${process.env.VUE_APP_CONTENT_MANAGER_CLIENT_ID}`,
                },
                params: params,
            })
        )
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.get(this.getUrl(url), params))
                }
                return null
            })
        return response
    }

    private checkModule(url: string) {
        return (this.module ? (url === '' ? `${this.module}` : `${this.module}/`) : '') + url
    }

    public rawGet(url: string, params?: object, useCache = false, replaceCache = false) {
        let requestedUrl = this.getUrl(url, false, useCache)
        if (replaceCache == true) {
            requestedUrl = requestedUrl.replace('redis', 'delete-redis')
        }

        const response = axios
            .get(requestedUrl, params)
            .then((res: any) => {
                return res
            })
            .catch((err: any) => {
                if (err.code === 'auth/updated-info') {
                    return mapResponse(axios.get(this.getUrl(url, false, useCache), params))
                }
                if (err.code === 'redo-without-cache') {
                    return mapResponse(axios.get(this.getUrl(url, false, false), params))
                }
                return null
            })
        return response
    }
}
