export default {
    INF: 'Infantil',
    AVEN: 'Aventura',
    DRAM: 'Drama',
    OCI: 'Ocio',
    DEP: 'Deportes',
    M_S: 'Misterio y Suspenso',
    ROM: 'Romance',
    C_F: 'Ciencia Ficción',
    FANT: 'Fantástico',
    TER: 'Terror',
    EDU: 'Educación',
    ART: 'Arte',
    ESPT: 'Espiritualidad',
    C_T: 'Ciencia y Tecnología',
    V_S: 'Vida y Salud',
    H_F: 'Historia y Filosofía',
    GAST: 'Gastronomía',
    AUTAY: 'Autoayuda',
    M_L: 'Mitos y Leyendas',
    CULT: 'Cultura',
    C_P: 'Crimen y Policial',
    MAR: 'Maravilloso',
    POES: 'Poesía',
    TEA: 'Teatro',
    C_N_G: 'Cómic y Novela Gráfica',
    F_L: 'Fomento Lector',
    MEAM: 'Medioambiente',
    MUSIC: 'Música',
    AMIS: 'Amistad',
    ANIM: 'Animales',
    FILS: 'Filosofía',
    PasatiempoDeportes: 'Pasatiempo y deportes',
    MisterioTerror: 'Misterio y terror',
    AventurasViajes: 'Aventuras y viajes',
    FantasiaMundos: 'Fantasía y mundos',
    ArteCultura: 'Arte y cultura',
    CienciaTecnologia: 'Ciencia y tecnología',
    CienciasUniverso: 'Ciencias y universo',
    TecnologiaInventos: 'Tecnología e inventos',
    PensamientosEmociones: 'Pensamientos y emociones',
    HistoriaVidaReal: 'Historia y vida real',
    HumorCuriosidades: 'Humor y curiosidades',
    CocinaVidaCotidiana: 'Cocina y vida cotidiana',
    NaturalezaAnimales: 'Naturaleza y animales',
}
