import {
    DIAGNOSIS_EVALUATION_TYPE,
    DIAGNOSIS_STATE,
    EvaluationFullData,
    EvaluationSimpleData,
    EvaluationFilters, 
    StudentWithDiagnosticAndSkills
} from "@/store/modules/Diagnoses/types";
import Services from "../base-services";
import mapParameters from "@/utils/map-parameters";
import { TableSkillData } from "@/data/reports/tables";

const service = new Services('diagnosticAssignment')
const evaluationService = new Services('evaluacionDiagnostico')


export const diagnosticAssignmentService = {
    async getDiagnosesByTeacher({ teacherId = null, currentCourseID = null, currentEvaluationType = null, evaluationState = true }: EvaluationFilters) {
        const params = mapParameters({
            teacherId,
            courseId: currentCourseID,
            type: currentEvaluationType,
            active: evaluationState,
        })
        const data = await service.get('findAllByCourseId', params)
        return data.content as EvaluationSimpleData[]
    },
    async getDiagnosesEvaluationFullData(diagnosisID: string) {
        const params = mapParameters({ assigmentId: diagnosisID })
        const data = await evaluationService.get(`getEvaluationDataByParams`, params)
        if(data.status.code !== 1) {
            return Promise.reject(data.content)
        }
        const result: Partial<EvaluationFullData> = {};
        const assignedStudents = data.content.readers.map((reader: any) => {
            const evolution = reader.evolution[0];
            if(evolution) {
                const locate: Partial<TableSkillData> = {
                    average: evolution.porcentajeCorrectasLocalizar,
                    corrects: evolution.totalCorrectasLocalizar,
                    incorrects: evolution.totalIncorrectasLocalizar,
                    total: evolution.totalPreguntasLocalizar,
                }
                const integrate: Partial<TableSkillData> = {
                    average: evolution.porcentajeCorrectasIntegrar,
                    corrects: evolution.totalCorrectasIntegrar,
                    incorrects: evolution.totalIncorrectasIntegrar,
                    total: evolution.totalPreguntasIntegrar,
                }
                const reflect: Partial<TableSkillData> = {
                    average: evolution.porcentajeCorrectasReflexionar,
                    corrects: evolution.totalCorrectasReflexionar,
                    incorrects: evolution.totalIncorrectasReflexionar,
                    total: evolution.totalPreguntasReflexionar,
                }
                const readingComprehension = evolution.porcentajeCorrectas;
                const student = reader.reader;
                const curr: Partial<StudentWithDiagnosticAndSkills> = {
                    id: student.id,
                    firstname: student.firstname,
                    lastname: student.lastname,
                    dni: student.dni,
                    beereadersStage: reader.beereadersStage,
                    studentTier: reader.studentTier,
                    course: student.course || {},
                    readerType: student.userType || 'READER',
                    diagnosticState: reader.status,
                    skills: {
                        locate,
                        integrate,
                        reflect,
                        readingComprehension,
                    }
                }

                return curr;
            }

            return null
        }).filter((reader: any) => reader !== null);

        const report = data.content.report[0];
        const classSkillsPercentages = {
            locate: report.porcentajeCorrectasLocalizar,
            integrate: report.porcentajeCorrectasIntegrar,
            reflect: report.porcentajeCorrectasReflexionar,
            readingComprehension: report.porcentajeCorrectas,
        };

        result.assignedStudents = assignedStudents;
        result.classSkillsPercentages = classSkillsPercentages;
        return result;
    },
    async updateOrCreateAssignment({ id, dateStart, course, level, createdBy, evaluations }: EvaluationSimpleData) {
        const data = await service.post(`save`, {
            id,
            dateStart,
            course,
            level,
            createdBy,
            evaluations,
        })
        if (data?.status?.code !== 1) {
            return Promise.reject(data?.content)
        }
        return data?.content as EvaluationSimpleData
    },
    async deleteDiagnosis(diagnosisID: string) {
        const params = mapParameters({
            diagnosticAssignmentId: diagnosisID,
        })
        const data = await service.delete(`delete`, params)
        return data.content as boolean
    },
    async getDiagnoses() {
        const params = mapParameters({
            gradeFrom: 1,
            gradeTo: 12,
        })
        const data = await service.get('getDiagnostics', params)
        return data.content as EvaluationSimpleData[]
    }
}