import home from './home'
import bookReading from './bookReading'
import library from './library'
import stats from './stats'
import reporting from './reporting'
import grades from './grades'
import leveling from '@/i18n/en/leveling'
import resources from '@/i18n/en/resources'
import sessions from './sessions'
import subSkill from '@/i18n/en/subSkill'
import categories from '@/i18n/en/categories'
import textType from '@/i18n/en/textType'
import reports from '@/i18n/en/reports'
import progressSystemLevel from '@/i18n/en/progressSystemLevel'
import assignments from '@/i18n/en/assignments'
import diagnoses from '@/i18n/en/diagnoses'
import evaluate from '@/i18n/en/evaluate'

export default {
    ad_text: ['You are looking at the new version.', 'Back to the previous version.'],
    title_home: 'Home | Beereaders',
    title_library: 'Library | Beereaders',
    title_quick_stats: 'Quick Stats | Beereaders',
    title_resource_room: 'Resource Room | Beereaders',
    title_session_data: 'Session Data | Beereaders',
    title_skill_reading_level: 'Skill & Reading Level | Beereaders',
    home_link: 'Home',
    library_link: 'Library',
    quick_stats_link: 'Quick Stats',
    resource_room_link: 'Resource Room',
    session_data_link: 'Session Data',
    skill_reading_level_link: 'Skill & Reading Level',
    logout: 'Sign out',
    grade: 'Grade',
    choose_course: 'Select class',
    see_full_list: 'See full list',
    TEACHER: 'Teacher',
    courses: 'classes',
    course: 'class',
    students: 'students',
    tier: 'tier',
    msg_error: 'Error:',
    msg_warning: 'Warning!',
    msg_info: 'Information!',
    msg_success: 'Oh Yes!',
    msg_load_error: 'Error loading content',
    txt_load_error: 'Click the accept button to reload the page and try again',
    general_error_message: 'An error has ocurred, try again later',
    anonymous: 'Anonymous',
    button_yes: 'Yes',
    button_no: 'No',
    button_close: 'Close',
    button_accept: 'Accept',
    go_back: 'Go back',
    to_confirm: 'Confirm',
    confirm_logout_msg: 'Are you sure you want to log out?',
    not_data: 'No data to display',
    end_date: 'End date:',
    init_date: 'Start date:',
    assigned_course: 'Class Assigned:',
    edit_period: 'Edit period',
    check_stats: 'Check stats',
    days_names: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    month_names: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    preview: 'Preview',
    start: 'Start',
    end: 'End',
    save: 'Save',
    continue: 'continue',
    progress: 'Progress',
    correct: 'Correct answers',
    incorrect: 'Incorrect',
    percentageCorrectAnswers: '% Correct Answers',
    percentageWrongAnswers: '% Incorrect Answers',
    total: 'total',
    accuracy: 'Accuracy',
    global: 'Overall',
    choose_tier: 'Choose tier',
    level: 'level',
    date_time: 'Date & Time',
    subject: 'Subject:',
    from: 'From: ',
    message: 'Message:',
    starting_date: 'Starting date: ',
    ending_date: 'Ending date: ',
    date_range: 'Date range:',
    execute: 'Execute',
    cancel: 'cancel',
    select: 'select',
    export_csv: 'Export to .CSV',
    print: 'Print',
    delete: 'Delete',
    exit: 'Exit',
    close: 'Close',
    not_message: 'No messages to display',
    select_course: 'Select class',
    download: 'Download',
    dynamic_language_banner_end: '_en.png',
    title_bookReading: 'Reading Experience | Beereaders',
    about: 'About',
    comments: 'Comments',
    no_comment: 'There are not Comments',
    more_nine_comments: '+9 Comments',
    next: 'Next',
    of: 'of',
    true: 'True',
    false: 'False',
    details: 'Details',
    pages: 'Pages',
    general: {
        error_msg: "Oops! Something went wrong!. Please contact support team",
        loading: 'Loading...'
    },
    actions: {
        export: 'Export'
    },
    home,
    bookReading,
    library,
    stats,
    reporting,
    grades,
    leveling,
    resources,
    sessions,
    subSkill,
    categories,
    textType,
    reports,
    progressSystemLevel,
    assignments,
    diagnoses,
    evaluate
}
