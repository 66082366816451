import { ActionContext } from "vuex"

import generalInformation from '../../../services/reports/generalInformation'

import { Course } from "@/data/dto/course"
import { Student } from "@/data/dto/student"
import { Tier } from "@/data/dto/tier"
import { DateOptionsEnum } from "@/data/reports/constants"

import { ReportsGlobalState, ReportViews } from './types/index'
import { getDateFromDaysAgo } from "./utils"
import { mutations } from './mutations'

export default  {
  setSelectedStudent({ commit }: ActionContext<ReportsGlobalState, any>, studentID: number) {
    commit(mutations.pickStudentByID, { studentID })
  },
  setCourses({ commit }: ActionContext<ReportsGlobalState, any>, courses: Course[]) {
    commit(mutations.setTeacherCourses, { courses })
  },
  setCourseSelected({ commit }: ActionContext<ReportsGlobalState, any>, course: Course) {
    commit(mutations.setCourseSelected, { course })
  },
  selectCourse({ commit }: ActionContext<ReportsGlobalState, any>, courseID: number) {
    commit(mutations.pickCourse, { courseID })
  },
  setDefaultCourse({ commit }: ActionContext<ReportsGlobalState, any>) {
    commit(mutations.setDefaultTeacherCourse)
  },
  setStudentsPerCourse({ commit }: ActionContext<ReportsGlobalState, any>) {
    commit(mutations.setStudentsPerCourse)
  },
  setTiers({ commit }: ActionContext<ReportsGlobalState, any>, tiers: Tier[]) {
    commit(mutations.setTiers, { tiers })
  },
  setTierSelected({ commit }: ActionContext<ReportsGlobalState, any>, tier: Tier) {
    commit(mutations.selectTier, { tier })
  },
  setNewCustomDateRange(
    { commit }: ActionContext<ReportsGlobalState, any>,
    payload: { initialDate: string; endDate: String }
  ) {
    commit(mutations.setInitialDate, { date: payload.initialDate })
    commit(mutations.setEndDate, { date: payload.endDate })
  },
  setNewDateRange(
      { commit, state }: ActionContext<ReportsGlobalState, any>,
      rangeOption: DateOptionsEnum
  ) {
    commit(mutations.setDateOption, { dateOption: rangeOption })

    if (!state.dates.endDate && rangeOption != DateOptionsEnum.CUSTOM_DATE) {
      commit(mutations.setTodayAsEndDate)
    }

    switch (rangeOption) {
      case DateOptionsEnum.LAST30DAYS:
        commit(mutations.resetCustomDaysData)
        commit(mutations.setInitialDate, { date: getDateFromDaysAgo(30) })
        break
      case DateOptionsEnum.LAST7DAYS:
        commit(mutations.resetCustomDaysData)
        commit(mutations.setInitialDate, { date: getDateFromDaysAgo(7) })
        break
      case DateOptionsEnum.LAST90DAYS:
        commit(mutations.resetCustomDaysData)
        commit(mutations.setInitialDate, { date: getDateFromDaysAgo(90) })
        break
    }
  },
  resetDates({ commit }: ActionContext<ReportsGlobalState, any>) {
    commit(mutations.resetCustomDaysData)
  },
  setStudents({ commit }: ActionContext<ReportsGlobalState, any>, students: Student[]) {
    commit(mutations.setCurrentStudents, { students })
  },
  setCurrentFilteredStudents(
    { commit }: ActionContext<ReportsGlobalState, any>,
    students: Student[]
  ) {
    commit(mutations.setFilteredStudents, { students })
  },
  async getGeneralInformationFromCourse(
    { commit, state }: ActionContext<ReportsGlobalState, any>,
    payload: {
      courseID: number
      dateRangeOption: DateOptionsEnum
    }
  ) {
    if (!state.dates?.initialDate || !state.dates?.endDate) return
    let generalInformationResp

    switch (payload.dateRangeOption) {
      case DateOptionsEnum.LAST7DAYS:
        if (state.generalInformation[payload.courseID].last7Days)
          return state.generalInformation[payload.courseID].last7Days
        state.loading.generalDataLoading = true
        generalInformationResp = await generalInformation.getPerformanceByCourse(
          payload.courseID,
          state.dates.initialDate,
          state.dates.endDate
        )
        commit(mutations.getLast7DaysGeneralInformationFromCourse, {
          courseID: payload.courseID,
          generalInformation: generalInformationResp,
        })
        state.loading.generalDataLoading = false
        return generalInformationResp.studentsList

      case DateOptionsEnum.LAST30DAYS:
        if (state.generalInformation[payload.courseID].last30Days)
            return state.generalInformation[payload.courseID].last30Days
        state.loading.generalDataLoading = true
        generalInformationResp = await generalInformation.getPerformanceByCourse(
          payload.courseID,
          state.dates.initialDate,
          state.dates.endDate
        )
        commit(mutations.getLast30DaysGeneralInformationFromCourse, {
          courseID: payload.courseID,
          generalInformation: generalInformationResp,
        })
        state.loading.generalDataLoading = false
        return generalInformationResp.studentsList

      case DateOptionsEnum.LAST90DAYS:
        if (state.generalInformation[payload.courseID].last90Days)
          return state.generalInformation[payload.courseID].last90Days
        state.loading.generalDataLoading = true
        generalInformationResp = await generalInformation.getPerformanceByCourse(
          payload.courseID,
          state.dates.initialDate,
          state.dates.endDate
        )
        commit(mutations.getLast90DaysGeneralInformationFromCourse, {
          courseID: payload.courseID,
          generalInformation: generalInformationResp,
        })
        state.loading.generalDataLoading = false
        return generalInformationResp.studentsList

      case DateOptionsEnum.CUSTOM_DATE:
        state.loading.generalDataLoading = true
        generalInformationResp = await generalInformation.getPerformanceByCourse(
          payload.courseID,
          state.dates.initialDate,
          state.dates.endDate
        )
        commit(mutations.getLastCustomDaysGeneralInformationFromCourse, {
          courseID: payload.courseID,
          generalInformation: generalInformationResp,
        })
        state.loading.generalDataLoading = false
        return generalInformationResp.studentsList
      }
  },
  async getBooksInformationFromCourse(
    { commit, state }: ActionContext<ReportsGlobalState, any>,
    payload: {
      courseID: number
      dateRangeOption: DateOptionsEnum
    }
  ) {
    if (!state.dates?.initialDate || !state.dates?.endDate) return
    let bookInformationResp

    switch (payload.dateRangeOption) {
      case DateOptionsEnum.LAST7DAYS:
        if (state.bookInformation[payload.courseID].last7Days)
          return state.bookInformation[payload.courseID].last7Days
        state.loading.booksDataLoading = true
        bookInformationResp = await generalInformation.getBookAssignedMetricsByCourse(
          payload.courseID,
          state.dates.initialDate,
          state.dates.endDate
        )
        commit(mutations.getLast7DaysBooksInformationFromCourse, {
          courseID: payload.courseID,
          bookInformation: bookInformationResp,
        })
        state.loading.booksDataLoading = false
        return bookInformationResp

      case DateOptionsEnum.LAST30DAYS:
        if (state.bookInformation[payload.courseID].last30Days)
          return state.bookInformation[payload.courseID].last30Days
        state.loading.booksDataLoading = true
        bookInformationResp = await generalInformation.getBookAssignedMetricsByCourse(
          payload.courseID,
          state.dates.initialDate,
          state.dates.endDate
        )
        commit(mutations.getLast30DaysBooksInformationFromCourse, {
          courseID: payload.courseID,
          bookInformation: bookInformationResp,
        })
        state.loading.booksDataLoading = false
        return bookInformationResp

      case DateOptionsEnum.LAST90DAYS:
        if (state.bookInformation[payload.courseID].last90Days)
          return state.bookInformation[payload.courseID].last90Days
        state.loading.booksDataLoading = true
        bookInformationResp = await generalInformation.getBookAssignedMetricsByCourse(
          payload.courseID,
          state.dates.initialDate,
          state.dates.endDate
        )
        commit(mutations.getLast90DaysBooksInformationFromCourse, {
          courseID: payload.courseID,
          bookInformation: bookInformationResp,
        })
        state.loading.booksDataLoading = false
        return bookInformationResp

      case DateOptionsEnum.CUSTOM_DATE:
        state.loading.booksDataLoading = true
        bookInformationResp = await generalInformation.getBookAssignedMetricsByCourse(
          payload.courseID,
          state.dates.initialDate,
          state.dates.endDate
        )
        commit(mutations.getLastCustomDaysBooksInformationFromCourse, {
          courseID: payload.courseID,
          bookInformation: bookInformationResp,
        })
        state.loading.booksDataLoading = false
        return bookInformationResp
      }
  },
  async getChallengesInformationFromCourse(
    { commit, state }: ActionContext<ReportsGlobalState, any>,
    payload: {
      courseID: number
      dateRangeOption: DateOptionsEnum
    }
  ) {
    if (!state.dates?.initialDate || !state.dates?.endDate) return
    let challengesInformation

    switch (payload.dateRangeOption) {
      case DateOptionsEnum.LAST7DAYS:
        if (state.challengesInformation[payload.courseID].last7Days)
            return state.challengesInformation[payload.courseID].last7Days
        state.loading.challengesDataLoading = true
        challengesInformation = await generalInformation.getChallengesMetricsByCourse(
            payload.courseID,
            state.dates.initialDate,
            state.dates.endDate
        )
        commit(mutations.getLast7DaysChallengesInformationFromCourse, {
            courseID: payload.courseID,
            challengesInformation,
        })
        state.loading.challengesDataLoading = false
        return challengesInformation.studentsList

      case DateOptionsEnum.LAST30DAYS:
        if (state.challengesInformation[payload.courseID].last30Days)
            return state.generalInformation[payload.courseID].last30Days
        state.loading.challengesDataLoading = true
        challengesInformation = await generalInformation.getChallengesMetricsByCourse(
            payload.courseID,
            state.dates.initialDate,
            state.dates.endDate
        )
        commit(mutations.getLast30DaysChallengesInformationFromCourse, {
            courseID: payload.courseID,
            challengesInformation,
        })
        state.loading.challengesDataLoading = false
        return challengesInformation.studentsList

      case DateOptionsEnum.LAST90DAYS:
        if (state.challengesInformation[payload.courseID].last90Days)
            return state.generalInformation[payload.courseID].last90Days
        state.loading.challengesDataLoading = true
        challengesInformation = await generalInformation.getChallengesMetricsByCourse(
            payload.courseID,
            state.dates.initialDate,
            state.dates.endDate
        )
        commit(mutations.getLast90DaysChallengesInformationFromCourse, {
            courseID: payload.courseID,
            challengesInformation,
        })
        state.loading.challengesDataLoading = false
        return challengesInformation.studentsList

      case DateOptionsEnum.CUSTOM_DATE:
        state.loading.challengesDataLoading = true
        challengesInformation = await generalInformation.getChallengesMetricsByCourse(
            payload.courseID,
            state.dates.initialDate,
            state.dates.endDate
        )
        commit(mutations.getLastCustomDaysChallengesInformationFromCourse, {
            courseID: payload.courseID,
            challengesInformation,
        })
        state.loading.challengesDataLoading = false
        return challengesInformation.studentsList
    }
  },
  async getCurrentStudentSelfLecturesInformation({
      commit,
      state,
  }: ActionContext<ReportsGlobalState, any>) {
    if (!state.studentSelected || state.studentSelected.id === 0) return
    if (!state.dates?.initialDate || !state.dates?.endDate) return
    if (!state.courseSelected) return

    const studentID = state.studentSelected.id

    state.loading.booksDataLoading = true
    const selfLecturesInformation = await generalInformation.getStudentsSelfLectures(
      studentID,
      state.courseSelected.id,
      state.dates.initialDate,
      state.dates.endDate
    )

    state.loading.booksDataLoading = false
    commit(mutations.setCurrentStudentSelectedSelfLectures, { selfLecturesInformation })
    return selfLecturesInformation
  },
  setError(
    { commit }: ActionContext<ReportsGlobalState, any>,
    payload: { error: string; type: 'general' | 'filter' }
  ) {
    if (payload.type === 'general') {
      commit(mutations.setGeneralError, { error: payload.error })
    } else {
      commit(mutations.setFilterError, { error: payload.error })
    }
  },
  clearErrors({ commit }: ActionContext<ReportsGlobalState, any>) {
      commit(mutations.resetErrors)
  },
  setReportPageTab(
      { commit }: ActionContext<ReportsGlobalState, any>,
      payload: { tab: ReportViews }
  ) {
    commit(mutations.setCurrentTab, { tab: payload.tab })
  },
  async getExcelFile({ commit, state }: ActionContext<ReportsGlobalState, any>) {
    if (!state.dates.initialDate || !state.dates.endDate || !state.courseSelected) return
    let currentTab: 'Challenges' | 'GeneralInfo' | 'Books' = 'GeneralInfo'
    switch (state.currentTab) {
      case 0:
        currentTab = 'GeneralInfo'
        break
      case 1:
        currentTab = 'Books'
        break
      case 2:
        currentTab = 'Challenges'
        break
    }

    const data = await generalInformation.getExcelFileFromTab({
      startDate: state.dates.initialDate,
      endDate: state.dates.endDate,
      courseID: state.courseSelected?.id,
      selectedTab: currentTab,
      studentID: state.studentSelected?.id,
    })
    return data
  },
  async getPerformanceByAssigmentExcelFile({ state }: ActionContext<ReportsGlobalState, any>, payload: { courseId: number, startingDate: string, evaluationDate: string, assigmentId: number }) {
    if (!payload.courseId || !payload.startingDate || !payload.evaluationDate || !payload.assigmentId) return

    const data = await generalInformation.getPerformanceExcelFile({
      startDate: payload.startingDate,
      endDate: payload.evaluationDate,
      courseId: payload.courseId,
      assigmentId: payload.assigmentId,
    })

    return data
  },
}
