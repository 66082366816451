export default {
    quiz_score: 'Nivelación por puntaje',
    placement_test: 'Prueba de Nivel Inicial y Reciente',
    compreshion_skills: 'Habilidades de comprensión',
    comprehesion_skills: [
        'Localizar características explícitas de personajes',
        'Localizar características explícitas de lugares',
        'Identificar sentimientos y / o emociones',
    ],
    simple_comprehesion_skills: [
        'Localizar u obtener información',
        'Integrar e interpretar',
        'Reflexionar y evaluar',
    ],
    short_comprehesion_skills: ['LOC', 'IN', 'RV'],
    quiz_card_title: 'Mi clase por nivel (puntuación)',
    initial_test_card: 'Prueba de diagnóstico inicial',
    lastest_test_card: 'Última prueba de diagnóstico',
    highest_comprehesion_card: 'Habilidades de comprensión más altas',
    lowest_comprehesion_card: 'Habilidades de comprensión más bajas',
}
