import { Course } from "@/data/dto/course"
import { Student } from "@/data/dto/student"
import { Tier } from "@/data/dto/tier"

import { ChallengeInformationDTO, GeneralInformationDTO } from "@/data/reports/responses"

import { DateOptionsEnum } from "@/data/reports/constants"

import { BookInformationDTO, ReportsGlobalState, ReportViews, SelfLecturesInformationDTO } from './types/index'

export const mutations = {
  pickStudentByID: 'pickStudentByID',
  setCurrentStudents: 'setCurrentStudents',
  getStudentPerformanceData: 'getStudentPerformanceData',
  setFilteredStudents: 'setFilteredStudents',
  setInitialDate: 'setInitialDate',
  setEndDate: 'setEndDate',
  setTodayAsEndDate: 'setTodayAsEndDate',
  setDateOption: 'setDateOption',
  getTeacherCourses: 'getTeacherCourses',
  setTeacherCourses: 'setTeacherCourses',
  setStudentsPerCourse: 'setStudentsPerCourse',
  setCourseSelected: 'setCourseSelected',
  pickCourse: 'pickCourse',
  setDefaultTeacherCourse: 'setDefaultTeacherCourse',
  getLast7DaysGeneralInformationFromCourse: 'getLast7DaysGeneralInformationFromCourse',
  getLast30DaysGeneralInformationFromCourse: 'getLast30DaysGeneralInformationFromCourse',
  getLast90DaysGeneralInformationFromCourse: 'getLast90DaysGeneralInformationFromCourse',
  getLastCustomDaysGeneralInformationFromCourse: 'getLastCustomDaysGeneralInformationFromCourse',
  getLast7DaysBooksInformationFromCourse: 'getLast7DaysBooksInformationFromCourse',
  getLast30DaysBooksInformationFromCourse: 'getLast30DaysBooksInformationFromCourse',
  getLast90DaysBooksInformationFromCourse: 'getLast90DaysBooksInformationFromCourse',
  getLastCustomDaysBooksInformationFromCourse: 'getLastCustomDaysBooksInformationFromCourse',
  setCurrentStudentSelectedSelfLectures: 'setCurrentStudentSelectedSelfLectures',
  getLast7DaysChallengesInformationFromCourse: 'getLast7DaysChallengesInformationFromCourse',
  getLast30DaysChallengesInformationFromCourse: 'getLast30DaysChallengesInformationFromCourse',
  getLast90DaysChallengesInformationFromCourse: 'getLast90DaysChallengesInformationFromCourse',
  getLastCustomDaysChallengesInformationFromCourse: 'getLastCustomDaysChallengesInformationFromCourse',
  resetCustomDaysData: 'resetCustomDaysData',
  setTiers: 'setTiers',
  selectTier: 'selectTier',
  setGeneralError: 'setGeneralError',
  setFilterError: 'setFilterError',
  resetErrors: 'resetErrors',
  setCurrentTab: 'setCurrentTab',
}

export default {
  pickStudentByID(state: ReportsGlobalState, payload: { studentID: number }) {
    state.studentSelected =
      state.students.find((student) => student.id === payload.studentID) || null
  },
  setCurrentStudents(state: ReportsGlobalState, payload: { students: Student[] }) {
    state.students = payload.students
    state.courses.forEach((course) => {
        state.studentsPerCourse[course.id] =
            payload.students.filter((student) => student.course?.id === course.id) || []
    })
  },
  getStudentPerformanceData(state: ReportsGlobalState, payload: { studentID: number }) {},
  setFilteredStudents(state: ReportsGlobalState, payload: { students: Student[] }) {
    state.filteredStudents = payload.students
  },
  setInitialDate(state: ReportsGlobalState, payload: { date: string }) {
    state.dates.initialDate = payload.date
  },
  setEndDate(state: ReportsGlobalState, payload: { date: string }) {
    state.dates.endDate = payload.date
  },
  setTodayAsEndDate(state: ReportsGlobalState) {
    state.dates.endDate = new Date().toISOString()
  },
  setDateOption(state: ReportsGlobalState, payload: { dateOption: DateOptionsEnum }) {
    state.dateOptionSelected = payload.dateOption
  },
  resetCustomDaysData(state: ReportsGlobalState) {
    if (!state.courseSelected?.id) return
    state.generalInformation[state.courseSelected?.id].custom = undefined
    state.bookInformation[state.courseSelected?.id].custom = undefined
  },
  async getTeacherCourses(state: ReportsGlobalState, payload: { teacherID: number }) {
    // const courses = await CourseService.getAllByTeacher(payload.teacherID)
  },
  async setTeacherCourses(state: ReportsGlobalState, payload: { courses: Course[] }) {
    state.courses = payload.courses
    payload.courses.forEach((course) => {
      state.generalInformation[course.id] = {}
      state.bookInformation[course.id] = {}
      state.challengesInformation[course.id] = {}
    })
  },
  setStudentsPerCourse(state: ReportsGlobalState) {
    state.courses.forEach((course) => {
      state.studentsPerCourse[course.id] = state.students.filter(
        (student) => student.course?.id === course.id
      )
    })
  },
  setCourseSelected(state: ReportsGlobalState, payload: { course: Course }) {
    state.courseSelected = payload.course
  },
  async pickCourse(state: ReportsGlobalState, payload: { courseID: number }) {
    state.courseSelected =
        state.courses.find((course) => course.id === payload.courseID) || null
  },
  setDefaultTeacherCourse(state: ReportsGlobalState) {
    if (!state.courses.length) return
    state.courseSelected = state.courses[0]
  },
  async getLast7DaysGeneralInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; generalInformation: GeneralInformationDTO }
  ) {
    if (state.generalInformation[payload.courseID].last7Days) return
    state.generalInformation[payload.courseID].last7Days =
        payload.generalInformation.studentsList
  },
  async getLast30DaysGeneralInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; generalInformation: GeneralInformationDTO }
  ) {
      if (state.generalInformation[payload.courseID].last30Days) return
      state.generalInformation[payload.courseID].last30Days =
          payload.generalInformation.studentsList
  },
  async getLast90DaysGeneralInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; generalInformation: GeneralInformationDTO }
  ) {
      if (state.generalInformation[payload.courseID].last90Days) return
      state.generalInformation[payload.courseID].last90Days =
          payload.generalInformation.studentsList
  },
  async getLastCustomDaysGeneralInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; generalInformation: GeneralInformationDTO }
  ) {
      state.generalInformation[payload.courseID].custom =
          payload.generalInformation.studentsList
  },
  async getLast7DaysBooksInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; bookInformation: BookInformationDTO }
  ) {
      if (state.bookInformation[payload.courseID].last7Days) return
      state.bookInformation[payload.courseID].last7Days = payload.bookInformation
  },
  async getLast30DaysBooksInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; bookInformation: BookInformationDTO }
  ) {
      if (state.bookInformation[payload.courseID].last30Days) return
      state.bookInformation[payload.courseID].last30Days = payload.bookInformation
  },
  async getLast90DaysBooksInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; bookInformation: BookInformationDTO }
  ) {
      if (state.bookInformation[payload.courseID].last90Days) return
      state.bookInformation[payload.courseID].last90Days = payload.bookInformation
  },
  async getLastCustomDaysBooksInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; bookInformation: BookInformationDTO }
  ) {
      state.bookInformation[payload.courseID].custom = payload.bookInformation
  },
  setCurrentStudentSelectedSelfLectures(
      state: ReportsGlobalState,
      paylaod: { selfLecturesInformation: SelfLecturesInformationDTO }
  ) {
      state.bookInformation.currentStudentSelfLectures = paylaod.selfLecturesInformation
  },
  async getLast7DaysChallengesInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; challengesInformation: ChallengeInformationDTO }
  ) {
      if (state.challengesInformation[payload.courseID].last7Days) return
      state.challengesInformation[payload.courseID].last7Days =
          payload.challengesInformation.studentsList
  },
  async getLast30DaysChallengesInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; challengesInformation: ChallengeInformationDTO }
  ) {
      if (state.challengesInformation[payload.courseID].last30Days) return
      state.challengesInformation[payload.courseID].last30Days =
          payload.challengesInformation.studentsList
  },
  async getLast90DaysChallengesInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; challengesInformation: ChallengeInformationDTO }
  ) {
      if (state.challengesInformation[payload.courseID].last90Days) return
      state.challengesInformation[payload.courseID].last90Days =
          payload.challengesInformation.studentsList
  },
  async getLastCustomDaysChallengesInformationFromCourse(
      state: ReportsGlobalState,
      payload: { courseID: number; challengesInformation: ChallengeInformationDTO }
  ) {
      state.challengesInformation[payload.courseID].custom =
          payload.challengesInformation.studentsList
  },
  setTiers(state: ReportsGlobalState, payload: { tiers: Tier[] }) {
      state.tiers = payload.tiers
  },
  selectTier(state: ReportsGlobalState, payload: { tier: Tier }) {
      state.tierSelected = payload.tier
  },
  setGeneralError(state: ReportsGlobalState, payload: { error: string }) {
      state.errors.generalError = payload.error
  },
  setFilterError(state: ReportsGlobalState, payload: { error: string }) {
      state.errors.filterError = payload.error
  },
  resetErrors(state: ReportsGlobalState) {
      state.errors.filterError = null
      state.errors.generalError = null
  },
  setCurrentTab(state: ReportsGlobalState, payload: { tab: ReportViews }) {
      state.currentTab = payload.tab
  },
}
