<template>
    <v-container class="mx-2">
        <div
            :class="classBar"
        >
            <div
                :class="`border-radius-5 d-flex justify-center align-center transition-all ${
                    progress ? 'px-2' : 'px-1'
                }`"
                :style="{
                    backgroundColor: color,
                    width: `${progress}%`,
                    height: '100%',
                    maxWidth: '100%',
                }"
            >
                <span
                    :class="classValueMinTwenty"
                    :style="{ maxWidth: '100px;' }"
                    v-if="progress >= 20"
                >
                    <slot />
                </span>
            </div>
            <span
                :class="classValueMaxTwenty"
                v-if="progress < 20"
            >
                {{ `${progress}${symbol}` }}
            </span>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'BeeProgressBar',
    props: {
        value: { type: [String, Number], default: '' },
        color: { type: String, default: '#514773' },
        progress: { type: [String, Number], default: 0 },
        symbol: { type: String, default: '%' },
        backgroundless: { type: Boolean, default: false },
        valueColorBlack: { type: Boolean, default: false },
    },
    computed: {
        classBar() {
            if (this.backgroundless) {
                return 'w-100 border-radius-5 white--text d-inline-block position-relative bee-progress-barr-no-background'
            }
            return 'w-100 border-radius-5 white--text d-inline-block position-relative bee-progress-barr'
        },
        classValueMaxTwenty() {
            if (this.valueColorBlack) {
                return 'text-truncate text-center position-absolute font-size-12 text-value color-value-black'
            }
            return 'text-truncate text-center position-absolute font-size-12 text-value color-value-gray'
        },
        classValueMinTwenty() {
            if (this.valueColorBlack) {
                return 'text-truncate text-center d-flex align-center justify-center color-value-black'
            }
            return 'text-truncate text-center d-flex align-center justify-center'
        },
    },
}
</script>

<style scoped>
.bee-progress-barr {
    background-image: linear-gradient(#f1f1f1, #f1f1f1, #f1f1f1);
    height: 3vh;
}
.bee-progress-barr-no-background {
    height: 3vh;
}
.color-value-black {
    color: black;
}
.text-value {
    max-width: 100px;
    top: .4vh;
}
.color-value-gray {
    color: #64606f;
}
</style>
