<template>
    <v-row class="align-center py-0">
        <v-col cols="12" class="py-1">
            <span class="py-0 font-size-14" :style="{ color: titleColor }">{{ title }}</span></v-col
        >
        <v-col cols="12" class="pa-0 d-flex justify-start align-center">
            <BeeProgressBar
                :color="color"
                symbol="%"
                class="pa-0 text-center font-size-12"
                :progress="value"
            >
                {{ `${value}${symbol}` }}
            </BeeProgressBar>
        </v-col>
    </v-row>
</template>

<script>
import BeeProgressBar from './BeeProgressBar'
export default {
    name: 'SkillBar',
    components: { BeeProgressBar },
    props: {
        value: { type: [String, Number], default: '0' },
        title: { type: [String], default: 'untitle' },
        color: { type: String, default: '#8320FD' },
        titleColor: { type: String, default: '#8320FD' },
        symbol: { type: String, default: '%' },
    },
}
</script>

<style scoped></style>
