export default {
    active_books: 'Active books',
    age: 'Age',
    available_books: 'Available books',
    inactive_books: 'Books read',
    books: 'Books',
    book_assignment: 'Book Assignment',
    fiction: 'Fiction',
    free: 'Free',
    genre: 'Genre',
    library: 'Library',
    more_filters: 'More filters...',
    next: 'Next',
    non_fiction: 'Non-Fiction',
    of: ' of ',
    previous: 'Previous',
    back: 'Back to Library',
    goBack: 'Back',
    hours_read_per_student: 'Reading hours',
    price: 'Price',
    search: 'Search',
    see_all: 'See all',
    see_less: 'See less',
    short_texts: 'Short texts',
    focus_skill: 'Focus skill',
    topic: 'Topic',
    with_price: 'With price',
    assign_this_book: 'Assign this book',
    select_init_month: 'Select start month',
    select_end_month: 'Select end month',
    select_class: 'Select a class',
    activate_assign: 'Activate assignment',
    assignment_date: 'Assignment date',
    reading_comprehension: 'Reading comprehension',
    well_done: 'Well done!',
    success_msg_book: 'The book <b>"{book}"</b> has been assigned successfully.',
    sucess_modification: 'The assignation has been modified successfully.',
    success_book_assignation_msg: "Your students will see the book in the assigned book section, don't forget to monitor their progress!",
    modify_assignment: 'Modify assignment',
    dont_modify_assignment: 'The 5 day range was met to be able to modify this assignment',
    total_page_read: 'Total pages read',
    reading_hours: 'Reading hours',
    questions: 'Questions',
    page_read_detail: 'Pages read by students',
    show_all: 'Show all',
    show_minus: 'Show less',
    assigment_deleted_success: 'Assigment was deleted successfully',
    assigment_deleted_error: 'Failed to delete assignment',
    confirm_delete: 'Confirm',
    confirm_delete_msg: `Are you sure you want to delete this record? If so, you won't be able to recover it in the future.`,
    no_results: 'There are no results for this search',
    course_not_selected: 'You must select a course to continue',
    country: 'Country',
    filters: 'Filters',
    select_topic: 'Select topic',
    select_genre: 'Select genre',
    select_country: 'Select country',
    age_1: '6 to 8',
    age_2: '9 to 11',
    age_3: '12 to 13',
    age_4: '14 to 15',
    age_5: '16+',
    ageRange: 'Age range',
    ageRangeValue: '{fromAge} to {toAge} years',
    title_carousel_1: "Novelties",
    title_button_card: "Open the book",
    error_consulting_books: "Error consulting books",
    no_books_found: "Error finding books",
    reboot_progress_warning: "The progress of students who have previously read a book on their own that you are now assigning, will be reset for you to track on the date of the assignment.",
    important_notice: "Important Notice",
    additional_information: 'Additional information',
    book_information: 'Book Information',
    book_rating: 'Rating',
    number_of_questions: 'Nº of questions',
    success_book_assignation_title: 'Book assigned successfully!',
    success_modification_book_assignation_title: 'Book assignment modified successfully!',
    lexile: 'Lexile Measure',
    select_lexile: 'Select Lexile Range',
    download_catalog: 'Download catalog'
}
