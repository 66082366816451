import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import userData from '@/store/modules/user'
import library from '@/store/modules/library'
import bookReading from './modules/BookReading'
import challenge from './modules/Challenge'
import common from './modules/Common'
import reports from './modules/Reports'
import diagnoses from './modules/Diagnoses'
import fluency from './modules/Fluency'
import survey from './modules/Survey'

Vue.use(Vuex)

const vuexPersist = new VuexPersist({
    // Al cambiar esta llave debe cambiarse también en "@/utils/request" para poder obtener el token
    key: 'teacher-beereaders-app',
    storage: window.localStorage,
    reducer: (state: any) => ({
        token: state.token,
        categories: state.categories,
        userData: state.userData,
        dashboard: state.dashboard,
        common: state.common,
        messages: state.messages,
        notifications: state.notifications,
        marketingParams: state.marketingParams,
    }),
})

const defaultState = () => ({
    token: '',
    showNewMessageModal: false,
    showResendMessageModal: false,
    showReplyModal: false,
    messages: [],
    notifications: [],
    loadingAssigment: false,
    marketingParams: [],
})

export default new Vuex.Store({
    state: defaultState,
    mutations: {
        token: (state: any, value: any) => {
            state.token = value
        },
        marketingParams: (state: any, value: any[]) => {
            state.marketingParams = value
        },
    },
    actions: {
        resetState({ state }: any) {
            Object.assign(state, defaultState())
        },
        setToken({ commit }: any, value: any) {
            commit('token', value)
        },
        clearSession(state) {
            Object.assign(state, defaultState)
            setTimeout(() => {
                // @ts-ignore
                vuexPersist.storage.clear()
            }, 10)
        },
        setShowNewMessageModal({ state }: any) {
            state.showNewMessageModal = !state.showNewMessageModal
        },
        setShowResendMessageModal({ state }: any) {
            state.showResendMessageModal = !state.showResendMessageModal
        },
        setShowReplyModal({ state }: any) {
            state.showReplyModal = !state.showReplyModal
        },
        loadAssigment({ state }: any) {
            state.loadingAssigment = !state.loadingAssigment
        },
        setMessages({state}: any, value: any){
            state.messages = value
        },
        setMarketingParams({ commit }: any, value: any) {
            commit('marketingParams', value)
        },
    },
    modules: {
        userData,
        library,
        bookReading,
        challenge,
        common,
        reports,
        diagnoses,
        fluency,
        survey
    },
    plugins: [vuexPersist.plugin],
})
