<template>
    <v-card
        elevation="5"
        :class="`${selected ? 'selected' : 'no-selected'} pointer`"
        :id="cardId"
        :loading="loading"
        height="200"
    >
        <v-row>
            <v-col
                v-resize="onResize"
                :id="chartId"
                cols="12"
                class="graphs mt-3"
                v-show="chartsReady"
            />
        </v-row>
    </v-card>
</template>

<script>
const echarts = require('echarts')
export default {
    name: 'CardStats',
    props: {
        chartId: { type: String, default: 'chart-without-id' },
        cardId: { type: String, default: 'card-without-id' },
        title: { type: String, default: 'without title' },
        icon: { type: String, default: 'without title' },
        loading: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        number: { type: Number, default: 0 },
        areaColor: { type: String, default: '#FEEDDE' },
        lineColor: { type: String, default: '#F9C456' },
        percent: { type: Boolean, default: false },
        data: { type: Array, default: () => [0, 0, 0, 0, 0, 0, 0] },
    },
    mounted() {
        this.chart = echarts.init(document.getElementById(this.chartId))
        this.setChart()
    },
    data: () => ({
        chart: null,
        chartsReady: false,
    }),
    methods: {
        onResize() {
            if (this.chartsReady) {
                this.chart.resize()
            }
        },
        setChart() {
            this.chart.setOption(this.getOption(this.getSerieData()))
            this.chartsReady = true
            setTimeout(() => {
                this.onResize()
            }, 10)
        },
        getOption(serieData) {
            return {
                title: {
                    text: `{icon|}{text| ${serieData.number}}${
                        serieData.percent ? '{percent|%}' : ''
                    }`,
                    subtext: `{text|         ${serieData.title}}`,
                    textStyle: {
                        rich: {
                            icon: {
                                height: 30,
                                backgroundColor: {
                                    image: serieData.icon,
                                },
                            },
                            text: {
                                align: 'left',
                                color: 'black',
                                fontSize: 30,
                                fontWeight: 'bold',
                            },
                            percent: {
                                verticalAlign: 'top',
                                color: 'black',
                                fontSize: 18,
                                fontWeight: 'bold',
                            },
                        },
                    },
                    subtextStyle: {
                        rich: {
                            text: {
                                align: 'left',
                                fontSize: this.$vuetify.breakpoint.lg ? 11 : 14,
                            },
                        },
                    },
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '0%',
                    top: '50%',
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    show: false,
                },
                yAxis: {
                    type: 'value',
                    show: false,
                },
                series: [serieData.data],
            }
        },
        getSerieData() {
            const { title, icon, lineColor, areaColor, percent, data, number } = this

            return {
                data: {
                    data,
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: areaColor,
                                },
                                {
                                    offset: 0.75,
                                    color: areaColor,
                                },
                                {
                                    offset: 1,
                                    color: 'white',
                                },
                            ],
                            global: false,
                        },
                    },
                    lineStyle: {
                        color: lineColor,
                        width: 5,
                    },
                },
                title,
                icon,
                percent,
                number,
            }
        },
    },
}
</script>

<style scoped>
.no-uppercase {
    text-transform: none;
}
.graphs {
    width: 10vh;
    height: 22vh;
}
.pointer {
    cursor: pointer;
}
.pointer canvas {
    cursor: pointer;
}
.no-selected {
    border: 5px solid transparent;
}
.selected {
    border: 5px solid #b9b1d5;
}
</style>
