export default {
    active_books: 'Libros activos',
    age: 'Edad',
    available_books: 'Libros disponibles',
    inactive_books: 'Libros ya leídos',
    books: 'Libros',
    book_assignment: 'Asignación de libros',
    fiction: 'Ficción',
    free: 'Gratis',
    genre: 'Género',
    library: 'Biblioteca',
    more_filters: 'Más filtros...',
    next: 'Siguiente',
    non_fiction: 'No Ficción',
    of: ' de ',
    previous: 'Anterior',
    back: 'Volver a la Biblioteca',
    goBack: 'Volver',
    hours_read_per_student: 'Horas de lectura',
    price: 'Precio',
    search: 'Buscar',
    see_all: 'Ver todo',
    see_less: 'Ver menos',
    short_texts: 'Textos cortos',
    focus_skill: 'Enfoque de habilidades',
    topic: 'Tema',
    with_price: 'Con precio',
    assign_this_book: 'Asignar este libro',
    select_init_month: 'Seleccione mes de partida',
    select_end_month: 'Seleccione mes final',
    select_class: 'Seleccione un curso',
    activate_assign: 'Activar asignación',
    assignment_date: 'Fecha de asignación',
    reading_comprehension: 'Comprensión lectora',
    well_done: '¡Muy bien hecho!',
    success_msg_book: 'El libro <b>"{book}"</b> ha sido asignado correctamente.',
    sucess_modification: 'La asignación ha sido modificada correctamente.',
    success_book_assignation_title: '¡Libro activado con éxito!',
    success_modification_book_assignation_title: 'Asignación modificada con éxito!',
    success_book_assignation_msg: 'Tus estudiantes verán el libro en la sección libro asignado, ¡No olvides monitorear su progreso!',
    modify_assignment: 'Modificar asignación',
    dont_modify_assignment: 'Se cumplio el plazo de 5 días para poder modificar esta asignación',
    total_page_read: 'Total de páginas leídas',
    reading_hours: 'Horas de lectura',
    questions: 'Preguntas',
    page_read_detail: 'Páginas leídas por los estudiantes',
    show_all: 'Mostrar todos',
    show_minus: 'Mostrar menos',
    assigment_deleted_success: 'Asignación eliminada correctamente',
    assigment_deleted_error: 'Error al eliminar asignación',
    confirm_delete: 'Confirmar',
    confirm_delete_msg: '¿Seguro que deseas eliminar este registro?. No podrás recuperarlo en el futuro.',
    no_results: 'No hay resultados para su búsqueda',
    course_not_selected: 'Debe seleccionar un curso para continuar',
    country: 'País',
    filters: 'Filtros',
    select_topic: 'Seleccione tema',
    select_genre: 'Seleccione género',
    select_country: 'Seleccione país',
    age_1: '6 a 8',
    age_2: '9 a 11',
    age_3: '12 a 13',
    age_4: '14 a 15',
    age_5: '16+',
    ageRange: 'Rango de edad',
    ageRangeValue: '{fromAge} a {toAge} años',
    title_carousel_1: "Novedades",
    title_button_card: "Abrir este Libro",
    error_consulting_books: "Error consultando Libros",
    no_books_found: "Error encontrando los libros",
    reboot_progress_warning: "El progreso de los estudiantes que hayan leído el libro previamente será reiniciado para que usted pueda hacer el seguimiento en la fecha de la asignación.",
    important_notice: "Aviso importante",
    additional_information: 'Información adicional',
    book_information: 'Información del libro',
    book_rating: 'Valoración',
    number_of_questions: 'Nº de preguntas',
    lexile: 'Medida Lexile',
    select_lexile: 'Selecciona Rango Lexile',
    download_catalog: 'Descargar catálogo'
}
