export default {
    evaluate: 'Evaluaciones',
    fluency: 'Fluidez',
    titles: {
        assessmentOfReadingProficiency: 'Evaluación de la fluidez lectora',
        results: 'Resultados',
        assignmentsAndLectures: 'Asignaciones y lecturas',
        listOfAssignedLectures: 'Lista de lecturas asignadas',
        studentList: 'Listado de estudiantes',
        assignedLecture: 'Lectura asignada',
        record: 'Grabación',
        lectureObservations: 'Observaciones de la lectura',
        lectureQuality: 'Calidad lectora',
        assignLecture: 'Asignar lectura',
        evaluations: 'Evaluaciones',
        assignedEvaluations: 'Evaluaciones Asignadas',
        generalInformation: 'Información general',
        classResultsPerSkill: 'Resultados de la clase por habilidad',
        studentsList: 'Listado de estudiantes',
        assignEvaluation: 'Asignar evaluación',
        assignDiagnosis: 'Asignar diagnostico',
        updateDiagnosis: 'Actualizar diagnostico',
        successfulDiagnosisAssignment: '¡Evaluación activada con éxito!',
        readingErrors: 'Errores de lectura',
        readingQuality: 'Calidad de lectura',
        recordingResult: 'Resultado de grabación',
        studentInformation: 'Información del estudiante',
        recording: 'Grabación',
        assignedText: 'Texto asignado',
        suggestResult: 'Resultado sugerido',
        lectureTime: 'Tiempo de lectura',
        textInformation: 'Información del texto',
    },
    messages: {
        evaluationTextAssignedTitle: 'Lectura asignada con éxito',
        evaluationTextAssignedMessage: 'Revisa el resumen de tu asignación.',
        whatIsLectureTime: 'El tiempo de lectura es el tiempo real desde la primera palabra detectada hasta la última palabra detectada en la grabación.',
        unsavedChanges: 'Hay cambios sin guardar',
    },
    evaluations: {
        states: {
            notInitiated: 'Sin iniciar',
            inProgress: 'En progreso',
            completed: 'Completado',
        },
        observations: {
            correct: 'Correcta',
            insertion: 'Inserción',
            substitution: 'Sustitución',
            omition: 'Omisión',
            repetition: 'Repetición',
            dicitonError: 'Error de dicción',
            corrects: 'Correcciones',
            inserts: 'Inserciones',
            substitutions: 'Sustituciones',
            omitions: 'Omisiones',
            repetitions: 'Repeticiones',
            dictionErrors: 'Errores de acentuación',
        },
        readingQuality: {
            NO_READER: 'No lector',
            SYLLABIC_READING: 'Lectura silábica',
            WORD_BY_WORD_READING: 'Lectura palabra por palabra',
            SHORT_UNITS_READING: 'Lectura de unidades cortas',
            FLUENT_READING: 'Lectura fluida',
        },
        readingQualityValues: {
            NO_READER: 'No lector',
            SYLLABIC_READING: 'Silábica',
            WORD_BY_WORD_READING: 'Palabra por palabra',
            SHORT_UNITS_READING: 'Unidades cortas',
            FLUENT_READING: 'Fluida',
        },
        readingSpeed: {
            SUPER_SLOW: 'Muy lenta',
            SLOW: 'Lenta',
            MEDIUM_LOW: 'Medio baja',
            MEDIUM_HIGH: 'Medio alta',
            FAST: 'Rápida',
            SUPER_FAST: 'Muy rápida',
        },
        errorsTypes: {
            errorType: 'Tipo de error',
            CORRECT: 'Correcta',
            OMISSION: 'Omisión de palabra',
            SUBSTITUTION: 'Substitución de palabra',
            INSERTION: 'Inserción de palabra',
            REPETITION: 'Repetición de palabra',
            REVERSAL: 'Inversion de palabra',
            DICTION_ERROR: 'Error de acentuación',
        },
    },
    coursesList: {
        '1-3': '1° a 3° grado',
        '4-6': '4° a 6° grado',
        '7-8': '7° a 8° grado',
        '9-10': '9° a 10° grado',
        '11-12': '11° a 12° grado',
        '11-13': '11° a 13° grado',
        '12-13': '12° a 13° grado',
    },
    utils: {
        numberOfWords: 'Número de palabras',
        textTitle: 'Título del texto',
        totalOfQuestions: 'Total de preguntas',
        totalWords: 'Total de palabras',
        textWordsNumber: 'Nº de palabras del texto',
        diagnosisType: 'Tipo de diagnóstico',
        text: 'Texto',
        question: 'Pregunta',
        to: 'a',
        grade: 'Grado',
        seconds: 'segundos',
        second: 'segundo',
        minutes: 'minutos',
        minute: 'minuto',
    },
    texts: {
        assignLecture: 'Selecciona la lectura más adecuada para tu clase.',
        tooltips: {
            diagnosesState: {
                completed: 'Completed evaluation',
                inProgress: 'Pending evaluation',
            },
        },
    },
    tables: {
        evaluationsList: {
            labels: {
                section: 'Segment',
                initial: 'Diagnostic evaluation',
                middle: 'Progressive evaluation',
                final: 'Closing evaluation',
            },
        },
        assignedEvaluations: {
            labels: {
                assignedText: 'Texto asignado',
                evaluation: 'Evaluación',
                state: 'Estado',
                progress: 'Progreso',
                assignedTo: 'Asignado a',
                course: 'Curso',
                startDate: 'Fecha de creación',
                endDate: 'Fecha de finalización',
                actions: 'Acciones',
            },
        },
        diagnosisResults: {
            labels: {
                totalWords: 'Total palabras',
                totalErrors: 'Total',
                correctWords: 'Palabras correctas',
                correctWordsPerMinute: 'Palabras correctas por minuto',
                readingSpeed: 'Velocidad lectora',
                readingQuality: 'Calidad lectora',
            }
        },
        studentsList: {
            labels: {
                state: 'Status',
                tier: 'Tier',
                actions: 'Actions',
                beeStage: 'BeeReaders Stage',
                student: 'Estudiante',
                firstName: 'Nombre',
                lastName: 'Apellido',
                course: 'Curso', 
            },
        },
        results: {
            labels: {
                wordsPerMinute: 'Palabras por minuto',
                correctWordsPerMinute: 'Palabras correctas por minuto',
                readingSpeed: 'Velocidad lectora',
                lectureQuality: 'Calidad lectora',
            },
        },
    },
    inputs: {
        labels: {
            evaluationText: 'Texto de evaluación',
            evaluationType: 'Tipo de evaluación',
            state: 'Estado',
            course: 'Curso',
            evaluation: 'Diagnóstico',
            assignTo: 'Asignar a',
            filterTextsByGrade: "Filtrar textos por grado"
        },
        placeholders: {
            chooseEvaluationText: 'Elige un texto de evaluación',
            chooseEvaluationType: 'Elige un tipo de evaluación',
            chooseEvaluationState: 'Elige un estado de evaluación',
            chooseCourse: 'Elige un curso',
        },
        filters: {
            evaluationState: {
                all: 'Mostrar todo',
            },
            course: {
                showAll: 'Todas las clases',
            },
            utils: {
                all: 'Todo',
            },
        },
    },
    actions: {
        evaluation: {
            assign: 'Asignar evaluación',
            edit: 'Editar evaluación',
            delete: 'Eliminar evaluación',
            update: 'Actualizar evaluación',
            activate: 'Activar evaluación',
            selectFontSize: 'Selecciona el tamaño de letra',
            markAs: 'Marcar como',
        },
        lecture: {
            assign: 'Asignar lectura',
            edit: 'Editar lectura',
            delete: 'Eliminar lectura',
            update: 'Actualizar lectura',
            activate: 'Activar lectura',
        },
        export: 'Exportar',
        exportExcel: 'Exportar a Excel',
        exportPdf: 'Exportar a PDF',
        assign: 'Asignar',
        view: 'Ver',
        evaluate: 'Evaluar',
        confirmation: {
            delete: "¿Confirma que desea eliminar esta evaluación? No podrá recuperarla más tarde.",
            assign: '¿Estás seguro de que quieres asignar esta evaluación? Puedes eliminar después',
        },
    },
    errors: {
        codes: {
            700: 'Maximum number of evaluations reached',
            701: 'Level is already assigned',
            702: 'Evaluation has answers',
            703: 'Evaluation does not exists',
            default: 'An error has occurred. Please try again later',
        },
    },
}
