import Vue from 'vue'
// @ts-ignore
import vueHeadful from 'vue-headful'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './assets/style/utils.scss'
import './components/core/RegisterCoreComponents'
import registerFilters from './filters'
import registerComponents from './components'
import Footer from '@/components/library/preview/content/Footer.vue'
import registerInterceptors from './interceptor'
import './assets/style/app.scss'
// @ts-ignore
import VueEllipseProgress from 'vue-ellipse-progress'
// @ts-ignore
import ECharts from 'vue-echarts'
// @ts-ignore
import VueLoading from 'vuejs-loading-plugin'
// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts-gl'
// @ts-ignore
import StarRating from 'vue-star-rating'
// @ts-ignore
import CountryFlag from 'vue-country-flag'
// @ts-ignore
import FunctionalCalendar from 'vue-functional-calendar'
// @ts-ignore
import JsonExcel from 'vue-json-excel'

// @ts-ignore
import VueHtmlToPaper from 'vue-html-to-paper'
import VueAnalytics from "vue-analytics";

const options = {
    name: '_blank',
    specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
    styles: [
        'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900',
        'https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css',
        'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
        './assets/style/utils.scss',
    ],
    script: {},
}

Vue.use(VueHtmlToPaper, options)

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper)

// register component to use
Vue.component('v-chart', ECharts)
import customLoader from './components/core/CustomLoader.vue'
import Modal from './plugins/modal'
// @ts-ignore
import UtilsPlugin from './plugins/utils'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

registerFilters(Vue)
registerComponents(Vue)
registerInterceptors()
Vue.config.productionTip = false
Vue.component('vue-headful', vueHeadful)
Vue.component('star-rating', StarRating)
Vue.component('country-flag', CountryFlag)
Vue.component('app-footer', Footer)
Vue.use(VueEllipseProgress)
Vue.use(Modal)
Vue.use(UtilsPlugin)
Vue.use(FunctionalCalendar, {
    dayNames: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
})
Vue.component('download-excel', JsonExcel)

import moment from 'moment'
require('moment/locale/es')

// @ts-ignore
const userLang = navigator.language || navigator.userLanguage || navigator.browserLanguage
const lang = /^es\b/.test(userLang) ? 'es' : 'en'

moment.locale(lang)

Vue.use(require('vue-moment'), {
    moment,
})

Vue.use(VueLoading, {
    dark: true, // default false
    text: 'Cargando', // default 'Loading'
    loading: false, // default false
    customLoader, // replaces the spinner and text with your own
    background: 'rgb(81, 71, 115)', // set custom background
})

//and then use it in main.js
Vue.use(VueAnalytics, {
    id: "UA-172785369-3",
    router,
});
/*
if (process.env.APP_ENV !== 'development') {
    Sentry.init({
        Vue,
        dsn: 'https://e523c4c6ac8244e59899e8b8abaa15d3@o516106.ingest.sentry.io/5671879',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    })
}
*/
new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount('#app')
