import _Vue from 'vue' // <-- notice the changed import

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;

export interface Author {
    blog: string
    id: number
    name: string
    twitter: string
    web: string
}

export default function utilsPlugin(Vue: typeof _Vue, options?: any): void {
    Vue.prototype.$utils = {
        getFullName(user = { firstname: '', lastname: '' }) {
            if (user?.firstname && user?.lastname) {
                const { firstname, lastname } = user
                return `${this.upperFirstLetter(firstname.split(' ')[0])} ${this.upperFirstLetter(
                    lastname.split(' ')[0]
                )}`
            }
        },
        upperFirstLetter(word: string = '') {
            return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`
        },
        getFirstLetter(word: string = '') {
            if (word) return `${word.charAt(0).toUpperCase()}`
            return ''
        },
        pad(num: number, places: number = 0) {
            const zero = places - num.toString().length + 1
            return Array(+(zero > 0 && zero)).join('0') + num
        },
        percentile(arr: Array<any>, p: number) {
            if (arr.length === 0) return 0

            if (p <= 0) return arr[0]
            if (p >= 1) return arr[arr.length - 1]

            let index = (arr.length - 1) * p,
                lower = Math.floor(index),
                upper = lower + 1,
                weight = index % 1

            if (upper >= arr.length) return arr[lower]
            return arr[lower] * (1 - weight) + arr[upper] * weight
        },
        percentRank(arr: Array<any>, v: number) {
            for (let i = 0, l = arr.length; i < l; i++) {
                if (v <= arr[i]) {
                    while (i < l && v === arr[i]) i++
                    if (i === 0) return 0
                    if (v !== arr[i - 1]) {
                        i += (v - arr[i - 1]) / (arr[i] - arr[i - 1])
                    }
                    return ((i / l) * 100).toFixed()
                }
            }
            return 1
        },
        getAuthorName(author: Author | any, authorList: Array<Author | any> = [], $t: any, defaultName = 'Anonymous') {
            return authorList && authorList.length
                ? authorList.map(({ name }) => name).join(', ')
                : author
                ? author.name
                : defaultName
        },
    }
}
