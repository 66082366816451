import Services from './base-services'

const service = new Services('challengeConfig')
import mapParameters from '../utils/map-parameters'


export default {
    getConfigByUser(userId: number|string) {
        const params = mapParameters({
            userId: userId ? userId : null
        })
        return service.get('getConfigByUser', params)
    },
    updateConfig(userId: number|string, typography: string, fontSize: string) {
        const params = {
            userId,
            typography,
            fontSize,
            soundActive: false,
            soundVol: '0'
        }
        return service.put('updateConfig', params)
    }
}
