export default {
    index: 'Índice',
    marker: 'Marcadores',
    dictionary: 'Diccionario',
    question: 'Preguntas',
    storyletter: 'Narrador',
    settings: 'Ajustes',
    notes: 'Notas',
    music: 'Música',
    searchInput: 'Buscar Definición...',
    warnDictionary: 'Debe buscar una palabra a la vez.',
    warnMark: 'Para marcar un texto, es necesario que sean al menos una frase de 5 palabras.',
    not_question: 'No hay preguntas en el presente capítulo.',
    finish_book: 'Has llegado al final del libro',
    congrats: '¡Felicidades!',
    finish_book_msg: 'Calificalo y luego presiona el boton para volver al inicio y continuar',
    go_back: 'Volver al Inicio',
    bad: 'Malo',
    regular: 'Regular',
    good: '¡Bueno!',
    very_good: '¡Muy Bueno!',
    excellent: '¡Excelente!',
    magnificent: 'Magnífico',
    start_reading: 'Comenzar la lectura',
    qualification: 'Calificación',
    days_to_finish: 'Dias para terminar',
    go_to_library: 'Valorar e ir a la biblioteca',
    go_to_home: 'Valorar e ir al inicio',
    congrats_msg_1: 'Has culminado la lectura',
    finish_msg_1: 'Coméntanos, ¿Te gustó el libro?',
    finish_msg_2: '¿Qué te pareció este libro?',
    send: 'Enviar',
    chapter: 'Capítulo',
    answer: 'Responder',
    try_again: 'Volver a intentar',
    continue: 'Continuar',
    understood: 'Entendido',
    clue: 'Pista',
    buy_wildcard: 'Comprar Comodín',
    have: 'Tienes',
    wildcard_50_50: 'Comodín 50/50',
    wildcard_buy_msg:
        'Este comodín te permitirá eliminar la mitad de las alternativas, aumentando la posibilidad de acertar',
    wildcard_used_msg: 'Has comprado el Comodín 50/50',
    characters: 'Personajes',
    actions: 'Acciones',
    vocabulary: 'Vocabulario',
    places: 'Lugares',
    highlight_modal_msg: 'Selecciona la categoría en la que deseas guardar este extracto:',
    warnMarkSelectType: 'Debes seleccionar un tipo de marcador para continuar',
    highlight_saved: 'Marcador Guardado',
    located_in: 'Ubicado en',
    page: 'página',
    music_2: 'Música ambiental',
    music_msg_1: 'Puedes activar y desactivar la música ambiental si lo deseas.',
    listen_background_music: 'Escuchar música ambiental',
    activate: 'Activar',
    desactivate: 'Desactivar',
    without_highlights: 'Sin marcadores para mostrar',
    comments: 'Comentarios',
    dictionary_msg_1: 'Ingresa la palabra que deseas buscar:',
    search_word: 'Buscar palabra',
    example: 'Ejemplo',
    notes_modal_msg: 'Escribe la nota que deseas crear',
    without_notes: 'Sin notas para mostrar',
    showAllNotes: 'Mostrar mis notas en el texto siempre.',
    reading_aloud: 'Narración',
    listen_read_aloud: 'Escuchar voz de narración',
    readaloud_msg_1: 'Puedes activar y desactivar el audio de narracion si lo deseas.',
    not_storyteller_data_found:
        'El narrador no está disponible para esta lectura, puedes intentar de nuevo más tarde.',
    choose_voice: 'Elige la voz del Narrador',
    my_notes: 'Mis notas',
    note_pill_msg: 'Nota creada',
    inactivity_modal:
        '¿Sigue leyendo?, hemos detectado que tiene 30 minutos sin cambiar de página.',
    without_beecoins: 'No posees BeeCoins suficientes para usar el comodín 50/50.',
    wildcard_alredy_use: 'Ya ha utilizado un comodín para esta pregunta',
    multiple_selection_only: 'Esta opción solo está disponible para preguntas de opción múltiple',
    first_chance: 'Primera oportunidad',
    second_chance: 'Segunda oportunidad',
    back_to_the_library: 'Volver a la biblioteca',
    no_questions: 'No hay preguntas en este momento.',
    no_meaning: 'No encontramos significado.',
}
