export default {
    actions: {
        assign: 'Assign this book',
        modify: 'Edit the assignment',
        activate: 'Activate Assignation',
        cancel: 'Cancel',
        saveChanges: 'Save changes',
        goBackToLibrary: 'Back to Library',
        seeAssignedBooks: 'See assigned books',
        loadMoreAssignments: 'Load more assignments',
    },
    inputs: {
        dates: {
            startDate: 'Initial date',
            endDate: 'End date',
        },
        course: {
            pick: 'Pick a course'
        },
        students: {
            assignTo: 'Assign to',
            all: 'All Students',
        },
        assignation: {
            name: 'Assignation name (Optional)',
        },
        assignationType: {
            actives: 'Actives',
            futureAssignations: 'Future Assignations',
            inactives: 'Inactives',
        }
    },
    placeholders: {
        dates: 'Pick a date',
        course: 'Pick a course',
        searchStudent: 'Search for a student',
        students: 'Choose the students',
        assignation: 'Write a name',
        studentsSelection: 'Students selection',
        totalQuestions: 'Total Questions',
        pagesAmount: 'Pages Amount',
        progress: 'Progress',
        readingComprenhension: 'Reading Comprehension',
    },
    titles: {
        assignTheBook: 'Assign the book',
        modifyTheAssignation: 'Modify the assignation',
        assignmentGeneralInformation: 'Assignment general information',
        generalPerformance: 'General Performance',
        assignmentTableTitles: {
            bookTitle: 'Book title',
            assignTo: 'Assign to',
            startDate: 'Start date',
            endDate: 'End date',
            actions: 'Actions',
            groupType: 'Group type',
        }
    },
    texts: {
        focusSkill: 'The following graph shows how the three reading comprehension micro-skills are distributed in this specific book.'
    },
    utils: {
        diffDays: {
            createdAgo: 'Created',
            createdInLessThan: 'Created in less than',
            days: 'days',
            day: 'day',
            daysAgo: 'days ago',
            dayAgo: 'day ago',
            hours: 'hours',
            hour: 'hour',
            inLess: 'In less than',
            lessThan: 'Less than',
            startsIn: 'Starts in',
            startsInLessThan: 'Starts in less than',
            remain: 'Remain',
            remainLess: 'Remain less than',
            finished: 'Finished',
            finishedInLess: 'Finished in less than',
        },
        books: {
            noAuthor: 'No author',
        },
        students: {
            single: "Student",
            multiple: "Students",
        },
        tiers: {
            tier: 'Tier',
            noTier: 'No Tier',
            TIER_GROUP_1: 'Tier 1',
            TIER_GROUP_2: 'Tier 2',
            TIER_GROUP_3: 'Tier 3',
        },
        groupType: {
            TIER: 'Tier',
            CUSTOM: 'Custom',
            ALL_COURSE: 'All Course',
            NO_TYPE: 'No Type',
        }
    }
}