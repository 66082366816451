export default {
    first: '1er básico',
    second: '2do básico',
    third: '3ero básico',
    fourth: '4to básico',
    fiveth: '5to básico',
    sixth: '6to básico',
    seventh: '7mo básico',
    eighth: '8vo básico',
    nineth: '1er medio',
    tenth: '2do medio',
    eleventh: '3ero medio',
    twelveth: '4to medio',
}
