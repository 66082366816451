<template>
    <div></div>
</template>

<script>
import { mapState } from 'vuex'
import * as Sentry from '@sentry/vue'
import BookReadingService from '@/services/bookReading'

export default {
    computed: {
        ...mapState({
            bookReadConf: (state) => state.bookReading.bookReadConf,
            fontFamily: (state) => state.bookReading.bookReadConf.fontFamily,
            fontSize: (state) => state.bookReading.bookReadConf.fontSize,
            book: (state) => state.bookReading.book,
            user: (state) => state.userData.user,
        }),
    },
    watch: {
        fontFamily(newValue, oldValue) {
            console.debug(newValue, oldValue)
            this.saveConfigs()
        },
        fontSize(newValue, oldValue) {
            console.debug(newValue, oldValue)
            this.saveConfigs()
        },
    },
    methods: {
        saveConfigs() {
            BookReadingService.updateBookReadConfig({
                userId: this.user.id,
                bookId: this.book.id,
                typography: this.bookReadConf.typography,
                pageTexture: this.bookReadConf.pageTexture,
                fontColor: this.bookReadConf.fontColor,
                fontSize: this.bookReadConf.fontSize,
                fontFamily: this.bookReadConf.fontFamily,
                soundActive: false,
                sound: '0',
                showNotes: false,
            }).catch((error) => Sentry.captureException(error))
        },
    },
}
</script>
