<template>
    <v-app :style="{ backgroundColor: '#f6f6f6' }">
        <router-view />
        <AppModal />
    </v-app>
</template>

<script>
import { loadScript } from 'vue-plugin-load-script';

export default {
    name: 'App',
    mounted() {
        // Load Gtag
        loadScript(`https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GTAG_ID}`)
        .then(() => {
            window.dataLayer = window.dataLayer || [];
            // eslint-disable-next-line prefer-rest-params
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', process.env.VUE_APP_GTAG_ID);
        })
        .catch(() => {
            // Failed to fetch script
            console.log('Unable to load Gtag')
        });
    },
    data: () => ({
        //
    }),
}
</script>
