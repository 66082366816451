<template>
    <div
        class="background-arrow d-flex justify-center align-center"
        @click="buttonAction"
        :style="style"
    >
        <img src="../../assets/img/arrows/flecha-siguiente_2.svg" alt="next" />
        <div class="position-absolute">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'arrow-btn',
    props: {
        width: { type: [Number, String], default: '20vh' },
        height: { type: [Number, String], default: '5vh' },
    },
    methods: {
        buttonAction() {
            this.$emit('brClick')
        },
    },
    computed: {
        style() {
            return `width: ${this.width}; height:${this.height};`
        },
    },
}
</script>
<style>
.background-arrow {
    background-repeat: round;
}

.background-arrow img {
    width: 100%;
}

.background-arrow:hover {
    cursor: pointer;
}
</style>
