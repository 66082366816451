export default {
    index: 'Index',
    marker: 'Highlighting',
    dictionary: 'Dictionary',
    question: 'Questions',
    storyletter: 'Read aloud',
    settings: 'Settings',
    notes: 'Notes',
    music: 'Music',
    searchInput: 'Search Definition...',
    warnDictionary: 'You must search one word at a time.',
    warnMark: 'To mark a text, it needs to be at least a 5-word sentence.',
    not_question: 'There are no questions in this chapter.',
    finish_book: 'You have reached the end of the book',
    congrats: 'Congratulations!',
    finish_book_msg: 'Rate it and then press the button to return to the beginning and continue',
    go_back: 'Go to star',
    bad: 'Bad',
    regular: 'Regular',
    good: 'Good!',
    very_good: 'Very good!',
    excellent: 'Excellent!',
    magnificent: 'Magnificent',
    start_reading: 'Start reading',
    qualification: 'Cualification',
    days_to_finish: 'Days for finish',
    go_to_library: 'Rate and go to the library',
    go_to_home: 'Rate and go to home',
    congrats_msg_1: 'You have finished reading',
    finish_msg_1: 'Tell us, did you like the book?',
    finish_msg_2: 'How did you like this book?',
    send: 'send',
    chapter: 'Capítulo',
    answer: 'Answer',
    try_again: 'Try again',
    continue: 'Continue',
    understood: 'Understood',
    clue: 'Clue',
    buy_wildcard: 'Buy Wildcard',
    have: 'You have',
    wildcard_50_50: '50/50 Wildcard',
    wildcard_buy_msg:
        'This wildcard will allow you to eliminate half of the alternatives, increasing the chance of hitting',
    wildcard_used_msg: 'You bought the 50/50 Wildcard',
    characters: 'Characters',
    actions: 'Actions',
    vocabulary: 'Vocabulary',
    places: 'Places',
    highlight_modal_msg: 'Select the category in which you want to save this extract:',
    warnMarkSelectType: 'You must select a marker type to continue',
    highlight_saved: 'Highlight Saved',
    located_in: 'Located in',
    page: 'page',
    music_2: 'Background music',
    music_msg_1: 'You can activate and desactivate the background music if you wish.',
    listen_background_music: 'Listen background music',
    activate: 'Activate',
    desactivate: 'Desactivate',
    without_highlights: 'Without highlights for to show',
    comments: 'Comentarios',
    dictionary_msg_1: 'Enter the word you want to search for:',
    search_word: 'Search word',
    example: 'Example',
    notes_modal_msg: 'Write the note you want to create',
    without_notes: 'Without notes for to show',
    showAllNotes: 'show my notes in the text always.',
    reading_aloud: 'Narration',
    listen_read_aloud: 'Hear narration voice',
    readaloud_msg_1: 'You can turn narration audio on and off if you wish.',
    not_storyteller_data_found:
        'The narrator is not available for this reading, you can try again later.',
    choose_voice: 'Choose the voice of the Narrator',
    my_notes: 'My notes',
    note_pill_msg: 'Note created',
    inactivity_modal:
        'Are you reading? We have detected that you have 30 minutes without changing the page.',
    without_beecoins: `You don't have enough BeeCoins to use the 50/50 wild card.`,
    wildcard_alredy_use: 'You have already used a wildcard for this question',
    multiple_selection_only: 'This option is only available for multiple choice questions',
    first_chance: 'First chance',
    second_chance: 'Second chance',
    back_to_the_library: 'Back to the library',
    no_questions: 'No questions at this time.',
    no_meaning: 'We find no meaning.',
}
