export default {
    ninety_days: '90 días',
    quick_stats: 'Vista rápida',
    seven_days: '7 días',
    thirty_days: '30 días',
    global_summary: 'Resumen global',
    locate_information: 'Localizar información',
    integrate_information: 'Integrar e interpretar',
    reflect_evaluate: 'Reflexionar y valorar',
    abbreviated_months: [
        'En',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ag',
        'Sept',
        'Oct',
        'Nov',
        'Dic',
    ],
    inactive_users: 'Usuarios inactivos',
    last_login: 'Último acceso',
    stats: ['Usuarios activos', 'Horas de lectura', 'Libros leídos', 'Puntaje promedio en pruebas'],
    four_week: '4 semanas',
    eigth_weeks: '8 semanas',
    twelve_weeks: '12 semanas',
    press_legen_item_preview: 'Presiona cualquier elemento en la leyenda para mostrar su gráfica'
}
