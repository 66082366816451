
import { AxiosError, AxiosResponse } from 'axios'

// import router from '../router'

export default {
  success: (config: AxiosResponse) => config,
  error: (err: AxiosError) => {
    if (err.response && err.response.status === 403) {
      window.location.href = process.env.VUE_APP_BEEREADERS_LOGIN_URL
      console.error(err);
    }

    return Promise.reject(err)
  }
}
