import capitalize from './capitalize'
import date from './date'
import dateStartDate from './dateStartDate'

const filters = [
    { key: 'capitalize', filter: capitalize },
    { key: 'date', filter: date },
    { key: 'dateStartDate', filter: dateStartDate },
]

export default (vue: any) => {
    filters.forEach((filter) => {
        vue.filter(filter.key, filter.filter)
    })
}
