import Service from '../base-services'

const service = new Service()

export default {
    async validateIfUserHasSurvey(teacherID: number) {
        try {
            const data = await service.cmsPost<{ activeCampaign: boolean; userReplied: boolean }>(
                '/nps/validate-nps-state',
                { userId: teacherID }
            )

            return data
        } catch (error) {
            console.log(error)
            return { activeCampaign: false, userReplied: false }
        }
    },
    async sendUserSurvey(surveyAnswers: { userId: number; npsScore: number; comments: string }) {
        const data = await service.cmsPost<{
            ok: boolean
            result: {
                id: number
                user_id: number
                user_name: string
                user_lastname: string
                nps_score: number
                comment: string
                school_id: number
                school_name: string
                country: string
                created_at: string
                nps_campaign_id: number
            }
            error?: boolean
            description?: string
        }>('/nps/send-user-answers', surveyAnswers)
        return data
    },
}
