import ICommit from '@/store/contracts/commit'

const getDefaultState = () => {
    return {
        book: null,
        currentStage: 'bookCover',
        currentPage: 0,
        currentChapterData: null,
        currentChapter: null,
        bookAssessmentData: null,
        bookReadConf: null,
        epubPages: [],
        showIndex: false,
        showSettings: false,
        showQuestions: false,
        showDictionary: false,
        questionsForChapter: [],
        itemsData: null,
        tipsData: null,
        indexItem: 0,
        itemType: null,
        currentQuestion: null,
        indexQuestion: 0,
        hasQuestion: false,
        word: '',
        dictionaryMode: '',
        bookCategories: [],
        idiomId: null,
        idiomParentId: null,
        showIdiomModal: false,
        trackIndex: 0,
        lastQuestionOfGroup: false,
        lastQuestionOfBook: false,
        bookRatingData: [],
        totalRatings: null,
        pageNumber: 0,
        pagesLoaded: 0,
        epubPagesLoaded: 0,
    }
}

const BookReading = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        setBook: (state: any, value: any) => {
            state.book = value
        },
        increaseCurrentPage(state: any) {
            state.currentPage += 1
        },
        decreaseCurrentPage(state: any) {
            state.currentPage -= 1
        },
    },
    actions: {
        setBook({ commit }: ICommit, value: any) {
            commit('setBook', value)
        },
        setBookAssessmentData({ state }: any, value: any) {
            state.bookAssessmentData = value
        },
        setCurrentPage({ state }: any, value: any) {
            state.currentPage = value
        },
        setCurrentChapter({ state }: any, value: any) {
            state.currentChapter = value
        },
        setBookReadConf({ state }: any, value: any) {
            state.bookReadConf = value
        },
        setCurrentStage({ state }: any, value: String) {
            state.currentStage = value
        },
        setCurrentChapterData({ state }: any, value: any) {
            state.currentChapterData = value
        },
        setBookCategories({ state }: any, value: any) {
            state.bookCategories = value
        },
        increaseCurrentPage({ commit }: ICommit) {
            commit('increaseCurrentPage')
        },
        decreaseCurrentPage({ commit }: ICommit) {
            commit('decreaseCurrentPage')
        },
        hideAllUtils({ state }: any) {
            state.showSettings = state.showIndex = state.showQuestions = state.showDictionary = false
        },
        showIndex({ state }: any) {
            state.showSettings = state.showQuestions = state.showDictionary = false
            state.showIndex = !state.showIndex
        },
        showSettings({ state }: any) {
            state.showIndex = state.showQuestions = state.showDictionary = false
            state.showSettings = !state.showSettings
        },
        showQuestions({ state }: any) {
            state.showIndex = state.showSettings = state.showDictionary = false
            state.showQuestions = !state.showQuestions
        },
        showDictionary({ state }: any) {
            state.showIndex = state.showSettings = state.showQuestions = false
            state.showDictionary = !state.showDictionary
        },
        setFontFamily({ state }: any, value: any) {
            state.bookReadConf.fontFamily = value
        },
        setFontSize({ state }: any, value: any) {
            state.bookReadConf.fontSize = value
        },
        //Preguntas
        setQuestionForChapter({ state }: any, value: any) {
            state.questionsForChapter = value
        },
        setItemsData({ state }: any, value: any) {
            state.itemsData = value
        },
        setIndexItem({ state }: any, value: any) {
            state.indexItem = value
        },
        setItemType({ state }: any, value: any) {
            state.itemType = value
        },
        setCurrentQuestion({ state }: any, value: any) {
            state.currentQuestion = value
        },
        setIndexQuestion({ state }: any, value: any) {
            state.indexQuestion = value
        },
        setHasQuestion({ state }: any, value: any) {
            state.hasQuestion = value
        },
        //Dictionary
        setWord({ state }: any, word: any) {
            state.word = word
        },
        setDictionaryMode({ state }: any, value: any) {
            state.dictionaryMode = value
        },
        //reset state
        resetBookReadingState({ state }: any) {
            Object.assign(state, getDefaultState())
        },
        //        epub
        setEpubPages({ state }: any, value: any) {
            state.epubPages = value
        },
        //Linguistic Variables
        resetIdioms({ state }: any) {
            state.idiomId = state.idiomParentId = null
            state.showIdiomModal = false
        },
        setIdiomId({ state }: any, value: any) {
            state.idiomId = value
        },
        setIdiomParentId({ state }: any, value: any) {
            state.idiomParentId = value
        },
        showIdiomModal({ state }: any, value: any) {
            state.showIdiomModal = value
        },
        setTrackIndex({ state }: any, value: any) {
            state.trackIndex = value
        },
        //Para logros
        setLastQuestionOfGroup({ state }: any, value: any) {
            state.lastQuestionOfGroup = value
        },
        setLastQuestionOfBook({ state }: any, value: any) {
            state.lastQuestionOfBook = value
        },
        setBookRatingData({ state }: any, value: any) {
            state.bookRatingData = value
        },
        concatBookRatingData({ state }: any, value: any) {
            if (!state.bookRatingData.length) state.bookRatingData = value
            else state.bookRatingData = state.bookRatingData.concat(value)
        },
        setPageNumber({ state }: any, value: any) {
            state.pageNumber = value
        },
        increasePageNumber({ state }: any) {
            state.pageNumber += 1
        },
        setTotalRating({ state }: any, value: any) {
            state.totalRatings = value
        },
        increasePagesLoaded({ state }: any) {
            state.pagesLoaded += 1
        },
        increaseEpubPagesLoaded({ state }: any) {
            state.epubPagesLoaded += 1
        },
    },
}

export default BookReading
