export default {
    evaluate: 'Evaluations',
    fluency: 'Fluency',
    titles: {
        assessmentOfReadingProficiency: 'Assessment of Reading Proficiency',
        results: 'Results',
        assignmentsAndLectures: 'Assignments and Lectures',
        listOfAssignedLectures: 'List of Assigned Lectures',
        studentList: 'Student List',
        assignedLecture: 'Assigned Lecture',
        record: 'Record',
        lectureObservations: 'Lecture Observations',
        lectureQuality: 'Lecture Quality',
        assignLecture: 'Asignar lectura',
        evaluations: 'Evaluations',
        assignedEvaluations: 'Assigned Evaluations',
        generalInformation: 'General Information',
        classResultsPerSkill: 'Class Results per Skill',
        studentsList: 'Students List',
        assignEvaluation: 'Assign evaluation',
        assignDiagnosis: 'Assign diagnosis',
        updateDiagnosis: 'Update diagnosis',
        successfulDiagnosisAssignment: 'Successful evaluation activation!',
        readingErrors: 'Reading errors',
        readingQuality: 'Reading quality',
        recordingResult: 'Resultado de grabación',
        studentInformation: 'Student information',
        recording: 'Recording',
        assignedText: 'Assigned text',
        suggestResult: 'Suggest result',
        lectureTime: 'Lecture time',
        textInformation: 'Text information',
    },
    messages: {
        evaluationTextAssignedTitle: 'Lecture assigned successfully',
        evaluationTextAssignedMessage: 'Check the summary of your assignment',
        whatIsLectureTime: 'Lecture time is the actual time from the first word detected to the last word detected in the recording', 
        unsavedChanges: 'There are unsaved changes',
    },
    evaluations: {
        states: {
            notInitiated: 'Not initiated',
            inProgress: 'In Progress',
            completed: 'Completed',
        },
        observations: {
            correct: 'Correct',
            insertion: 'Insertion',
            substitution: 'Substitution',
            omition: 'Omission',
            repetition: 'Repetition',
            dicitonError: 'Diction Error',
            corrects: 'Corrects',
            inserts: 'Inserts',
            substitutions: 'Substitutions',
            omitions: 'Omissions',
            repetitions: 'Repetitions',
            dictionErrors: 'Accent Errors',
        },
        readingQuality: {
            NO_READER: 'No reader',
            SYLLABIC_READING: 'Syllabic reading',
            WORD_BY_WORD_READING: 'Word by word reading',
            SHORT_UNITS_READING: 'Short units reading',
            FLUENT_READING: 'Fluent reading',
        },
        readingQualityValues: {
            NO_READER: 'No reader',
            SYLLABIC_READING: 'Syllabic reading',
            WORD_BY_WORD_READING: 'Word by word reading',
            SHORT_UNITS_READING: 'Short units reading',
            FLUENT_READING: 'Fluent reading',
        },
        readingSpeed: {
            SUPER_SLOW: 'very slow',
            SLOW: 'Slow',
            MEDIUM_LOW: 'Medium low',
            MEDIUM_HIGH: 'Medium high',
            FAST: 'Fast',
            SUPER_FAST: 'Very fast',
        },
        errorsTypes: {
            errorType: 'Error type',
            CORRECT: 'Correct',
            OMISSION: 'Word omission',
            SUBSTITUTION: 'Word substitution',
            INSERTION: 'Word insertion',
            REPETITION: 'Word repetition',
            REVERSAL: 'Word reversal',
            DICTION_ERROR: 'Accent error',
        },
    },
    coursesList: {
        '1-3': '1° to 3° grade',
        '4-6': '4° to 6° grade',
        '7-8': '7° to 8° grade',
        '9-10': '9° to 10° grade',
        '11-12': '11° to 12° grade',
        '11-13': '11° to 13° grade',
        '12-13': '12° to 13° grade',
    },
    utils: {
        numberOfWords: 'Amount of words',
        textTitle: 'Text title',
        list: 'List of Diagnostics',
        instructions: 'Instructions to assign the diagnostics',
        skills: {
            locate: 'Locate Information',
            integrate: 'Integrate and Interpret',
            reflect: 'Reflect and Evaluate',
            readingComprenhension: 'Reading Comprenhension',
        },
        totalOfQuestions: 'Total of questions',
        totalWords: 'Total words',
        textWordsNumber: 'Text words number',
        diagnosisType: 'Diagnosis type',
        text: 'Text',
        question: 'Question',
        to: 'to',
        grade: 'grade',
        seconds: 'seconds',
        second: 'second',
        minutes: 'minutes',
        minute: 'minute',
    },
    texts: {
        assignLecture: 'Choose the most adecuated lecture for your course',
        tooltips: {
            diagnosesState: {
                completed: 'Completed evaluation',
                inProgress: 'Pending evaluation',
            },
        },
    },
    tables: {
        evaluationsList: {
            labels: {
                section: 'Segment',
                initial: 'Diagnostic evaluation',
                middle: 'Progressive evaluation',
                final: 'Closing evaluation',
            },
        },
        diagnosisResults: {
            labels: {
                totalWords: 'Total words',
                totalErrors: 'Total errors',
                correctWords: 'Correct words',
                correctWordsPerMinute: 'Correct words per minute',
                readingSpeed: 'Reading speed',
                readingQuality: 'Reading quality',
            }
        },
        assignedEvaluations: {
            labels: {
                assignedText: 'Assigned text',
                evaluation: 'Diagnostic',
                state: 'Progress',
                assignedTo: 'Assigned to',
                progress: 'Progress',
                course: 'Class',
                startDate: 'Start Date',
                endDate: 'End date',
                actions: 'Actions',
                student: 'Student',
                firstName: 'First name',
                lastName: 'Last name',
                tier: 'Tier',
                beeStage: 'BeeReaders stage',
            },
        },
        studentsList: {
            labels: {
                student: 'Student',
                state: 'Status',
                tier: 'Tier',
                actions: 'Actions',
                beeStage: 'BeeReaders Stage',
            },
        },
        results: {
            labels: {
                wordsPerMinute: 'Words per minute',
                correctWordsPerMinute: 'Correct words per minute',
                readingSpeed: 'Reading speed',
                lectureQuality: 'Reading quality',
            },
        },
    },
    inputs: {
        labels: {
            evaluationText: 'Evaluation text',
            evaluationType: 'Evaluation type',
            state: 'State',
            course: 'Class',
            evaluation: 'Diagnostic',
            assignTo: 'Assign to',
            filterTextsByGrade: "Filter texts by the grade"
        },
        placeholders: {
            chooseEvaluationText: 'Choose an evaluation text',
            chooseEvaluationType: 'Choose an evaluation type',
            chooseEvaluationState: 'Choose an evaluation state',
            chooseCourse: 'Choose a course',
        },
        filters: {
            evaluationState: {
                all: 'Show all',
            },
            course: {
                showAll: 'All courses',
            },
            utils: {
                all: 'All',
            },
        },
    },
    actions: {
        evaluation: {
            assign: 'Assign evaluation',
            edit: 'Edit evaluation',
            delete: 'Delete evaluation',
            update: 'Update evaluation',
            activate: 'Activate evaluation',
            selectFontSize: 'Select the text font size',
            markAs: 'Mark as',
        },
        lecture: {
            assign: 'Assign lecture',
            edit: 'Edit lecture',
            delete: 'Delete lecture',
            update: 'Update lecture',
            activate: 'Activate lecture',
        },
        export: 'Export',
        exportExcel: 'Export to Excel',
        exportPdf: 'Export to PDF',
        assign: 'Assign',
        view: 'See',
        evaluate: 'Evaluate',
        confirmation: {
            delete: "Do you confirm that you want to delete this evaluation? You won't be able to get it back later.",
            assign: 'Are you sure do you want to assign this evaluation? You can delete after',
        },
    },
    errors: {
        codes: {
            700: 'Maximum number of evaluations reached',
            701: 'Level is already assigned',
            702: 'Evaluation has answers',
            703: 'Evaluation does not exists',
            default: 'An error has occurred. Please try again later',
        },
    },
}
