export enum DateOptionsEnum {
    CUSTOM_DATE = 0,
    LAST90DAYS = 90,
    LAST30DAYS = 30,
    LAST7DAYS = 7,
}

export type DateOption = {
    id: number
    value: number
    dateOption: DateOptionsEnum
    text: string
}
