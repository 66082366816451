<template>
    <div class="text-center border-radius-3">
        <v-dialog v-model="visible" width="500" content-class="border-radius-3">
            <v-card
                justify="center"
                align="center"
                class="border-radius-3 pa-5"
                elevation="1"
                color="#fff"
            >
                <v-card-title
                    :class="`headline text-center w-100 d-flex justify-center align-center text-h4 ${colorTitle}`"
                >
                    {{ title }}
                </v-card-title>
                <v-card-text :class="`subtitle ${colorTitle} pt-7 pb-7`">
                    {{ text }}
                </v-card-text>

                <v-card-actions
                    :class="
                        'px-10 py-5 d-flex align-center ' +
                        ((modalType === 'confirm' || modalType === 'loadError' ) ? 'justify-space-around' : 'justify-center ')
                    "
                >
                    <v-btn
                        color="secondary"
                        class="px-16 py-5 white--text"
                        rounded
                        outlined
                        @click="hide"
                        v-if="modalType === 'confirm'"
                    >
                        {{ $t('button_no') }}
                    </v-btn>
                    <v-btn
                        color="secondary"
                        class="px-16 py-5 white--text"
                        rounded
                        :loading="loading"
                        @click="actionConfirm"
                        v-if="modalType === 'confirm'"
                    >
                        {{ $t('button_yes') }}
                    </v-btn>
                    <v-btn
                        color="secondary"
                        class="px-16 py-5 white--text"
                        v-if="modalType !== 'confirm' && modalType !== 'loadError' && modalType !== 'accept' "
                        rounded
                        :disabled="loading"
                        @click="hide"
                    >
                        {{ $t('button_close') }}
                    </v-btn>
                    <v-btn
                        color="secondary"
                        class="px-16 py-5 white--text"
                        v-if="modalType === 'loadError'"
                        rounded
                        outlined
                        @click="goBack"
                    >
                        {{ $t('go_back') }}
                    </v-btn>
                    <v-btn
                        color="secondary"
                        class="px-16 py-5 white--text"
                        v-if="modalType === 'loadError'"
                        rounded
                        @click="reload"
                    >
                        {{ $t('button_accept') }}
                    </v-btn>
                    <v-btn
                        color="secondary"
                        class="px-16 py-5 white--text"
                        v-if="modalType === 'accept'"
                        rounded
                        @click="actionConfirm"
                    >
                        {{ $t('button_accept') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/extensions
import Modal from '@/plugins/modal.js'

export default {
    data() {
        return {
            // variable that shows/hides modal
            visible: false,
            title: '',
            text: '',
            modalType: '',
            modalIcon: '',
            cssTitle: '',
            resolve: null,
            reject: null,
            onConfirm: null,
            loading: false,
        }
    },
    methods: {
        hide() {
            this.visible = false
        },
        confirm(title, message, callback) {
            this.visible = true
            this.title = title || this.$t('msg_confirm-default')
            this.text = message
            this.modalType = 'confirm'
            this.onConfirm = callback
        },
        show(params) {
            this.visible = true
            this.title = params.title || this.$t('msg_info')
            this.text = params.text || this.$t('msg_confirm-default')
            this.modalType = params.modalType || 'confirm'
        },
        error(text) {
            this.visible = true
            this.title = this.$t('msg_error')
            this.text = text
            this.modalType = 'error'
        },
        warning(text) {
            this.visible = true
            this.title = this.$t('msg_warning')
            this.text = text
            this.modalType = 'warning'
        },
        info(text) {
            this.visible = true
            this.title = this.$t('msg_info')
            this.text = text
            this.modalType = 'info'
        },
        accept(title, text, callback) {
            this.visible = true
            this.title = title
            this.text = text
            this.modalType = 'accept'
            this.onConfirm = callback
        },
        success(text) {
            this.visible = true
            this.title = this.$t('msg_success')
            this.text = text
            this.modalType = 'success'
        },
        agree() {
            this.resolve(true)
            this.visible = false
        },
        cancel() {
            this.resolve(false)
            this.visible = false
        },
        loadError(text = null) {
            this.visible = true
            this.title = this.$t('msg_load_error')
            this.text = text || this.$t('txt_load_error')
            this.modalType = 'loadError'
        },
        reload() {
            this.visible = false
            this.$router.go()
        },
        goBack() {
            this.visible = false
            this.$router.go(-1)
        },
        async actionConfirm(){
            this.loading = true
            await this.onConfirm()
            this.visible = false
            this.loading = false
        }
    },
    computed: {
        colorTitle() {
            return 'primary--text'
        },
        icon() {
            switch (this.modalType) {
                case 'error':
                    return 'mdi mdi-close-circle'
                case 'warning':
                    return 'mdi mdi-alert'
                case 'success':
                    return 'mdi mdi-checkbox-marked-circle'
                case 'info':
                    return 'mdi mdi-alert-circle'
                case 'accept':
                    return 'mdi mdi-alert-circle'
                default:
                    return ''
            }
        },
    },
    beforeMount() {
        Modal.EventBus.$on('show', (params) => {
            this.show(params)
        })

        Modal.EventBus.$on('error', (params) => {
            this.error(params)
        })

        Modal.EventBus.$on('warning', (params) => {
            this.warning(params)
        })

        Modal.EventBus.$on('info', (params) => {
            this.info(params)
        })

        Modal.EventBus.$on('success', (params) => {
            this.success(params)
        })

        Modal.EventBus.$on('loadError', (params) => {
            this.loadError(params)
        })

        Modal.EventBus.$on('confirm', (title, message, callback) => {
            return this.confirm(title, message, callback)
        })

        Modal.EventBus.$on('accept', (title, message, callback) => {
            return this.accept(title, message, callback)
        })
    },
}
</script>

<style scoped>
.subtitle {
    font-size: 25px;
    font-weight: lighter;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    line-height: 5vh;
}
.modal-wrapper {
    position: fixed;
    width: 70vh;
    z-index: 1000;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.content-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 70000;
    width: 100vw;
    height: 100vh;
}
.modal-buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
}
.modal-button {
    flex-grow: 1;
}
.br-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 60000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.281) !important;
}

.modal-body-text {
    font-size: 3vh;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .modal-wrapper {
        position: fixed;
        z-index: 1000;
        width: 95%;
        margin: 0vh;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .modal-wrapper {
        position: fixed;
        width: 70vh;
        z-index: 1000;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .modal-wrapper {
        position: fixed;
        width: 70vh;
        z-index: 1000;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .modal-wrapper {
        position: fixed;
        width: 70vh;
        z-index: 1000;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .modal-wrapper {
        position: fixed;
        width: 70vh;
        z-index: 1000;
    }
}
</style>
