export default {
    skills: 'Habilidades',
    level_system: 'Sistema de nivelación Beereaders',
    top_student_full_list: 'Lista completa de los estudiantes',
    tier_text: ['Nivel 1: 61%-100%', 'Nivel 2: 41%-60%', 'Nivel 3: 0%-40%'],
    full_profile: 'Perfil completo',
    reading_time: 'Tiempo de lectura',
    reads: 'Lecturas',
    currently_reading: 'Actualmente leyendo',
    compared_performance: 'Comparación de rendimiento',
    performance_msg: '{0} esta por encima del {1}% de la clase',
    abilityOne: {
        title: 'Localizar u obtener información.',
        desc:
            'Estás en un nivel <strong> {0} </strong> de lectura, te invitamos a seguir practicando para mantener tu capacidad de compresión.',
    },
    abilityTwo: {
        title: 'Integrar e interpretar.',
        desc:
            'Estás en un nivel <strong> {0} </strong> de lectura, te invitamos a reforzar tu competencia lectora.',
    },
    abilityThree: {
        title: 'Reflexionar y valorar.',
        desc:
            'Estás en un nivel <strong> {0} </strong> de lectura, te invitamos a desarrollar y mejorar tu competencia lectora.',
    },
    abilityLevels: ['Inicial', 'Intermedio', 'Avanzado'],
    reading_comprehension: 'Comprensión lectora',
    top_student_performence_data: 'Rendimiento lector del estudiante',
    tier_group: 'Grupo de nivel',
    sub_skill: 'Sub habilidad',
    most_descended_students: 'Estudiantes más descendidos',
    most_promoted_students: 'Estudiantes más ascendidos',
    students_list: 'Listado de estudiantes',
    monthly_report_version: "Reporte mensual Version 1.2"
}
