export default {
    quiz_score: 'Quiz Score Tier',
    placement_test: 'Initial & Most Recent Placement Test',
    compreshion_skills: 'Comprehension Skills',
    comprehesion_skills: [
        'Locate explicit character traits',
        'Locate explicit characteristics of places',
        'Identify feelings and / or emotions',
    ],
    simple_comprehesion_skills: [
        'Find & Locate',
        'Integrate & Interpret',
        'Reflect & Evaluate',
    ],
    short_comprehesion_skills: [
        'LOC',
        'IN',
        'RV',
    ],
    quiz_card_title: 'My Class by Tier (Quiz Score)',
    initial_test_card: 'Initial Placement Test',
    lastest_test_card: 'Most Recent Placement Test',
    highest_comprehesion_card: 'Highest Comprehension Skills',
    lowest_comprehesion_card: 'Lowest Comprehension Skills',
}
