import store from '../../store'

export default async (to: any, from: any, next: any) => {
    const token = store.state.token
    if (token != null && token != '') {
        next()
    } else {
        store.dispatch('clearSession').then(() => {
            setTimeout(() => {
                window.location.href = process.env.VUE_APP_BEEREADERS_LOGIN_URL
            }, 100)
        })
    }
}
