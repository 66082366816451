export default {
    accuracy: 'Accuracy',
    activate_session: 'Activate Session',
    add_new_session: 'Add New Session',
    all_tiers: 'All tiers',
    all_tiers_global: 'All classes',
    avg_reading_time: 'Avg. Reading Time',
    avg_number_texts_read: 'Avg. Number of Texts Read',
    back_to_sessions: 'Back to Sessions & Data',
    cancel: 'Cancel',
    compared_performance: 'Compared Performance',
    confirm: 'Confirm',
    continue: 'Continue',
    correct: 'Correct',
    currently_reading: 'Currently Reading',
    ending_time: 'End time',
    find_locate: 'Locate information',
    incorrect: 'Incorrect',
    focus_skill: 'Focus skill',
    group: 'Group',
    groups_by_tier: 'Groups by tier',
    instructional_goal: 'Instructional goal',
    integrate_interpret: 'Integrate & Interpret',
    level: 'Level',
    live_session: 'Live Session',
    live_session_tab: 'Live <br/>Session',
    my_students: 'My Students',
    next_step: 'Next step',
    no_data: 'There is no data',
    no_students_data: 'There are no active students in this class',
    number_groups: 'Number of Groups',
    number_short_texts: 'Number of Short Texts',
    number_subgroups: 'Number of Sub-groups',
    performance_genre: {
        female: 'her',
        male: 'his',
    },
    performance_measure: {
        low: 'lower',
        high: 'higher',
    },
    performance_measure_student: {
        low: 'bottom',
        high: 'top',
    },
    performance_text: `On average, your students' skill performance was {percent}% {reference} in this session.`,
    performance_text_student: '{name} is in the {reference},{percent}% of {genre} classroom',
    period: 'Period',
    periods: [
        '7 Days',
        '30 Days',
        '90 Days',
    ],
    reading_time: 'Reading Time',
    reflect_valuate: 'Reflect & Evaluate',
    repeat: 'Repeat',
    repeat_options: [
        'Once a week for 1 month',
        'Once a week for 2 months'
    ],
    select_class: 'Select the class',
    select_group_tier: 'Select the group by tier',
    select_session: 'Select the session',
    select_start_date: 'Select the start date',
    session_activated: 'Session activated!',
    session_live: 'Session live',
    sessions: 'Sessions',
    sessions_data: 'Sessions & Data',
    sessions_data_tab: 'Sessions &<br/> Data',
    sessions_dates: 'Sessions Dates',
    sessions_number: [
        '1 Group',
        '2 Groups',
        '3 Groups',
        '4 Groups',
        '5 Groups',
        '6 Groups',
        '7 Groups',
        '8 Groups',
    ],
    sessions_subnumber: [
        'Classroom',
    ],
    set_group: 'Set group',
    set_new_session: 'Set new session',
    set_up: 'Set Up',
    set_up_groups: 'Set up groups',
    set_up_successfully: 'has been set up successfully.',
    short_texts: 'Short texts',
    skills_options: [
        'Recommended by Beereaders',
        'Locate Information',
        'Integrate & Interpret',
        'Reflect & Evaluate',
    ],
    starting_time: 'Starting time',
    student_attendance_at_session: 'Student attendance for this session',
    students: 'Students',
    student_students: ['Student', 'students'],
    texts_read: 'Texts Read',
    tier_text: ['Tier 1: 61%-100%', 'Tier 2: 41%-60%', 'Tier 3: 0%-40%'],
    well_done: 'Well Done!',
}
