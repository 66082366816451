import ICommit from '@/store/contracts/commit'
import ChallengeConfigService from '@/services/challengeConfig'

const getDefaultState = () => {
    return {
        config: {
            typography: null,
            fontSize: null,
            soundVol: null,
        },
        questions: [],
        challengeStage: null,
        evaluationId: null,
        testId: null,
        testNuncaAplicado: false,
        controlsTexts: [],
        textName: '',
        tiempoEmpleadoLectura: (new Date()).getTime(),
        tiempoEmpleadoReLectura: (new Date()).getTime(),
        challengeCategories: [],
        //utils
        showQuestion: false,
        //pagination
        challengePage: 1,
    }
}

const ChallengeModule = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        config: (state: any, value: any) => {
            if (value) {
                state.config.typography = value.typography
                state.config.fontSize = value.fontSize
                state.config.soundActive = false
                state.config.soundVol = '0'
            }
        },
        questions: (state: any, value: any) => {
            state.questions = value
        },
        challengeStage: (state: any, stage: any) => {
            state.challengeStage = stage
        },
        evaluationId: (state: any, value: any) => {
            state.evaluationId = value
        },
        testId: (state: any, value: any) => {
            state.testId = value
        },
        testNuncaAplicado: (state: any, value: any) => {
            state.testNuncaAplicado = value
        },
        controlsTexts: (state: any, value: any) => {
            state.controlsTexts = value
        },
        textName: (state: any, value: any) => {
            state.textName = value
        },
        challengeCategories: (state: any, value: any) => {
            state.challengeCategories = value
        },
        updateConfig: (state: any, userId: number | string) => {
            ChallengeConfigService.updateConfig(
                userId,
                state.config.typography,
                state.config.fontSize,
            )
                .then((response: any) => console.info(response))
                .catch((error: any) => console.error(error))
        },
    },
    actions: {
        setConfig({ commit }: ICommit, value: any) {
            commit('config', value)
        },
        setQuestions({ commit }: ICommit, value: any) {
            commit('questions', value)
        },
        setChallengeStage({ commit }: ICommit, value: any) {
            commit('challengeStage', value)
        },
        setEvaluationId({ commit }: ICommit, value: any) {
            commit('evaluationId', value)
        },
        setFontFamily({ state }: any, value: any) {
            state.config.typography = value
        },
        setFontSize({ state }: any, value: any) {
            state.config.fontSize = value
        },
        setTestId({ commit }: ICommit, value: any) {
            commit('testId', value)
        },
        setTestNuncaAplicado({ commit }: ICommit, value: any) {
            commit('testNuncaAplicado', value)
        },
        resetChallengeState({ state }: any) {
            Object.assign(state, getDefaultState())
        },
        setChallengeData({ commit }: ICommit, data: any) {
            commit('controlsTexts', data.mdTestDTO.mdTextosdecontrols)
            commit('textName', data.mdTestDTO.nombre)
            commit('testNuncaAplicado', data.testNuncaAplicado)
            commit('evaluationId', data.mdEvaluacionId)
            commit('testId', data.mdTestDTO.id)
            commit('challengeStage', 'runChallenge')
            commit('questions', data.mdTestDTO.mdTestPreguntases)
        },
        setControlsTexts({ commit }: ICommit, value: any) {
            commit('controlsTexts', value)
        },
        setTextName({ commit }: ICommit, value: any) {
            commit('textName', value)
        },
        resetTimers({ state }: any) {
            state.tiempoEmpleadoReLectura = state.tiempoEmpleadoLectura = new Date().getTime()
        },
        ShowQuestion({ state }: any) {
            state.showQuestion = !state.showQuestion
        },
        setChallengeCategories({ commit }: ICommit, value: any) {
            commit('challengeCategories', value)
        },
        updateConfig({ commit }: ICommit, userId: number | string) {
            commit('updateConfig', userId)
        },
        increasePage({ state }: any) {
            state.challengePage += 1
        },
        decreasePage({ state }: any) {
            state.challengePage -= 1
        },
    },
}

export default ChallengeModule
