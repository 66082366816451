export default {
    skills: 'Skills',
    level_system: 'Beereaders Leveling System',
    top_student_full_list: 'Top students full list',
    tier_text: ['Tier 1: 61%-100%', 'Tier 2: 41%-60%', 'Tier 3: 0%-40%'],
    full_profile: 'Complete profile',
    reading_time: 'Reading time',
    reads: 'Books read',
    currently_reading: 'Currently reading',
    compared_performance: 'Compared performance',
    performance_msg: '{0} is in the top {1}% of the class',
    abilityOne: {
        title: 'Locate or obtain information.',
        desc:
            'You are at a <strong> {0} </strong> level of reading, we encourage you to continue practicing to continue strengthening your comprehension.',
    },
    abilityTwo: {
        title: 'Integrate and interpret.',
        desc:
            'You are at a <strong> {0} </strong> level of reading, we encourage you to strengthen your reading skills.',
    },
    abilityThree: {
        title: 'Reflect and evaluate.',
        desc:
            'You are at a <strong> {0} </strong> level of reading, we invite you to develop and improve your reading skills.',
    },
    abilityLevels: ['initial', 'intermediate', 'advanced'],
    reading_comprehension: 'Reading comprehension',
    top_student_performence_data: 'Student reading performance',
    tier_group: 'Tier group',
    sub_skill: 'Sub-Skill',
    most_descended_students: 'Lowest Performing Students',
    most_promoted_students: 'Highest Performing Students',
    students_list: 'Student roster',
    monthly_report_version: "Monthly Report Version 1.2"
}
