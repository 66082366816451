var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mx-2"},[_c('div',{class:_vm.classBar},[_c('div',{class:`border-radius-5 d-flex justify-center align-center transition-all ${
                _vm.progress ? 'px-2' : 'px-1'
            }`,style:({
                backgroundColor: _vm.color,
                width: `${_vm.progress}%`,
                height: '100%',
                maxWidth: '100%',
            })},[(_vm.progress >= 20)?_c('span',{class:_vm.classValueMinTwenty,style:({ maxWidth: '100px;' })},[_vm._t("default")],2):_vm._e()]),(_vm.progress < 20)?_c('span',{class:_vm.classValueMaxTwenty},[_vm._v(" "+_vm._s(`${_vm.progress}${_vm.symbol}`)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }