<template>
    <v-row class="grey--text darken-2 font-size-14">
        <v-col cols="4">{{ getProperty("code") }}</v-col>
        <v-col cols="2">{{ getProperty("accuracy") }}%</v-col>
        <v-col cols="2">{{ getProperty("corrects") }}</v-col>
        <v-col cols="2">{{ getProperty("incorrects") }}</v-col>
        <v-col cols="2">{{ getProperty("total") }}</v-col>
    </v-row>
</template>

<script>
    import {TeksBody} from "../../data/defaultInterfaces";

    export default {
        name: "AccuracyTeksRow",
        props: {
            rowData: {
                type: Object,
                default: () => ({
                        ...TeksBody
                    }
                )
            }
        },
        methods: {
            getProperty(name = "") {
                return (this.rowData[name]).toString() || ""
            }
        }
    }
</script>

<style scoped>

</style>
