export default {
    beereaders_level: 'Nivel de Beereaders',
    complexity: 'Nivel de complejidad',
    description: 'Descripción',
    spanish_lexile: 'Lexile Español',
    fountas_pinnel: 'Fountas y Pinnell SEL',
    leveling_system_params: [
        {
            grade: '1er básico',
            level: 1,
            description:
                'Extrae información explícita de textos breves y continuos, con baja carga semántica, adecuados a su grado.',
            lexile: '420-820',
            fountas_pinnell: 'E',
            complexity_level: 'Bajo ',
        },
        {
            grade: '1er básico',
            level: 2,
            description:
                'Extrae información explícita de textos breves y continuos, con baja carga semántica, adecuados a su grado.',
            lexile: '420-820',
            fountas_pinnell: 'E',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '1er básico',
            level: 3,
            description:
                'Extrae información explícita de textos breves y continuos, con baja carga semántica, adecuados a su grado.',
            lexile: '420-820',
            fountas_pinnell: 'F',
            complexity_level: 'Medio',
        },
        {
            grade: '1er básico',
            level: 4,
            description:
                'Localiza e interpreta información específica de textos breves y continuos, con baja carga semántica, adecuados a su grado.',
            lexile: '420-820',
            fountas_pinnell: 'F',
            complexity_level: 'Medio alto',
        },
        {
            grade: '1er básico',
            level: 5,
            description:
                'Localiza e interpreta información específica de textos breves, continuos, con baja carga semántica, adecuados a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'G',
            complexity_level: 'Alto',
        },
        {
            grade: '1er básico',

            level: 6,
            description:
                'Localiza e interpreta información específica de textos breves, continuos, con baja carga semántica, adecuados a su grado. Además, reconoce algunas tipologías textuales básicas. ',
            lexile: '420-820',
            fountas_pinnell: 'G',
            complexity_level: 'Superior',
        },
        {
            grade: '2do básico',
            level: 7,
            description:
                'Localiza e infiere información específica de textos breves, continuos, con baja carga semántica, adecuado a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'H',
            complexity_level: 'Bajo ',
        },
        {
            grade: '2do básico',
            level: 8,
            description:
                'Localiza e infiere información específica de textos breves, continuos, con baja carga semántica, adecuado a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'H',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '2do básico',
            level: 9,
            description:
                'Localiza e infiere información específica de textos breves, continuos, con baja carga semántica, adecuado a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'I',
            complexity_level: 'Medio',
        },
        {
            grade: '2do básico',
            level: 10,
            description:
                'Localiza e infiere información específica de textos breves, continuos, con baja carga semántica, adecuado a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'I',
            complexity_level: 'Medio alto',
        },
        {
            grade: '2do básico',
            level: 11,
            description:
                'Localiza e infiere información específica de textos breves, continuos, con baja carga semántica, adecuado a su grado. Reconocen algunas tipologías textuales.',
            lexile: '420-820',
            fountas_pinnell: 'J',
            complexity_level: 'Alto',
        },
        {
            grade: '2do básico',
            level: 12,
            description:
                'Localiza e infiere información específica de textos breves, continuos, con baja carga semántica, adecuado a su grado. Reconocen mensaje o enseñanza del texto. ',
            lexile: '420-820',
            fountas_pinnell: 'J',
            complexity_level: 'Superior',
        },
        {
            grade: '3ero básico',
            level: 13,
            description:
                'Localiza e infiere información específica de textos breves, continuos o discontinuos, con baja carga semántica, adecuados a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'K',
            complexity_level: 'Bajo ',
        },
        {
            grade: '3ero básico',
            level: 14,
            description:
                'Localiza e infiere información específica de textos breves, continuos o discontinuos, con baja carga semántica, adecuados a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'K',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '3ero básico',
            level: 15,
            description:
                'Localiza e infiere información específica de textos breves, continuos o discontinuos, con baja carga semántica, adecuados a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'L',
            complexity_level: 'Medio',
        },
        {
            grade: '3ero básico',
            level: 16,
            description:
                'Localiza e infiere información específica de textos breves, continuos o discontinuos, con baja carga semántica, adecuados a su grado. Reconocen algunas tipologías textuales.',
            lexile: '420-820',
            fountas_pinnell: 'L',
            complexity_level: 'Medio alto',
        },
        {
            grade: '3ero básico',
            level: 17,
            description:
                'Localiza e infiere información específica de textos breves, continuos o discontinuos, con baja carga semántica, adecuados a su grado. Reconocen mensaje o enseñanza del texto. ',
            lexile: '420-820',
            fountas_pinnell: 'M',
            complexity_level: 'Alto',
        },
        {
            grade: '3ero básico',
            level: 18,
            description:
                'Localiza e infiere información específica de textos breves, continuos o discontinuos, con baja carga semántica, adecuados a su grado. Relacionan información del texto con la vida cotidiana. ',
            lexile: '420-820',
            fountas_pinnell: 'M',
            complexity_level: 'Superior',
        },
        {
            grade: '4to básico',
            level: 19,
            description:
                'Localiza e infiere información específica de textos breves y/o intermedios, continuos y/o discontinuos, con  carga semántica intermedia y párrafos de extensión moderada, adecuados a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'N',
            complexity_level: 'Bajo ',
        },
        {
            grade: '4to básico',
            level: 20,
            description:
                'Localiza e infiere información específica de textos breves y/o intermedios, continuos y/o discontinuos, con  carga semántica intermedia y párrafos de extensión moderada, adecuados a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'N',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '4to básico',
            level: 21,
            description:
                'Localiza e infiere información específica de textos breves y/o intermedios, continuos y/o discontinuos, con carga semántica intermedia y párrafos de extensión moderada, adecuados a su grado. Reconocen algunas tipologías textuales.',
            lexile: '420-820',
            fountas_pinnell: 'O',
            complexity_level: 'Medio',
        },
        {
            grade: '4to básico',
            level: 22,
            description:
                'Localiza e infiere información específica de textos breves y/o intermedios, continuos y/o discontinuos, con carga semántica intermedia y párrafos de extensión moderada, adecuados a su grado. Reconocen el propósito comunicativo.',
            lexile: '420-820',
            fountas_pinnell: 'O',
            complexity_level: 'Medio alto',
        },
        {
            grade: '4to básico',
            level: 23,
            description:
                'Localiza e infiere información específica de textos breves y/o intermedios, continuos y/o discontinuos, con carga semántica intermedia y párrafos de extensión moderada, adecuados a su grado. Reconocen características de forma y/o contenido.',
            lexile: '420-820',
            fountas_pinnell: 'P',
            complexity_level: 'Alto',
        },
        {
            grade: '4to básico',
            level: 24,
            description:
                'Localiza e infiere información específica de textos breves y/o intermedios, continuos y/o discontinuos, con carga semántica intermedia y párrafos de extensión moderada, adecuados a su grado. Reconocen mensaje o enseñanza del texto.',
            lexile: '420-820',
            fountas_pinnell: 'P',
            complexity_level: 'Superior',
        },
        {
            grade: '5to básico',
            level: 25,
            description:
                'Localiza e infiere información específica de textos de extensión baja o intermedio, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada, adecuados a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'Q',
            complexity_level: 'Bajo ',
        },
        {
            grade: '5to básico',
            level: 26,
            description:
                'Localiza e infiere información específica de textos de extensión baja o intermedio, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada, adecuados a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'Q',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '5to básico',
            level: 27,
            description:
                'Localiza e infiere información específica de textos de extensión baja o intermedio, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada de adecuados a su grado. Reconoce variadas tipologías textuales. ',
            lexile: '420-820',
            fountas_pinnell: 'R',
            complexity_level: 'Medio',
        },
        {
            grade: '5to básico',
            level: 28,
            description:
                'Localiza e infiere información específica de textos de extensión baja o intermedio, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada de adecuados a su grado. Reconoce el  propósito comunicativo del texto.',
            lexile: '420-820',
            fountas_pinnell: 'R',
            complexity_level: 'Medio alto',
        },
        {
            grade: '5to básico',
            level: 29,
            description:
                'Localiza e infiere información específica de textos de extensión baja o intermedio, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada de adecuados a su grado. Reconoce mensaje o enseñanza del texto.',
            lexile: '420-820',
            fountas_pinnell: 'S',
            complexity_level: 'Alto',
        },
        {
            grade: '5to básico',
            level: 30,
            description:
                'Localiza e infiere información específica de textos de extensión baja o intermedio, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada de adecuados a su grado. Relaciona la información del texto con la vida cotidiana.',
            lexile: '420-820',
            fountas_pinnell: 'S',
            complexity_level: 'Superior',
        },
        {
            grade: '6to básico',
            level: 31,
            description:
                'Localiza e infiere información específica de textos breves e intermedios, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada, adecuados a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'T',
            complexity_level: 'Bajo ',
        },
        {
            grade: '6to básico',
            level: 32,
            description:
                'Localiza e infiere información específica de textos breves e intermedios, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada, adecuados a su grado. ',
            lexile: '420-820',
            fountas_pinnell: 'T',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '6to básico',
            level: 33,
            description:
                'Localiza e infiere información específica de textos breves e intermedios, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada de adecuados a su grado. Reconoce propósito comunicativo en el texto.',
            lexile: '420-820',
            fountas_pinnell: 'U',
            complexity_level: 'Medio',
        },
        {
            grade: '6to básico',
            level: 34,
            description:
                'Localiza e infiere información específica de textos breves e intermedios, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada de adecuados a su grado. Reconoce el ámbito, mundo o cultura al que se asocia el texto.',
            lexile: '420-820',
            fountas_pinnell: 'U',
            complexity_level: 'Medio alto',
        },
        {
            grade: '6to básico',
            level: 35,
            description:
                'Localiza e infiere información específica de textos breves e intermedios, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada de adecuados a su grado. Reconoce el mensaje o enseñanza del texto.',
            lexile: '420-820',
            fountas_pinnell: 'V',
            complexity_level: 'Alto',
        },
        {
            grade: '6to básico',
            level: 36,
            description:
                'Localiza e infiere información específica de textos breves e intermedios, continuos y/o discontinuos, con  carga semántica media y párrafos de extensión moderada, adecuados a su grado. Relaciona la información del texto con la vida cotidiana.',
            lexile: '420-820',
            fountas_pinnell: 'V',
            complexity_level: 'Superior',
        },
        {
            grade: '7mo básico',
            level: 37,
            description:
                'Localiza e infiere información específica de textos breves, intermedios o extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta.  ',
            lexile: '740-1010',
            fountas_pinnell: 'W',
            complexity_level: 'Bajo ',
        },
        {
            grade: '7mo básico',
            level: 38,
            description:
                'Localiza e infiere información específica de textos breves, intermedios o extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta.  Reconoce el propósito comunicativo del texto.',
            lexile: '740-1010',
            fountas_pinnell: 'W',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '7mo básico',
            level: 39,
            description:
                'Localiza e infiere información específica de textos breves, intermedios o extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta.  Reconoce el ámbito mundo o cultura al que se asocia un texto.',
            lexile: '740-1010',
            fountas_pinnell: 'W',
            complexity_level: 'Medio',
        },
        {
            grade: '7mo básico',
            level: 40,
            description:
                'Localiza e infiere información específica de textos breves, intermedios o extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta.  Reconoce valores en personas o personajes.',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'Medio alto',
        },
        {
            grade: '7mo básico',
            level: 41,
            description:
                'Localiza e infiere información específica de textos breves, intermedios o extensos. Trabajan textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta. Evalúa la opinión de una persona o personaje.',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'Alto',
        },
        {
            grade: '7mo básico',
            level: 42,
            description:
                'Localiza e infiere información específica de textos breves, intermedios o extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta.  Relaciona la información del texto con la vida cotidiana.',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'Superior',
        },
        {
            grade: '8vo básico',
            level: 43,
            description:
                'Localiza e infiere información específica de textos breves, intermedios o extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta.  ',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'Bajo ',
        },
        {
            grade: '8vo básico',
            level: 44,
            description:
                ' Localiza e infiere información específica de textos breves, intermedios o extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta. Reconoce variadas tipologías textuales.',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '8vo básico',
            level: 45,
            description:
                'Localiza e infiere información específica de textos breves, intermedios o extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta. Reconoce variadas tipologías textuales.',
            lexile: '740-1010',
            fountas_pinnell: 'X',
            complexity_level: 'Medio',
        },
        {
            grade: '8vo básico',
            level: 46,
            description:
                'Localiza e infiere información específica de textos breves, intermedios o extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta. Reconoce propósito comunicativo. ',
            lexile: '740-1010',
            fountas_pinnell: 'Y',
            complexity_level: 'Medio alto',
        },
        {
            grade: '8vo básico',
            level: 47,
            description:
                'Localiza e infiere información específica de textos breves, intermedios o extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta. Reconoce ámbito, mundo o cultura al que se asocia un texto.',
            lexile: '740-1010',
            fountas_pinnell: 'Y',
            complexity_level: 'Alto',
        },
        {
            grade: '8vo básico',
            level: 48,
            description:
                'Localiza e infiere información específica de textos breves, intermedios o extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica media alta. Predice efectos o consecuencias de acciones.',
            lexile: '740-1010',
            fountas_pinnell: 'Y',
            complexity_level: 'Superior',
        },
        {
            grade: '1er medio',
            level: 49,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta.  Relaciona y valora múltiples tipologías textuales.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Bajo ',
        },
        {
            grade: '1er medio',
            level: 50,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta. Reconoce propósito comunicativo del texto.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '1er medio',
            level: 51,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta.   Reconoce características de forma y/o contenido.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Medio',
        },
        {
            grade: '1er medio',
            level: 52,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta. Reconoce ámbito, mundo y/o cultura al que se asocia el texto y el principal mensaje o enseñanza que entrega.',
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'Medio alto',
        },
        {
            grade: '1er medio',
            level: 53,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabajan textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta.  Reconoce el principal mensaje o enseñanza y evalúan hechos en un texto.',
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'Alto',
        },
        {
            grade: '1er medio',
            level: 54,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabajan textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta. Reconoce valores en personas y personajes y predicen efectos y/o consecuencias de acciones en un texto. ',
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'Superior',
        },
        {
            grade: '2do medio',
            level: 55,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta.  Reconoce valores en personas y personajes de un texto.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Bajo ',
        },
        {
            grade: '2do medio',
            level: 56,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta.   Evalúa la opinión de una persona o personaje en un texto.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '2do medio',
            level: 57,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta.  Relaciona hechos del texto con el contexto de producción.',
            lexile: '925-1185',
            fountas_pinnell: 'Y',
            complexity_level: 'Medio',
        },
        {
            grade: '2do medio',
            level: 58,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta. Evalúa el argumento de una persona o personaje en un texto.',
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'Medio alto',
        },
        {
            grade: '2do medio',
            level: 59,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta.  Relaciona el texto con la vida cotidiana y contrastan puntos de vista presentados en el texto con visiones de mundo actual. ',
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'Alto',
        },
        {
            grade: '2do medio',
            level: 60,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y/o mixtos, de complejidad y carga semántica alta. Analiza críticamente un personaje y relacionan los hechos del texto con el contexto de producción. ',
            lexile: '925-1185',
            fountas_pinnell: 'Z',
            complexity_level: 'Superior',
        },
        {
            grade: '3ero medio',
            level: 61,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta.  Además, reconoce el propósito comunicativo de un texto.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Bajo ',
        },
        {
            grade: '3ero medio',
            level: 62,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta.  Evalúa el argumento que usa un personaje o persona en el texto.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '3ero medio',
            level: 63,
            description:
                'Localiza e infieren información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta.  Relaciona la información presentada con la vida cotidiana.  ',
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Medio',
        },
        {
            grade: '3ero medio',
            level: 64,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta.  Contrasta puntos de vista presentados en el texto con visiones de mundo actual.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'Medio alto',
        },
        {
            grade: '3ero medio',
            level: 65,
            description:
                'Localiza e infieren información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta.  Explica cómo los recursos presentes en el texto contribuyen a la expresión de ideas.  ',
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'Alto',
        },
        {
            grade: '3ero medio',
            level: 66,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta.  Analiza críticamente a un personaje y relaciona hechos con el contexto de producción.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'Superior',
        },
        {
            grade: '4to medio',
            level: 67,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabajan textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta.  Reconoce ámbito, mundo o cultura con la que se asocia el texto. ',
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Bajo ',
        },
        {
            grade: '4to medio',
            level: 68,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta. Relaciona la información presentada con la vida cotidiana.  ',
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Medio bajo',
        },
        {
            grade: '4to medio',
            level: 69,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta.  Contrasta puntos de vista presentados en el texto con visiones de mundo actual.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z',
            complexity_level: 'Medio',
        },
        {
            grade: '4to medio',
            level: 70,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta.  Explica cómo los recursos presentes en el texto contribuyen a la expresión de ideas.  ',
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'Medio alto',
        },
        {
            grade: '4to medio',
            level: 71,
            description:
                'Localiza e infieren información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta.  Analiza críticamente a un personaje.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'Alto',
        },
        {
            grade: '4to medio',
            level: 72,
            description:
                'Localiza e infiere información específica de textos breves, intermedios y extensos. Trabaja textos continuos, discontinuos y mixtos, de complejidad y carga semántica alta.  Predice los efectos y/o consecuencias de acciones y relacionan hechos con el contexto de producción.',
            lexile: '1050-1335',
            fountas_pinnell: 'Z+',
            complexity_level: 'Superior',
        },
    ],
}
