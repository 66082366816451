export default {
    NOV: 'Novel',
    CUE: 'Story',
    MIT: 'Myth',
    LEY: 'Legend',
    FAB: 'Fable',
    TEA: 'Theater',
    EPI: 'Epistle',
    INF: 'Informative',
    BIO: 'Biography',
    POE: 'Poetry',
    COM: 'Comic',
    ROM: 'Romance',
    TRA: 'Tragedy',
    ART: 'Article',
    CRO: 'Chronicle',
    ENS: 'Essay',
    CAR: 'Epistolary (letter)',
    REC: 'Recipe',
    EPC: 'Epic',
}
