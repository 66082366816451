const defaultState = () => ({
    assignedBooksList: [],
    currentNewAssignment: {
        name: null,
        dates: {
            start: null,
            end: null,
        },
        course: null,
        students: [],
        book: null,
    }
})

const library = {
    namespaced: true,
    state: defaultState(),
    mutations: {
        assignedBooksList: (state: any, value: any) => {
            state.assignedBooksList = value
        },
        pushToAssignedBooks: (state: any, value: any) => {
            state.assignedBooksList.push(value)
        },
        reset: (state: any) => {
            Object.assign(state, defaultState())
        },
    },
    actions: {
        setAssignedBookList({ commit }: any, value: any) {
            commit('assignedBooksList', value)
        },
        pushToAssignedBooks({ commit }: any, value: any) {
            commit('pushToAssignedBooks', value)
        },
        resetLibraryState({ commit }: any) {
            commit('reset')
        },
    },
}

export default library
