var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-start align-start"},[(!_vm.avatar)?_c('v-sheet',{staticClass:"rounded-circle transition-swing d-flex justify-center align-center",style:({
            width: `${_vm.size}vh`,
            height: `${_vm.size}vh`,
        }),attrs:{"color":_vm.classColor,"rounded":"","shaped":""}},[_c('span',{staticClass:"white--text",style:({ fontSize: _vm.fontSize })},[_vm._v(_vm._s(_vm.iconName))])]):_c('img',{class:'rounded-circle transition-swing',style:({
            width: `${_vm.size}vh`,
            height: `${_vm.size}vh`,
        }),attrs:{"src":`https://assets.beereaders.com/avatar/${_vm.avatar.name}/${_vm.avatar.value}`,"alt":"userAvatar"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }