import home from './home'
import bookReading from './bookReading'
import library from './library'
import stats from './stats'
import reporting from './reporting'
import grades from './grades'
import leveling from '@/i18n/es/leveling'
import resources from '@/i18n/es/resources'
import sessions from './sessions'
import subSkill from '@/i18n/es/subSkill'
import textType from '@/i18n/es/textType'
import categories from '@/i18n/es/categories'
import reports from '@/i18n/es/reports'
import progressSystemLevel from '@/i18n/es/progressSystemLevel'
import assignments from '@/i18n/es/assignments'
import diagnoses from '@/i18n/es/diagnoses'
import evaluate from '@/i18n/es/evaluate'

export default {
    ad_text: ['Estás viendo la nueva versión.', 'Volver a la versión anterior.'],
    title_home: 'Inicio | Beereaders',
    title_library: 'Biblioteca | Beereaders',
    title_quick_stats: 'Estadísticas rápidas | Beereaders',
    title_resource_room: 'Recursos | Beereaders',
    title_session_data: 'Sesiones de trabajo | Beereaders',
    title_skill_reading_level: 'Nivel de habilidad y lectura | Beereaders',
    home_link: 'Inicio',
    library_link: 'Biblioteca',
    quick_stats_link: 'Estadísticas rápidas',
    resource_room_link: 'Recursos',
    session_data_link: 'Sesiones de trabajo',
    skill_reading_level_link: 'Nivel de habilidad y lectura',
    logout: 'Cerrar sesión',
    grade: 'Grado',
    choose_course: 'Seleccionar curso',
    see_full_list: 'Ver lista completa',
    TEACHER: 'Docente',
    courses: 'Cursos',
    course: 'Curso',
    students: 'Estudiantes',
    tier: 'Nivel',
    msg_error: 'Error:',
    msg_warning: '¡Advertencia!',
    msg_info: '¡Información!',
    msg_success: '¡Muy bien!',
    msg_load_error: 'Error al cargar contenido',
    txt_load_error: 'Haz clic en el botón de aceptar para recargar la página y volverlo a intentar',
    general_error_message: 'Ocurrió un error, intenta de nuevo más tarde',
    anonymous: 'Anónimo',
    button_yes: 'Sí',
    button_no: 'No',
    button_close: 'Cerrar',
    button_accept: 'Aceptar',
    to_confirm: 'Confirmar',
    confirm_logout_msg: '¿Estás seguro de cerrar tu sesión?',
    not_data: 'No hay datos para mostrar',
    end_date: 'Fecha final:',
    init_date: 'Fecha inicial:',
    assigned_course: 'Curso asignado:',
    edit_period: 'Editar periodo',
    check_stats: 'Ver estadísticas',
    days_names: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    month_names: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ],
    preview: 'Vista previa',
    start: 'Inicio',
    end: 'Fin',
    save: 'Guardar',
    continue: 'Continuar',
    progress: 'Progreso',
    correct: 'Correctas',
    incorrect: 'Incorrectas',
    percentageCorrectAnswers: '% Preguntas Correctas',
    percentageWrongAnswers: '% Preguntas Incorrectas',
    total: 'Total',
    accuracy: 'Precisión',
    global: 'Global',
    go_back: 'Volver',
    choose_tier: 'Seleccionar nivel',
    level: 'Nivel',
    date_time: 'Fecha y hora',
    subject: 'Asunto:',
    from: 'De: ',
    message: 'Mensaje:',
    starting_date: 'Fecha inicial: ',
    ending_date: 'Fecha final: ',
    date_range: 'Rango de fecha:',
    execute: 'Ejecutar',
    cancel: 'Cancelar',
    select: 'Seleccionar',
    export_csv: 'Exportar',
    print: 'Imprimir',
    delete: 'Eliminar',
    exit: 'Salir',
    close: 'Cerrar',
    not_message: 'Sin mensajes para mostrar',
    select_course: 'Seleccione un curso',
    download: 'Descargar',
    dynamic_language_banner_end: '_es.png',
    title_bookReading: 'Experiencia Lectora | Beereaders',
    about: 'Sobre',
    comments: 'Comentarios',
    no_comment: 'No hay comentarios',
    more_nine_comments: '+9 Comentarios',
    next: 'Siguiente',
    of: 'de',
    true: 'Verdadero',
    false: 'Falso',
    details: 'Detalles',
    pages: 'Páginas',
    general: {
        error_msg: "Ha ocurrido un error. Por favor intertarlo nuevamente. Si el error persiste, pedir apoyo al equipo de soporte",
        loading: 'Cargando...'
    },
    actions: {
        export: 'Exportar'
    },
    home,
    bookReading,
    library,
    stats,
    reporting,
    grades,
    leveling,
    resources,
    sessions,
    subSkill,
    categories,
    textType,
    reports,
    progressSystemLevel,
    assignments,
    diagnoses,
    evaluate
}
