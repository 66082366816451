export default {
  dashboard: {
    title: 'Reportes',
    sectionTitles: {
      generalInformation: 'Información general',
      books: 'Libros',
      challenges: 'Desafíos',
    },
    cards: {
      readingComprehension: 'Comprensión lectora',
      questionsAmountquestionsAmount: 'Cantidad de actividades y preguntas',
      questionsAmount: 'Cantidad de preguntas',
      studentsPerformance: 'Desempeño de estudiantes',
      booksByAssignment: 'Libros por asignación',
      assignedBooksResults: 'Resultado de libros asignados',
      selfLectures: 'Estudiantes que han leído por gusto propio',
      selfLecturesBooks: 'Libros por gusto propio',
      challengeResults: 'Resultados desafíos',
    },
    tooltips: {
      generalInformation: {
        generalInformationTab: 'Esta pestaña muestra los resultados de los estudiantes en los libros asignados, libros de gusto propio y desafíos realizados.',
        readingComprehension: 'Este gráfico muestra la tendencia en la comprensión lectora de los estudiantes en el rango de fechas seleccionado.',
        questionsAmount: 'Esta tabla muestra la cantidad de libros asignados, libros de gusto propio y desafíos realizados, así como el número de respuestas correctas, incorrectas y totales.',
        studentsPerformance: 'Esta tabla muestra los resultados de las habilidades lectoras, nivel de intervención, tipo de lector y etapa Beereaders de cada estudiante.',
        individualInformation: {
          readingComprehension: 'Este gráfico muestra la tendencia en la comprensión lectora de los estudiantes en el rango de fechas seleccionado.',
          questionsAmount: 'Esta tabla muestra la cantidad de libros asignados, libros de gusto propio y desafíos realizados, así como el número de respuestas correctas, incorrectas y totales.',
          studentsPerformance: 'Esta tabla muestra los resultados de las habilidades lectoras, nivel de intervención, tipo de lector y etapa Beereaders de cada estudiante.',
        }
      },
      booksInformation: {
        bookInformationTab: 'Esta pestaña muestra los resultados de los estudiantes en los libros asignados y libros de gusto propio.',
        booksByAssignment: 'Esta tabla muestra los resultados de los libros asignados. Puedes hacer clic en el libro para más detalles.',
        assignedBooksResults: 'Este gráfico de barras muestra los promedios de las habilidades lectoras en los libros asignados.',
        questionsAmount: 'Esta tabla muestra la cantidad de preguntas correctas, incorrectas y totales de todos los libros asignados. Ayuda a interpretar el gráfico de la izquierda.',
        selfLectures: 'Esta tabla muestra los nombres de los estudiantes que han realizado lecturas por gusto propio.',
        individualInformation: {
          booksByAssignment: 'Esta tabla muestra los resultados de los libros asignados. Puedes hacer clic en el libro para más detalles.',
          assignedBooksResults: 'Este gráfico de barras muestra los promedios de las habilidades lectoras en los libros asignados.',
          questionsAmount: 'Esta tabla muestra la cantidad de preguntas correctas, incorrectas y totales de todos los libros asignados. Ayuda a interpretar el gráfico de la izquierda.',
          selfLectures: 'Esta tabla muestra los resultados de los libros leídos por gusto propio del estudiante. Puedes hacer clic en el libro para más información de este.',
        }
      },
      challengesInformation: {
        challengeInformationTab: 'Esta pestaña muestra los resultados de los estudiantes en los desafíos realizados.',
        challengesResults: 'Este gráfico de barras muestra los promedios de las habilidades lectoras en los desafíos realizados.',
        questionsAmount: 'Esta tabla muestra la cantidad de preguntas correctas, incorrectas y totales de todos los desafíos realizados. Ayuda a interpretar el gráfico de la izquierda.',
        studentsPerformance: 'Esta tabla muestra los resultados de las habilidades lectoras y cantidad de desafíos por estudiante.',
      }
    }
  },
  skills: {
    fullName: {
      locate: 'Localizar información',
      integrate: 'Integrar e interpretar',
      reflect: 'Reflexionar y valorar',
      readingTime: 'Tiempo de lectura',
      booksByAssignment: 'Libros por asignación',
      assignedBooksResults: 'Resultado libros asignados',
      selfLectures: 'Estudiantes que han leído por gusto propio',
      challengeResults: 'Resultados desafíos',
    },
    abbrevationName: {
      locate: 'LOC',
      integrate: 'INT',
      reflect: 'RV',
      readingTime: 'TL',
      readingComprehension: 'CL',
    }
  },
  tableHeaders: {
    student: 'Estudiantes',
    bookTitle: 'Título del libro',
    readerType: 'Tipo de lector',
    tiers: 'Niveles',
    beereadersStage: 'Etapa Beereaders',
    lectures: 'Lecturas',
    activities: 'Actividades',
    booksAmount: 'Cantidad de libros',
    amount: 'Cantidad',
    corrects: 'Preguntas correctas',
    incorrects: 'Preguntas incorrectas',
    total: 'Total de preguntas',
    challengesAmount: 'Nº de desafíos',
    lexileMeasure: 'Medida Lexile',
    progress: 'Progreso',
    skill: 'Habilidad',
  },
  tableValues: {
    student: {
      type: {
        preInitial: 'Preinicial',
        initial: 'Inicial',
        transitional: 'Intrépido',
        independent: 'Independiente',
        fluent: 'Juvenil',
        reflexive: 'Reflexivo',
      }
    },
    lectures: {
      assignedBooks: 'Libros',
      readChallenges: 'Desafíos',
    },
    emptyValues: {
      emptyStudents: 'No hay estudiantes que mostrar!',
      emptyData: 'No hay datos que mostrar!',
    }
  },
  generalFilter: {
    filters: {
      courses: 'Cursos',
      levels: 'Niveles',
      students: 'Estudiantes',
      dateRange: 'Rango de fecha',
    },
    defaultValues: {
      all: 'Todos',
      customDates: 'Fechas personalizadas',
      last7Days: 'Últimos 7 días',
      last30Days: 'Últimos 30 días',
      last90Days: 'Últimos 90 días',
    },
    placeholders: {
      dateRange: 'Selecciona un rango de fechas'
    },
    labels: {
      filterByCourses: 'Filtro por cursos',
      filterByLevels: 'Filtro por niveles',
      filterByStudents: 'Filtro por estudiantes',
      filterByDate: 'Filtro por estudiantes',
    }
  },
  utils: {
    of: 'de',
    level: 'Nivel',
    cancel: 'Cancelar',
  },
  errors: {
    filterError: {
      initialDateGreater: 'Oops! La fecha inicial debe ser menor a la fecha final, intenta otra vez'
    }
  }
}