export default {
    IN_1: 'Inferir información específica en un texto.',
    IN_10: 'Reconocer la solución de problemas planteados en un texto.',
    IN_11: 'Reconocer la finalidad para la cual se realiza una acción en un texto.',
    IN_12: 'Reconocer el tema central de un texto.',
    IN_13: 'Resumir un texto leído, centrándose en sus principales contenidos.',
    IN_14: 'Inferir motivaciones de personajes de un texto.',
    IN_15: 'Inferir sentimientos y/o emociones de personajes en un texto.',
    IN_16: 'Relacionar detalles o partes de un texto con su tema central.',
    IN_17: 'Determinar el sentido en que se usa un homónimo en un texto.',
    IN_18:
        'Interpretar lenguaje figurado de expresiones familiares en un texto a partir de claves contextuales.',
    IN_19: 'Interpretar lenguaje figurado en un texto a partir de claves contextuales.',
    IN_2: 'Inferir atributos relevantes referidos a personajes centrales.',
    IN_20: 'Reconocer sinónimo y/o antónimo de un término a partir de claves contextuales.',
    IN_21: 'Reconocer el significado de un término a partir de claves contextuales.',
    IN_22: 'Interpretar el significado de símbolos en el texto a partir de claves contextuales.',
    IN_23: 'Reconocer el conflicto central de un texto.',
    IN_24: 'Describir física y/o psicológicamente a personajes de un texto.',
    IN_25: 'Describir el espacio físico y/o psicológico de un texto.',
    IN_26: 'Establecer redes de relaciones entre personajes de un texto.',
    IN_27:
        'Relacionar las características psicológicas de los personajes con sus acciones a lo largo de la obra.',
    IN_28: 'Reconocer la evolución que experimenta un personaje a lo largo del texto.',
    IN_29: 'Comprender el sentido global de un texto o de un fragmento de este.',
    IN_3: 'Inferir características físicas y/o psicológicas de personajes en un texto.',
    IN_30: 'Reconocer ideas relevantes de personajes en un texto.',
    IN_31: 'Reconocer relaciones de finalidad en textos breves.',
    IN_32:
        'Reconocer idea principal, distinguiendo de secundarias y accesorias, en textos de no ficción.',
    IN_33: 'Reconocer sentimientos y/o emociones centrales en un texto.',
    IN_34: 'Reconocer el tema central de un texto no literario.',
    IN_35:
        'Describir el contexto en que se desarrolla una acción determinada, a partir de información explícita e implícita.',
    IN_36:
        'Reconocer situaciones que marcan precedentes en la personalidad de personajes en un texto.',
    IN_37: 'Interpretar versos en poemas o canciones.',
    IN_38: 'Interpretar sentido global de un poema.',
    IN_39: 'Relacionar figuras literarias con el sentido global del texto.',
    IN_4: 'Inferir características contextuales en un texto.',
    IN_40: 'Ordenar hechos de un texto en forma cronológica.',
    IN_41: 'Distinguir entre atributos físicos y psicológicos de los personajes.',
    IN_42: 'Reconocer el motivo lírico.',
    IN_43: 'Reconocer el conflicto central en un texto lírico.',
    IN_44:
        'Interpretar un poema, relacionando versos con aspectos globales o particulares del mismo.',
    IN_5: 'Comparar información de personajes en un texto.',
    IN_6: 'Comparar información de elementos contextuales en un texto.',
    IN_7: 'Inferir las causas de acciones en un texto.',
    IN_8: 'Reconocer relaciones de causa y efecto presentes en una sección acotada de un texto.',
    IN_9: 'Reconocer efectos o consecuencias de acciones en un texto.',
    LOC_1: 'Extraer información explícita en un texto.',
    LOC_10: 'Ordenar cronológicamente una secuencia de acciones relevantes en un texto dramático.',
    LOC_11: 'Identificar la causa explícita de acciones en un texto.',
    LOC_12: 'Identificar la finalidad explícita para la cual se realiza una acción en un texto.',
    LOC_13: 'Identificar motivaciones explícitas de personajes en un texto.',
    LOC_14: 'Identificar consecuencias explícitas de acciones en un texto.',
    LOC_15: 'Identificar un dato o información específica que expresa una idea importante.',
    LOC_3: 'Identificar características explícitas de los personajes en un texto.',
    LOC_4: 'Identificar características explícitas de elementos contextuales en un texto.',
    LOC_5: 'Identificar sentimientos y/o emociones presentes de forma explícita en un texto.',
    LOC_6: 'Identificar el orden cronológico de hechos en una sección de un texto.',
    LOC_7: 'Localizar una acción en una secuencia textual.',
    LOC_8:
        'Identificar palabras o expresiones que permiten dar atributos o características (frases adjetivas, adjetivos).',
    LOC_9: 'Identificar el espacio en el que se desarrolla una acción determinada en el texto.',
    RV_1: 'Reconocer tipo de texto.',
    RV_10: 'Reconocer el argumento que usa un personaje en el texto para fundamentar una opinión.',
    RV_11: 'Relacionar la información presentada en un texto con la vida cotidiana.',
    RV_12: 'Reconocer acuerdos o desacuerdos entre opiniones de personajes de un texto.',
    RV_13: 'Reconocer la visión de mundo y/o creencias de personajes de un texto.',
    RV_14: 'Relacionar hechos del texto con el contexto de producción de la obra.',
    RV_15: 'Contrastar puntos de vista presentados en el texto con las visiones del mundo actual.',
    RV_16: 'Reconocer personajes tipo de un texto.',
    RV_17: 'Reconocer tipo de narrador en un texto.',
    RV_18:
        'Describir tratamiento del tiempo o estructura temporal de una narración',
    RV_19: 'Explicar la función que cumple una parte determinada de un texto.',
    RV_2: 'Reconocer el propósito comunicativo de un texto.',
    RV_20:
        'Explicar cómo los recursos poéticos presentes en los textos leídos (rima, ritmo, sonoridad, distribución de los versos) contribuyen a la expresión de ideas y sensaciones.',
    RV_21:
        'Analizar críticamente un personaje a partir de la forma en que la situación que vive influye sus pensamientos y sus reacciones.',
    RV_24: 'Reconocer figuras literarias presentes en un texto.',
    RV_25: 'Identificar moraleja o enseñanza explícita en una fábula.',
    RV_26: 'Predecir efectos y/o consecuencias de acciones en un texto.',
    RV_27: 'Reconocer el argumento principal que usa un personaje para fundamentar una opinión.',
    RV_28:
        'Reconocer o inferir aspectos estructurales de la forma y contenido en un texto de ficción.',
    RV_29:
        'Reconocer elementos de análisis lírico, tales como: rima, motivo lírico, objeto lírico, actitud lírica, entre otros.',
    RV_3: 'Reconocer características de forma y/o contenido de los distintos tipos de textos.',
    RV_30: 'Reconocer valores en actitudes concretas de personajes.',
    RV_5: 'Reconocer el ámbito, mundo o cultura al que se asocia un texto.',
    RV_6: 'Reconocer la principal enseñanza y/o mensaje de un texto.',
    RV_7: 'Distinguir entre hechos y opiniones en un texto.',
    RV_8: 'Reconocer valores en personajes de un texto.',
    RV_9: 'Reconocer la opinión de un personaje acerca de un tema en el texto.',
}
