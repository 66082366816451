import { ActionContext } from "vuex";
import { SurveyGlobalState, SurveyQuestionType, Survey, Answer } from "./types";
import SurveyServices from '@/services/survey'

const defaultState = (): SurveyGlobalState => ({
    answers: [],
    currentAnswer: null,
    currentSurvey: null,
    surveys: [
        {
            id: 1,
            title: 'Evalúa tu experiencia',
            createdDate: '2021-09-01',
            answeredDate: null,
            questions: [
                {
                    id: 1,
                    question: '¿Qué tan probable es que recomiendes BeeReaders a un amigo o colega?',
                    questionType: SurveyQuestionType.RATE,
                    required: true,
                    labels: {
                        start: 'Poco probable',
                        end: 'Muy probable'
                    }
                },
                {
                    id: 2,
                    question: '¿Podrías decirnos por qué nos has dado esa puntuación? (Opcional)',
                    questionType: SurveyQuestionType.TEXT,
                    required: false,
                    placeholder: 'Escribe aquí tu respuesta'
                }
            ],
            surveyType: 'NPS'
        }
    ],
    currentQuestion: null,
    currentQuestionIndex: 0,
    error: undefined
});

const surveyState = {
    namespaced: true,
    state: defaultState(),
    mutations: {
        async setSurveys(state: SurveyGlobalState, payload: { surveys: Survey[] }) {
            state.surveys = payload.surveys
        },
        async setCurrentSurvey(state: SurveyGlobalState, payload: { survey: Survey }) {
            state.currentSurvey = payload.survey
        },
        resetState(state: SurveyGlobalState) {
            state.answers = []
            state.currentAnswer = null
            state.currentSurvey = null
            state.currentQuestion = null
            state.currentQuestionIndex = 0
        },
        setCurrentAnswer(state: SurveyGlobalState, payload: { answer: Answer }) {
            state.currentAnswer = payload.answer
        },
        addAnswer(state: SurveyGlobalState, payload: { answer: Answer }) {
            const index = state.answers.findIndex(a => a.questionId === payload.answer.questionId)
            if (index !== -1) {
                state.answers[index] = payload.answer
            } else {
                state.answers.push(payload.answer)
            }
            state.currentAnswer = null
        },
        nextQuestion(state: SurveyGlobalState) {
            state.currentQuestionIndex = state.currentQuestionIndex + 1
            state.currentAnswer = state.answers[state.currentQuestionIndex] || null
        },
        prevQuestion(state: SurveyGlobalState) {
            state.currentQuestionIndex = state.currentQuestionIndex - 1
            state.currentAnswer = state.answers[state.currentQuestionIndex] || null
        },
        closeSurvey(state: SurveyGlobalState) {
            state.currentSurvey = null
        },
        setError(state: SurveyGlobalState, payload: { error: string }) {
            state.error = payload.error
        }
    },
    actions: {
        async getTeacherCurrentSurvey({ commit, state }: ActionContext<SurveyGlobalState, any>, payload: { teacherID: number }) {
            const resp = await SurveyServices.validateIfUserHasSurvey(payload.teacherID)
            if(resp.activeCampaign && !resp.userReplied) {
                commit('setCurrentSurvey', { survey: state.surveys[0] })
            } else {
                commit('resetState')
            }
        },
        async sendSurveyAnswers({ commit, state }: ActionContext<SurveyGlobalState, any>, payload:  { userId: number }) {
            if(!state.currentSurvey?.questions) {
                commit('setError', { error: 'No hay encuesta activa' })
                return
            }

            const data = { comments: state.answers[1]?.answer || ' ', npsScore: state.answers[0].answer, userId: payload.userId }
            try {
                const resp = await SurveyServices.sendUserSurvey(data)
                if(!resp.ok) {
                    commit('setError', { error: resp.error || 'Error al enviar la encuesta' })
                }
            } catch (error) {
                commit('setError', { error: String(error) || 'Error al enviar la encuesta' })
            }
        }
    }
}

export default surveyState