import { ActionContext } from "vuex";
import { DIAGNOSIS_EVALUATION_TYPE, DIAGNOSIS_STATE, DiagnosticsGlobalState, EvaluationFullData, EvaluationSimpleData } from "./types";
import { diagnosticAssignmentService } from "@/services/evaluations/diagnosticAssignment";
import { evaluacionDiagnostico } from "@/services/evaluations/evaluacionDiagnostico";

const defaultState = (): DiagnosticsGlobalState => ({
    currentAssignedEvaluationDetail: null,
    assignedEvaluations: [],
    newEvaluationData: undefined,
    filters: {
        currentCourseID: undefined,
        currentEvaluationType: undefined,
        evaluationState: undefined,
    }
});

const diagnosticData = {
    namespaced: true,
    state: defaultState(),
    getters: {
        currentDiagnostic: (state: DiagnosticsGlobalState) => {
            return state.currentAssignedEvaluationDetail
        },
        assignedEvaluations: (state: DiagnosticsGlobalState) => {
            return state.assignedEvaluations
        },
        getCourseSelected: (state: DiagnosticsGlobalState) => {
            return state.filters.currentCourseID
        },
        getDiagnosticEvaluationType: (state: DiagnosticsGlobalState) => {
            return state.filters.currentEvaluationType
        },
        getDiagnosticEvaluationState: (state: DiagnosticsGlobalState) => {
            return state.filters.evaluationState
        }
    },
    mutations: {
        async setAssignedEvaluations(state: DiagnosticsGlobalState, payload: { evaluations: EvaluationSimpleData[] }) {
            state.assignedEvaluations = payload.evaluations
        },
        async setDiagnosticEvaluationFullData(state: DiagnosticsGlobalState, payload: { fullEvaluation: EvaluationFullData }) {
            state.currentAssignedEvaluationDetail = payload.fullEvaluation
        },
        addNewEvaluationIntoAssigned(state: DiagnosticsGlobalState, payload: { evaluation: EvaluationSimpleData }) {
            state.assignedEvaluations.push(payload.evaluation)
        },
        removeEvaluationFromAssigned(state: DiagnosticsGlobalState, payload: { evaluationID: string }) {
            state.assignedEvaluations = state.assignedEvaluations.filter(ev => ev.id !== payload.evaluationID)
        },
        resetFilters(state: DiagnosticsGlobalState) {
            state.filters = defaultState().filters
        },
        setCourseSelected(state: DiagnosticsGlobalState, payload: { courseID: number }) {
            state.filters.currentCourseID = payload.courseID
        },
        setDiagnosticEvaluationType(state: DiagnosticsGlobalState, payload: { evaluationType: DIAGNOSIS_EVALUATION_TYPE }) {
            state.filters.currentEvaluationType = payload.evaluationType
        },
        setDiagnosticEvaluationState(state: DiagnosticsGlobalState, payload: { evaluationState: boolean }) {
            state.filters.evaluationState = payload.evaluationState
        },
        resetCurrentAssignedEvaluationDetail(state: DiagnosticsGlobalState) {
            state.currentAssignedEvaluationDetail = null
        }
    },
    actions: {
        async getAssignedEvaluations({ commit }: ActionContext<DiagnosticsGlobalState, any>, payload: { teacherID: number }) {
           const evaluations = await diagnosticAssignmentService.getDiagnosesByTeacher({ teacherId: payload.teacherID, evaluationState: null });
            commit('setAssignedEvaluations', { evaluations: evaluations })
        },
        async getDiagnosisDetail({ commit }: ActionContext<DiagnosticsGlobalState, any>, payload: { diagnosisID: string }) {
            const evaluation = await diagnosticAssignmentService.getDiagnosesEvaluationFullData(payload.diagnosisID)
            commit('setDiagnosticEvaluationFullData', { fullEvaluation: evaluation })
            return evaluation
        },
        async generateExcel({ commit, state }: ActionContext<DiagnosticsGlobalState, any>, payload: any) {
            return await evaluacionDiagnostico.generateEvaluationDataFile(payload)
        },
    }
}

export default diagnosticData