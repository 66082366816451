export default {
    abbreviated_months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    inactive_users: 'Inactive users',
    last_login: 'Last login',
    ninety_days: '90 days',
    quick_stats: 'Quick stats',
    seven_days: '7 days',
    stats: ['Active users', 'Reading hours', 'Reads', 'Avg. quiz score'],
    thirty_days: '30 days',
    global_summary: 'Global summary',
    locate_information: 'Locate Information',
    integrate_information: 'Integrate & Interpret',
    reflect_evaluate: 'Reflect & Evaluate',
    four_week: '4 weeks',
    eigth_weeks: '8 weeks',
    twelve_weeks: '12 weeks',
    press_legen_item_preview: 'Press any item on the legend to show its graph'
}
