import { Student } from "@/data/dto/student"
import { DateOptionsEnum } from "@/data/reports/constants"
import { GeneralInformation } from "@/data/reports/responses"

import { BookInformationDTO, ReportsGlobalState } from './types/index'

export default {
  studentsFromCourse: (state: ReportsGlobalState) => (courseID: number): Student[] => {
    return state.studentsPerCourse[courseID] || []
  },
  getBooksInformation: (state: ReportsGlobalState) => (
      courseID: number,
      dateRangeOption: DateOptionsEnum
  ): BookInformationDTO | undefined => {
    switch (dateRangeOption) {
      case DateOptionsEnum.LAST7DAYS:
        return state.bookInformation[courseID]?.last7Days
      case DateOptionsEnum.LAST30DAYS:
        return state.bookInformation[courseID]?.last30Days
      case DateOptionsEnum.LAST90DAYS:
        return state.bookInformation[courseID]?.last90Days
      default:
        return state.bookInformation[courseID]?.custom
    }
  },
  getCourseBooksInformation: (state: ReportsGlobalState) => (payload: {
    courseID: number
    dateRangeOption: DateOptionsEnum
  }): BookInformationDTO | undefined => {
    switch (payload.dateRangeOption) {
        case DateOptionsEnum.LAST7DAYS:
          return state.bookInformation[payload.courseID]?.last7Days
        case DateOptionsEnum.LAST30DAYS:
          return state.bookInformation[payload.courseID]?.last30Days
        case DateOptionsEnum.LAST90DAYS:
          return state.bookInformation[payload.courseID]?.last90Days
        default:
          return state.bookInformation[payload.courseID]?.custom
    }
  },
  getGeneralInformationFromCourse: (state: ReportsGlobalState) => (payload: {
    courseID: number
    dateRangeOption: DateOptionsEnum
  }): GeneralInformation[] | undefined => {
    switch (payload.dateRangeOption) {
      case DateOptionsEnum.LAST7DAYS:
        return state.generalInformation[payload.courseID]?.last7Days
      case DateOptionsEnum.LAST30DAYS:
        return state.generalInformation[payload.courseID]?.last30Days
      case DateOptionsEnum.LAST90DAYS:
        return state.generalInformation[payload.courseID]?.last90Days
      case DateOptionsEnum.CUSTOM_DATE:
        return state.generalInformation[payload.courseID]?.custom
    }
  },
  isLoadingData(state: ReportsGlobalState) {
    if (
      state.loading.generalDataLoading ||
      state.loading.booksDataLoading ||
      state.loading.challengesDataLoading
    )
      return true

    return false
  },
  isError(state: ReportsGlobalState) {
    if (state.errors.filterError || state.errors.generalError) return true
    return false
  },
  getErrorMessage(state: ReportsGlobalState) {
    return state.errors.generalError || state.errors.filterError
  },
}
