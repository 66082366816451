<template>
    <div class="loader">
        <img src="/img/brand/logo-white.svg" alt="logo loader" />
        <v-progress-circular :size="50" indeterminate :color="'#ffb511'"></v-progress-circular>
    </div>
</template>
<script>
export default {}
</script>

<style lang="css"></style>
