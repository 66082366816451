export default {
    reports: 'Reportes',
    activities: 'Recursos didácticos',
    teacher_training: 'Tutoriales y webinars',
    message_center: 'Centro de mensajes',
    resources_room: 'Sala de recursos',
    categories: 'Categorias',
    all: 'Todos',
    student_tutorial: 'Tutorial para estudiantes',
    teacher_tutorial: 'Tutorial para maestros',
    recorded_webinar: 'Webinars grabados',
    new_message: 'Mensaje nuevo',
    inbox: 'Entrantes',
    sent_messages: 'Enviados',
    archived: 'Archivados',
    trash: 'Papelera',
    paginator: `<b>{0} - {1}</b> of {2}`,
    file: 'Archivar',
    forward: 'Reenviar',
    delete: 'Eliminar',
    reply: 'Responder',
    to_students: 'A estudiantes:',
    to_groups: 'A grupos:',
    send_message: 'Enviar mensaje',
    to: 'Para:',
    from: 'De:',
    subject_required: 'El asunto es requerido.',
    body_required: 'El mensaje es requerido.',
    student_required: 'Debe seleccionar al menos un estudiante.',
    group_required: 'Debe seleccionar al menos un curso y un nivel.',
    not_videos: 'No hay contenido para mostrar.',
    report_type: 'Tipo de reporte:',
    activity_type: 'Tipo de actividad',
    preview: 'Vista previa',
    from_six_years: 'Desde 6 años',
    from_nine_years: 'Desde 9 años',
    from_twelve_years: 'Desde 12 años',
    from_fourteen_years: 'Desde 14 años',
    from_sixteen_years: 'Desde 16 años',
    unable_to_get_student_report: 'No se pudo obtener el reporte de estudiantes para este curso',
    unable_to_get_course_report: 'No se pudo obtener el reporte de este curso',
    monthly_report: 'Reporte mensual',
    must_select_course: 'Seleccione un curso',
    select_report_month: 'Seleccione el mes del reporte',
    report_month: 'Mes del reporte',
    course_to_download: 'Curso a descargar',
    must_select_month: 'Selecciona un mes',
    school: 'Colegio',
    teacher_in_charge: 'Profesor',
    segment: 'Segmento',
    segment_values: ['61%-100%', '41%-60%', '0%-40%'],
    months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ],
    to_the: 'al',
    of: 'de',
    reported_period: 'Período informado',
    course_section: 'Curso / Sección',
    current_level: 'Nivel actual',
    detail_by_student: 'Detalle por estudiante',
    average_and_totals: 'PROMEDIO Y TOTALES',
    name: 'Nombre',
    books: 'Libros',
    challenges: 'Desafíos',
    skills: 'Habilidades',
    total_average: 'Promedio total Comprensión Lectora',
    monthly_report_no_data: 'No hay datos para el curso en el periodo consultado.',
    accept: 'Aceptar',
    cl_report: 'N.A. = Nivel actual según Sistema de Nivelación Beereaders',
    percent_pages: '% Pág. = Porcentaje de páginas leídas',
    percent_reading: '% C.L = Porcentaje de comprensión lectora',
    challenges_number: 'N° des. = Número de desafíos',
    f_report: 'L = Localizar u obtener información',
    i_report: 'I = Integrar e interpretar',
    r_report: 'R = Reflexionar y valorar',
    report_header_abbreviation: ['D.I', '% Pag.', '% C.L.', 'N° des.', 'L', 'I', 'R', 'N.A'],
    di_report: 'D.I. = Diagnóstico Inicial',
    warning_percent_cl: '*El % de comprensión lectora en desafíos se calcula considerando el total de preguntas contestadas por cada estudiante.',
    warning_quantity_answer_challenge: '*Cada desafío corresponde a un texto de control con 4 preguntas.',
    reading_progression_system: 'Sistema de progresión lectora',
    select_reader_type: 'Seleccione tipo de lector',


}
